<template>
  <div class="zero-table d-flex flex-column">
    <div class="card flex-grow-1">
      <div id="kt_app_table_header" v-if="title && title !== ''" class="card-header">
        <h4 class="card-title">{{ title }}</h4>
      </div>
      <ZeroSearch id="kt_app_table_search" v-if="method !== 'pure'" ref="zeroSearch" v-model="filterInfo" v-model:collapse="isSearchCollapse"
                   :class="title && title !== '' ? '' : 'pt-4'"
                   :columns="searchColumns" :filter="config.filter || {}"
                   :handleSearch="handleSearch" :no-reset="noSearchReset" :responsive="true" @search="initData('', 'new')">
        <template v-for="(filter, i) in config.filter" #[`${i}-filter`]="{ data }">
          <slot :name="i + '-filter'" :data="data" :class-list="filter.class" :configs="filter.configs">
          </slot>
        </template>
      </ZeroSearch>
      <div class="separator" />
      <div id="kt_app_table_toolbar" class="card-header">
        <div class="card-title">
          <TableConfig v-if="showTableConfig" v-model:column="tableColumnConfig" :column-list="config?.table?.fields" :table-name="tableName ? $route.name + '-' + tableName : $route.name" />
        </div>
        <div v-if="hasActionButtons" class="card-toolbar">
          <slot v-if="$slots['left-action-buttons']" name="left-action-buttons" />
          <template v-if="defaultSelect">
            <b-button class="btn-outline min-w-80px" v-if="!selectMode" variant="outline-primary" @click="openSelectMode">
              批量操作
            </b-button>
            <template v-else>
              <slot name="select-actions" :selectedIdList="selectedIdList" :selectedItemList="selectedItemList" :selectMode="selectMode" />
              <b-button class="btn-outline w-80px" variant="outline-primary" @click="closeSelectMode">
                返回
              </b-button>
            </template>
          </template>
          <template v-if="!selectMode">
            <template v-if="defaultImport">
              <b-button class="btn-outline w-80px ms-2" variant="outline-primary" @click="openFileImportModal">
                导入
              </b-button>
            </template>
            <template v-if="defaultExport">
              <b-button class="btn-outline w-80px ms-2" variant="outline-primary" @click="download">
                导出
              </b-button>
            </template>
            <template v-if="defaultAdd">
              <router-link :to="changePathToAdd()" class="btn btn-primary min-w-80px ms-2">
                <icon type="ri" name="add" mode="fill" class="p-0" />{{ defaultAddText }}
              </router-link>
            </template>
          </template>
          <span v-if="((defaultExport || defaultImport || defaultAdd || defaultSelect) && $slots['action-buttons'])" class="bullet h-20px w-1px bg-secondary mx-4"></span>
          <slot name="action-buttons" :selectedIdList="selectedIdList" :selectedItemList="selectedItemList" :selectMode="selectMode" />
        </div>
      </div>
      <div ref="tableWrapper" class="card-body py-0 px-4 z-index-0">
        <slot :config="config">
          <vxe-table ref="tableInstance"
                     :data="$_VALUE"
                     :height="height ? height : tableHeight"
                     :row-config="vxeRowConfig"
                     :column-config="vxeColumnConfig"
                     :expand-config="expandConfig"
                     border="inner"
                     @checkbox-change="onSelectChange"
                     @checkbox-all="onSelectChange"
                     @resizable-change="resizeableChange"
                     class="border-top border-gray-200"
          >
            <template #empty>
              <no-data :empty-text="emptyText" empty-text-description="您可以尝试修改筛选条件后查询" />
            </template>
            <vxe-column v-if="selectMode" type="checkbox" width="50" fixed="left" />
            <vxe-column type="seq" title="序号" width="64" fixed="left" align="center" :col-id="$_INDEX_KEY">
              <template v-if="$slots[$_INDEX_KEY] || $slots[`cell(${$_INDEX_KEY})`]" v-slot="slotParams">
                <slot :name="$_INDEX_KEY" :for="$_INDEX_KEY" :data="{ item: slotParams.row, value: slotParams.row[$_INDEX_KEY], index: slotParams.$rowIndex }" :form="{ key: $_INDEX_KEY}" />
              </template>
            </vxe-column>
            <vxe-column v-if="expandMode" type="expand" width="50">
              <template #content="{ row }">
                <slot name="expand-content" :data="{ data: row }" />
              </template>
            </vxe-column>
            <template :key="`table-column-${column.key}`" v-for="column in fields">
              <vxe-column v-if="['do', 'action'].includes(column.key)" title="操作" :width="cellOptionWidth" fixed="right" col-id="do">
                <template v-slot="slotParams">
                  <div class="w-100" :ref="el => cellOptionWrapper[slotParams.$rowIndex] = el">
                    <slot name="do" :value="slotParams.row[column.key]" :data="{ item: slotParams.row, value: slotParams.row[column.key] }" :item="slotParams.row" :index="slotParams.$rowIndex" />
                  </div>
                </template>
              </vxe-column>
              <vxe-column v-else-if="column.key === 'status'" width="120" :title="column.label ?? column.key" field="status" :status-data="statusData = {}" col-id="status">
                <template v-if="!column.format && $slots.status" v-slot="slotParams">
                  <slot name="status" :value="slotParams.row[column.key]" :data="{ item: slotParams.row, value: slotParams.row[column.key] }" :item="slotParams.row" :index="slotParams.$rowIndex" />
                </template>
                <template v-else-if="!column.format && $slots['cell(status)']" v-slot="slotParams">
                  <slot name="cell(status)" :value="slotParams.row[column.key]" :data="{ item: slotParams.row, value: slotParams.row[column.key] }" :item="slotParams.row" :index="slotParams.$rowIndex" />
                </template>
                <template v-else v-slot="slotParams">
                  <zero-tag :status-data="statusData[slotParams.$rowIndex] = translateStatus(column, slotParams.row, slotParams.row[column.key], column)" :variant="statusData[slotParams.$rowIndex].variant">{{ statusData[slotParams.$rowIndex].text }}</zero-tag>
                </template>
              </vxe-column>
              <vxe-column v-else-if="column.key !== $_INDEX_KEY"
                          :min-width="150"
                          :width="column.width ?? undefined"
                          show-overflow
                          :sortable="!!column.sort"
                          :fixed="column.fixed ?? undefined"
                          :title="column.label ?? column.key"
                          :field="column.key"
                          :col-id="`col-${column.key}`"
                          :tree-node="treeNode === column.key"
              >
                <template v-slot="slotParams">
                  <div v-if="column.clipboard && !isDisplayEmpty(column.key, slotParams.rowIndex)" class="cell-clipboard">
                    <b-button variant="link" class="px-2 text-gray-500 text-hover-primary" @click.prevent="copyClipboard(column.key, slotParams.rowIndex)"><icon type="ri" class="p-0 m-0" name="file-copy-2" mode="fill" /></b-button>
                  </div>
                  <template v-if="$slots[column.key] || $slots[`cell(${column.key})`]">
                    <slot :name="column.key" :value="slotParams.row[column.key]" :data="{ item: slotParams.row, value: slotParams.row[column.key] }" :item="slotParams.row" :index="slotParams.$rowIndex" />
                  </template>
                  <template v-else>{{ slotParams.row[column.key] }}</template>
                </template>
              </vxe-column>
            </template>
          </vxe-table>
<!--          <div v-if="items === null || (items && items.length === 0)" class="text-center mt-6">
            <p class="text-muted fs-6">
              {{ noItemLabel }}
            </p>
          </div>-->
        </slot>
      </div>
      <div class="separator z-index-3" />
      <div id="kt_app_table_footer" class="card-footer d-flex justify-content-between px-6 py-0 bg-white border-0 z-index-3">
        <Pagination
          v-if="(!(isEmpty($_VALUE) && pagination.current === 1)) && !hidePagination"
          v-model:current="pagination.current"
          v-model:total="pagination.total"
          v-model:size="pagination.size"
          :disabled="loading"
        ></Pagination>
      </div>
    </div>
  </div>
  <b-modal v-model="fileImportModalShow" ref="modal-import" size="md" id="modal-import" title="文件导入" no-close-on-backdrop centered>
    <div class="form">
      <div class="form-group row">
        <label class="col-3">导入文件上传</label>
        <div class="col-9">
          <button type="button" @click="selectFile" class="btn btn-light-primary font-weight-bold btn-sm">{{ file ? file.name : '请选择需要导入的文件' }}</button>
          <input type="file" ref="fileImportInputRef" style="display: none" accept=".xlsx" @change="getFile"/>
          <div class="form-text text-muted mt-3">请根据导入模板进行信息整理后上传.</div>
          <a v-if="importTemplateFile" :href="importTemplateFile" class="mt-3 hover-primary" download>模版文件下载</a>
        </div>
      </div>
    </div>
    <template #footer>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button variant="primary" class="w-100px me-2" @click.prevent="upload">确定</b-button>
          <b-button variant="dark" class="w-90px" @click.prevent="closeFileImportModal">取消</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script setup>
import {
  defineEmits,
  onMounted,
  ref,
  watch,
  defineProps,
  defineExpose,
  onBeforeMount,
  onBeforeUnmount,
  nextTick,
  onUnmounted
} from 'vue'
import ZeroSearch from './search.vue'
import { hasAuth } from '../utils/auth'
// import CommonService from '@/services/common'
import { usePermissionStore } from '@/stores/permissions'
import Pagination from './pagination'
import TableConfig from './table-config.vue'
import NoData from './no-data.vue'
import Event from '@/utils/event'
import { useRoute } from 'vue-router'
import { isEmpty, typeOf } from '../utils/common'
import { useMenuStore } from '@/stores/menu'
import { ZERO_TABLE_EVENTS } from '@/components/zero-vue/events'
import useClipboard from 'vue-clipboard3'
import CommonService from '@/services/common'
import notification from '@/utils/notification'

const vxeColumnConfig = {
  resizable: true
}

const vxeRowConfig = {
  isHover: true,
  isCurrent: true
}

const route = useRoute()

const props = defineProps({
  // 表格配置，包含filter、table两个组件配置
  config: Object,
  // 表格模式，默认standard，pure为纯表格，component为组件
  method: { type: String, default: 'standard' },
  // 是否显示序号
  index: { type: Boolean, default: true },
  // 是否树形列表, 定义树形tree-node
  treeNode: { type: String, default: '' },
  // 是否显示搜索重置按钮
  noSearchReset: { type: Boolean, default: false },
  // table是否显示hover效果
  hover: { type: Boolean, default: true },
  // 是否显示操作按钮
  hasActionButtons: { type: Boolean, default: true },
  // 表格标题内容
  title: { type: String },
  // 默认表格每页item数量
  size: { type: [Number, String], default: 20 },
  // search组件中一行显示的搜索项数量
  searchColumns: { type: [Number, String], default: 4 },
  // 当表格内无数据时content展示的内容
  noItemLabel: { type: String, default: '暂无数据' },
  // 是否隐藏分页
  hidePagination: { type: Boolean, default: false },
  // 是否显示table-footer
  tableFooter: { type: Boolean, default: false },
  // 是否开启批量操作
  defaultSelect: { type: Boolean, default: false },
  // 是否开启导入
  defaultImport: { type: Boolean, default: false },
  // 是否开启导出
  defaultExport: { type: Boolean, default: false },
  // 前端导出文件名字
  exportName: { type: String, default: '' },
  // 是否开启新增
  defaultAdd: { type: Boolean, default: true },
  // 新增按钮文字
  defaultAddText: { type: String, default: '新增' },
  // 新增页面跳转路由
  addPath: { type: String, default: '' },
  // 导入模版文件
  importTemplateFile: { type: String, default: '' },
  // 获取表格内容的service，会默认调用其getList方法
  asyncService: Object,
  getListFuncName: { type: String, default: '' },
  // 表名
  tableName: { type: String, default: '' },
  // 是否展示配置项
  showTableConfig: { type: Boolean, default: true },
  // 自定义返回数据结构
  isCustomResult: { type: Boolean, default: false },
  // 处理get前的param参数
  handleGet: Function,
  // 处理search前的filter参数
  handleSearch: Function,
  // 处理接口获取的的result数据
  handleResult: Function,
  height: { type: Number },
  // 是否开启展开行功能
  expandMode: { type: Boolean, default: false },
  expandConfig: { type: Object }
})

const emits = defineEmits(['upload', 'download'])
const { toClipboard } = useClipboard()

const tableWrapper = ref()
const tableInstance = ref()
const tableHeight = ref(400)
// 定义是否在请求数据中
const loading = ref(false)
// 是否搜索折叠
const isSearchCollapse = ref(true)
// 定义table列配置
const fields = ref([])
// 定义table配置
const tableConfig = ref({})
// 定义table列配置
const tableColumnConfig = ref([])
// 定义表单内容项
const $_VALUE = ref([])
// 定义选中的id列表
const selectedIdList = ref([])
// 定义选中的item列表
const selectedItemList = ref([])
// 是否开启批量模式
const selectMode = ref(false)
// 分页配置
const pagination = ref({
  total: 10,
  size: props.size,
  pages: 2,
  current: 1
})
const emptyText = ref(props.noItemLabel)

const defaultMenu = ref()
const $_INDEX_KEY = ref('index')

const computedFields = ref([])
const zeroSearch = ref(null)
const filterInfo = ref({})
const cellOptionWrapper = ref([])
const cellOptionWidth = ref(100)

/* const computedTitle = computed(() => {
  let title = props.title
  if (isEmpty(title)) {
    title = defaultMenu.value?.meta?.title || ''
  }
  return title
}) */

watch(fields, fields => {
  nextTick(() => {
    Event.emit('ZERO_TABLE.FIELD_CHANGED', 'FIELD_CHANGED')
  })
})

watch(tableColumnConfig, tableColumnConfig => {
  Event.emit('ZERO_TABLE.FIELD_CHANGED', 'CONFIG_CHANGED')
})

/* watch(computedFields, value => {
  console.log('refresh')
  console.log(value)
  tableInstance.value.loadColumn(value)
}) */

// function parseFormatter (field) {
//   return ({ cellValue, row, column }) => {
//     return translateValue(cellValue, row, column, field)
//   }
// }

const defaultStatus = {
  0: { variant: 'light-primary', text: '启用' },
  1: { variant: 'light-danger', text: '停用' }
}

function isDisplayEmpty (key, rowIndex) {
  const el = document.querySelectorAll(`td[colid="col-${key}"]`)?.[rowIndex]
  return !el || (['', '-'].includes(el.innerText))
}

async function copyClipboard (key, rowIndex) {
  const el = document.querySelectorAll(`td[colid="col-${key}"]`)?.[rowIndex]
  let message = ''
  if (el) {
    message = el.innerText
  }
  try {
    await toClipboard(message)
  } catch (e) {
    console.error(e)
  }
}

function translateStatus (field, data) {
  if (field.format) {
    const value = field.format(data[field.key], data)
    if (typeOf(value) !== 'object') {
      return {
        variant: defaultStatus[data[field.key]]?.variant,
        text: value
      }
    } else {
      return value
    }
  } else {
    return {
      variant: defaultStatus[data[field.key]]?.variant,
      text: defaultStatus[data[field.key]] ? defaultStatus[data[field.key]].text : data[field.key]
    }
  }
}

function translateValue (row, field) {
  let value = ''
  if (field.format) {
    value = field.format(row[field.key], row)
  } else {
    value = row[field.key]
  }
  return (value === undefined || value === null || value === '') ? '-' : value
}

const initTable = async () => {
  // 过滤手动显隐数据列
  // const fieldsConfig = await getColumnItems(props.config?.table?.fields || [])
  // console.log(fieldsConfig, 'fieldsConfig')
  // 初始化表格基础项
  let fieldsHandle = props.config?.table?.fields
  // for (const item of fieldsConfig) {
  //   if (item.status) {
  //     fieldsHandle.push(item)
  //   }
  // }
  // 非超管用户进行权限过滤
  if (fieldsHandle) {
    fieldsHandle = fieldsHandle.filter((val) => {
      return hasAuth(val.auth)
    })

    fieldsHandle.forEach(item => {
      if (item.key === 'do') {
        item.thClass = 'text-start'
        item.tdClass = 'text-start'
        item.sticky = 'end'
      }
      if (!item.tdClass) {
        item.tdClass = 'text-gray-700'
      } else {
        item.tdClass += ' text-gray-700'
      }
    })
  }

  fields.value = fieldsHandle
  tableConfig.value = props.config?.table?.tableConfig || {}

  // columnDrop()
}

const initData = async (orderParam, type) => {
  if (type && type === 'new') {
    pagination.value.current = 1
  }
  if (props.asyncService) {
    let filterInfoBack = Object.assign({}, filterInfo.value)
    if (filterInfoBack) {
      for (const i in filterInfoBack) {
        if (filterInfoBack[i] === null || filterInfoBack[i] === undefined || filterInfoBack[i] === '') {
          delete filterInfoBack[i]
        }
      }
      if (props.handleSearch && Object.keys(filterInfoBack).length > 0) filterInfoBack = props.handleSearch(filterInfoBack)
    }
    let param = { page: pagination.value.current, size: pagination.value.size, ...orderParam, ...filterInfoBack }
    param = props.handleGet ? props.handleGet(param) : param
    try {
      emptyText.value = props.noItemLabel
      let data = {}
      if (props.getListFuncName) {
        if (props.asyncService[props.getListFuncName]) {
          data = await props.asyncService[props.getListFuncName](param)
        }
      } else {
        data = await props.asyncService.getList(param)
      }

      let value = []

      if (typeOf(data) === 'array') {
        if (props.handleResult) {
          value = props.handleResult(data)
        } else {
          value = data || []
        }
      } else if (props.isCustomResult) {
        // 自定义返回结构数组
        value = props.handleResult(data)
      } else {
        if (data.records && props.handleResult) {
          data.records = props.handleResult(data.records)
        }
        value = data?.records || []
      }
      for (const item of value) {
        for (const field of fields.value) {
          if (field.key !== 'status') {
            item[field.key] = translateValue(item, field)
          }
        }
      }
      console.log([...value].length)
      $_VALUE.value = value
      pagination.value = data.pagination || pagination.value
      // 清空多选
      selectedItemList.value = []
      selectedIdList.value = []
    } catch (error) {
      console.log(error)
      emptyText.value = [`获取数据出错，${error?.data?.message}`, `${error?.data?.exception_clazz}`]
    }
  }
}

// 监听分页变化
watch(() => [pagination.value.current, pagination.value.size], async (newVal, oldValue) => {
  if (newVal[0] !== oldValue[0] || newVal[1] !== oldValue[1]) {
    // 同时监测current和size
    await initData()
    loading.value = false
  }
}, { deep: true })

/**
 * @name reload
 * @description 重新加载数据
 * @return void
 */
const reload = () => {
  fields.value = []
  tableConfig.value = {}
  $_VALUE.value = []
  selectedIdList.value = []
  selectedItemList.value = []

  pagination.value = {
    total: 10,
    size: 20,
    pages: 2,
    current: 1
  }

  initTable()
  initData()
}

const file = ref()
// 是否打开文件导入模态框
const fileImportModalShow = ref(false)

/**
 * @name upload
 * @description 上传文件
 * @return void
 */
const upload = async () => {
  // 上传文件
  const formData = new FormData()
  formData.append('file', file.value)
  // 触发上传事件
  emits('upload', formData)
  try {
    await props.asyncService.upload(formData)
    await notification.success('导入成功', '')
    await initData()
    // 关闭模态框
    fileImportModalShow.value = false
  } catch (e) {
    console.log(e)
    notification.error('导入失败,' + e.data.message)
  }
}

/**
 * @name download
 * @description 下载文件
 * @return void
 */
const download = () => {
  // 包含文件名字段走前端导出,否则走后端导出
  if (props.exportName) {
    tableInstance.value.exportData({
      type: 'xlsx',
      filename: props.exportName
    })
  } else {
    emits('download')
  }
}

// 用于文件选择的type为file的input
const fileImportInputRef = ref()

/**
 * @name selectFile
 * @description 选择文件
 * @return void
 */
const selectFile = () => {
  // 选择文件
  fileImportInputRef.value?.click()
}

/**
 * @name getFile
 * @description 获取文件
 * @param e input事件
 * @return void
 */
const getFile = (e) => {
  const aElement = e.target
  file.value = aElement.files?.[0] || ''
}

/**
 * @name openFileImportModal
 * @description 打开文件导入模态框
 * @return void
 */
const openFileImportModal = () => {
  // 打开文件导入模态框
  fileImportModalShow.value = true
}

const closeFileImportModal = () => {
  // 关闭文件导入模态框
  fileImportModalShow.value = false
}

/**
 * @name changePathToAdd
 * @description 新增地址转换
 * @description 用于新增按钮的跳转
 * @return string 新增地址
 */
const changePathToAdd = () => {
  // 新增地址转换
  return route.path.replace(/\/\w+$/, '/add')
}

/**
 * @name openSelectMode
 * @description 打开批量操作模式
 * @description 增加选择列
 */
const openSelectMode = () => {
  // 打开批量操作模式
  selectMode.value = true
  // 增加选择列
  computedFields.value.unshift({ key: 'selected', stickyColumn: true, isRowHeader: true, label: '选择', thClass: 'w-50px fw-bold fs-6 text-gray-1000' })
  // 清空选择列表
  selectedIdList.value = []
  selectedItemList.value = []
  tableInstance.value.refreshColumn()
}

function reloadData () {
  initTable()
  initData()
}

/**
 * @name closeSelectMode
 * @description 关闭批量操作模式
 * @description 增加选择列
 */
const closeSelectMode = () => {
  // 关闭批量操作模式
  selectMode.value = false
  // 去除选择列
  computedFields.value.shift()
  // 清空选择列表
  selectedIdList.value = []
  selectedItemList.value = []
  tableInstance.value.refreshColumn()
}

function onSelectChange () {
  const records = tableInstance.value.getCheckboxRecords()
  const idList = []
  const itemList = []
  for (const n in records) {
    idList.push(records[n].id)
    itemList.push(records[n])
  }
  selectedIdList.value = idList
  selectedItemList.value = itemList
}

async function resizeableChange (row) {
  console.log(row)
  const tableConfig = [...tableColumnConfig.value]
  const changeConfig = tableConfig.find((item) => item.key === row.column.key)
  if (changeConfig) {
    changeConfig.width = row.column.resizeWidth
    try {
      const tableName = props.tableName
      await CommonService.setColumnConfig(tableName ? route.name + '-' + tableName : route.name, { column: tableConfig })
    } catch (e) {
      console.log(e)
    }
  }
}

function debounce (func, delay) {
  let timeoutId
  return () => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(func, delay)
  }
}

function parseIndexColumn () {
  for (const n in computedFields.value) {
    if (computedFields.value[n].key === 'index' || computedFields.value[n].type === 'index') {
      $_INDEX_KEY.value = computedFields.value[n].key
      return
    }
  }
}

function emitFiledChanged () {
  if (tableInstance.value) {
    const resultFields = []
    const tableConfig = [...tableColumnConfig.value]

    const data = tableInstance.value.getTableColumn().fullColumn
    for (const index in data) {
      let item = data[index]
      const itemConfig = tableConfig.find(i => i.key === item.field)
      if (itemConfig) item = Object.assign(item, itemConfig)
    }
    tableInstance.value.refreshColumn(true)
    computedFields.value = resultFields
  }
}

onBeforeMount(() => {
  parseIndexColumn()
  window.addEventListener('resize', tableHeightListener())
  // 注意处理新加字段的情况
  Event.on('ZERO_TABLE.FIELD_CHANGED', ['CONFIG_CHANGED', 'FIELD_CHANGED'], emitFiledChanged)
  Event.on(`${ZERO_TABLE_EVENTS.RELOAD}:${route.name}`, (condition, route) => {
    reloadData()
  })
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', tableHeightListener())
})

function tableHeightListener () {
  return debounce(() => {
    if (tableWrapper.value) {
      tableHeight.value = getTableHeight()
    }
  }, 10)
}

watch(cellOptionWrapper, value => {
  const minWidth = 100
  let width = 0
  for (const i in value) {
    const options = value[i].querySelectorAll('.cell-option')
    let currentWidth = 0
    for (const n in options) {
      if (options[n].clientWidth) {
        currentWidth += options[n].clientWidth + 12
      }
    }
    currentWidth += 32
    width = (currentWidth > width ? currentWidth : width)
  }
  cellOptionWidth.value = (minWidth > width ? minWidth : width)
  // tableInstance.value.refreshColumn()
}, { deep: true, once: true })

function getTableHeight () {
  const bodyHeight = document.body.clientHeight
  const heights = {
    pageHeader: document.querySelector('#kt_app_header')?.clientHeight ?? 0,
    breadcrumb: 32,
    pageTab: 32,
    tableHeader: document.querySelector('#kt_app_table_header')?.clientHeight ?? 0,
    search: document.querySelector('#kt_app_table_search')?.clientHeight ?? 0,
    searchPadding: document.querySelector('#kt_app_table_search') ? 16 : 0,
    tableToolbar: document.querySelector('#kt_app_table_toolbar')?.clientHeight ?? 0,
    tableFooter: 48,
    // bottom: 16,
    separator: 4
  }
  let widgetHeight = 0
  for (const n in heights) {
    widgetHeight += heights[n]
  }
  return bodyHeight - widgetHeight
}

watch(isSearchCollapse, async value => {
  await nextTick()
  tableHeight.value = getTableHeight()
})

onMounted(() => {
  // tableHeight.value = getTableHeight()
  if (props.type === 'component') {
    initTable()
    initData()
  } else {
    useMenuStore().initialized.run(async () => {
      defaultMenu.value = useMenuStore().getMenuByName(route.name)
      await nextTick()
      tableHeight.value = getTableHeight()
    }, 'ZERO_TABLE_TITLE_INITIALIZED')
    // 初始化
    usePermissionStore().initialized.run(() => {
      initTable()
      initData()
      changePathToAdd()
      // eslint-disable-next-line no-undef
      // KTMenu.createInstances()
    }, 'ZERO_TABLE_PERMISSIONS_INITIALIZED')
  }
})

onUnmounted(() => {
  Event.remove('ZERO_TABLE.FIELD_CHANGED', emitFiledChanged)
  Event.remove(`${ZERO_TABLE_EVENTS.RELOAD}:${route.name}`)
})

defineExpose({ reload, initData, fields: computedFields, openSelectMode, selectedIdList, selectedItemList })
</script>
