import BasicQualityClassList from '../pages/quality/class/list'

export default [
  {
    path: '/basic/quality/class/add',
    name: 'basic-quality-class-add',
    component: () => import('../pages/quality/class/add')
  },
  {
    path: '/basic/quality/class/list',
    name: 'basic-quality-class-list',
    component: BasicQualityClassList
  },
  {
    path: '/basic/quality/class/change/:id',
    name: 'basic-quality-class-change',
    component: () => import('../pages/quality/class/change')
  },
  {
    path: '/basic/quality/item/add',
    name: 'basic-quality-item-add',
    component: () => import('../pages/quality/item/add')
  },
  {
    path: '/basic/quality/item/list',
    name: 'basic-quality-item-list',
    component: () => import('../pages/quality/item/list')
  },
  {
    path: '/basic/quality/item/change/:id',
    name: 'basic-quality-item-change',
    component: () => import('../pages/quality/item/change')
  },
  {
    path: '/basic/quality/item/detail/:id',
    name: 'basic-quality-item-detail',
    component: () => import('../pages/quality/item/detail')
  },
  {
    path: '/basic/quality/bad-cause/add',
    name: 'basic-quality-bad-cause-add',
    component: () => import('../pages/quality/bad-cause/add')
  },
  {
    path: '/basic/quality/bad-cause/list',
    name: 'basic-quality-bad-cause-list',
    component: () => import('../pages/quality/bad-cause/list')
  },
  {
    path: '/basic/quality/bad-cause/change/:id',
    name: 'basic-quality-bad-cause-change',
    component: () => import('../pages/quality/bad-cause/change')
  },
  {
    path: '/basic/quality/bad-cause-class/add',
    name: 'basic-quality-bad-cause-class-add',
    component: () => import('../pages/quality/bad-cause-class/add')
  },
  {
    path: '/basic/quality/bad-cause-class/list',
    name: 'basic-quality-bad-cause-class-list',
    component: () => import('../pages/quality/bad-cause-class/list')
  },
  {
    path: '/basic/quality/bad-cause-class/change/:id',
    name: 'basic-quality-bad-cause-class-change',
    component: () => import('../pages/quality/bad-cause-class/change')
  },
  {
    path: '/basic/quality/plan/add',
    name: 'basic-quality-plan-add',
    component: () => import('../pages/quality/plan/add')
  },
  {
    path: '/basic/quality/plan/list',
    name: 'basic-quality-plan-list',
    component: () => import('../pages/quality/plan/list')
  },
  {
    path: '/basic/quality/plan/change/:id',
    name: 'basic-quality-plan-change',
    component: () => import('../pages/quality/plan/change')
  },
  {
    path: '/basic/quality/plan/detail/:id',
    name: 'basic-quality-plan-detail',
    component: () => import('../pages/quality/plan/detail')
  },
  {
    path: '/basic/quality/bad-production-class/add',
    name: 'basic-quality-bad-production-class-add',
    component: () => import('../pages/quality/bad-production-class/add')
  },
  {
    path: '/basic/quality/bad-production-class/list',
    name: 'basic-quality-bad-production-class-list',
    component: () => import('../pages/quality/bad-production-class/list')
  },
  {
    path: '/basic/quality/bad-production-class/change/:id',
    name: 'basic-quality-bad-production-class-change',
    component: () => import('../pages/quality/bad-production-class/change')
  },
  {
    path: '/basic/quality/bad-production/add',
    name: 'basic-quality-bad-production-add',
    component: () => import('../pages/quality/bad-production/add')
  },
  {
    path: '/basic/quality/bad-production/list',
    name: 'basic-quality-bad-production-list',
    component: () => import('../pages/quality/bad-production/list')
  },
  {
    path: '/basic/quality/bad-production/change/:id',
    name: 'basic-quality-bad-production-change',
    component: () => import('../pages/quality/bad-production/change')
  },
  {
    path: '/basic/factory/place/list',
    name: 'basic-factory-place-list',
    component: () => import('../pages/factory/place/list')
  },
  {
    path: '/basic/factory/place/add',
    name: 'basic-factory-place-add',
    component: () => import('../pages/factory/place/add')
  },
  {
    path: '/basic/factory/place/change/:id',
    name: 'basic-factory-place-change',
    component: () => import('../pages/factory/place/change')
  },
  {
    path: '/basic/factory/workshop/add',
    name: 'basic-factory-workshop-add',
    component: () => import('../pages/factory/workshop/add')
  },
  {
    path: '/basic/factory/workshop/list',
    name: 'basic-factory-workshop-list',
    component: () => import('../pages/factory/workshop/list')
  },
  {
    path: '/basic/factory/workshop/change/:id',
    name: 'basic-factory-workshop-change',
    component: () => import('../pages/factory/workshop/change')
  },
  {
    path: '/basic/factory/production-line/add',
    name: 'basic-factory-production-line-add',
    component: () => import('../pages/factory/production-line/add')
  },
  {
    path: '/basic/factory/production-line/change/:id',
    name: 'basic-factory-production-line-change',
    component: () => import('../pages/factory/production-line/change')
  },
  {
    path: '/basic/factory/production-line/list',
    name: 'basic-factory-production-line-list',
    component: () => import('../pages/factory/production-line/list')
  },
  {
    path: '/basic/factory/station/add',
    name: 'basic-factory-station-add',
    meta: {
      keepAlive: true
    },
    component: () => import('../pages/factory/station/add')
  },
  {
    path: '/basic/factory/station/list',
    name: 'basic-factory-station-list',
    component: () => import('../pages/factory/station/list')
  },
  {
    path: '/basic/factory/station/change/:id',
    name: 'basic-factory-station-change',
    component: () => import('../pages/factory/station/change')
  },
  {
    path: '/basic/factory/station/detail/:id',
    name: 'basic-factory-station-detail',
    component: () => import('../pages/factory/station/detail')
  },
  {
    path: '/basic/factory/process/add',
    name: 'basic-factory-process-add',
    component: () => import('../pages/factory/process/add')
  },
  {
    path: '/basic/factory/process/list',
    name: 'basic-factory-process-list',
    component: () => import('../pages/factory/process/list')
  },
  {
    path: '/basic/factory/process/change/:id',
    name: 'basic-factory-process-change',
    component: () => import('../pages/factory/process/change')
  },
  {
    path: '/basic/factory/process/detail/:id',
    name: 'basic-factory-process-detail',
    component: () => import('../pages/factory/process/detail')
  },
  {
    path: '/basic/factory/technique/add',
    name: 'basic-factory-technique-add',
    component: () => import('../pages/factory/technique/add')
  },
  {
    path: '/basic/factory/technique/list',
    name: 'basic-factory-technique-list',
    component: () => import('../pages/factory/technique/list')
  },
  {
    path: '/basic/factory/technique/change/:id',
    name: 'basic-factory-technique-change',
    component: () => import('../pages/factory/technique/change')
  },
  {
    path: '/basic/factory/technique/detail/:id',
    name: 'basic-factory-technique-detail',
    component: () => import('../pages/factory/technique/detail')
  },
  {
    path: '/basic/factory/production-bom/add',
    name: 'basic-factory-production-bom-add',
    component: () => import('../pages/factory/production-bom/add')
  },
  {
    path: '/basic/factory/production-bom/change/:id',
    name: 'basic-factory-production-bom-change',
    component: () => import('../pages/factory/production-bom/change')
  },
  {
    path: '/basic/factory/production-bom/list',
    name: 'basic-factory-production-bom-list',
    component: () => import('../pages/factory/production-bom/list')
  },
  {
    path: '/basic/factory/production-bom/detail/:id',
    name: 'basic-factory-production-bom-detail',
    component: () => import('../pages/factory/production-bom/detail')
  },
  {
    path: '/basic/factory/team/add',
    name: 'basic-factory-team-add',
    component: () => import('../pages/factory/team/add')
  },
  {
    path: '/basic/factory/team/list',
    name: 'basic-factory-team-list',
    component: () => import('../pages/factory/team/list')
  },
  {
    path: '/basic/factory/team/change/:id',
    name: 'basic-factory-team-change',
    component: () => import('../pages/factory/team/change')
  },
  {
    path: '/basic/factory/team/detail/:id',
    name: 'basic-factory-team-detail',
    component: () => import('../pages/factory/team/detail')
  },
  {
    path: '/basic/factory/classes/add',
    name: 'basic-factory-classes-add',
    component: () => import('../pages/factory/classes/add')
  },
  {
    path: '/basic/factory/classes/list',
    name: 'basic-factory-classes-list',
    component: () => import('../pages/factory/classes/list')
  },
  {
    path: '/basic/factory/classes/change/:id',
    name: 'basic-factory-classes-change',
    component: () => import('../pages/factory/classes/change')
  },
  {
    path: '/basic/factory/classes/detail/:id',
    name: 'basic-factory-classes-detail',
    component: () => import('../pages/factory/classes/detail')
  },
  {
    path: '/basic/material/type/add',
    name: 'basic-material-type-add',
    component: () => import('../pages/material/type/add')
  },
  {
    path: '/basic/material/type/change/:id',
    name: 'basic-material-type-change',
    component: () => import('../pages/material/type/change')
  },
  {
    path: '/basic/material/type/list',
    name: 'basic-material-type-list',
    component: () => import('../pages/material/type/list')
  },
  {
    path: '/basic/material/definition/add',
    name: 'basic-material-definition-add',
    meta: {
      keepAlive: true
    },
    component: () => import('../pages/material/definition/add')
  },
  {
    path: '/basic/material/definition/change/:id',
    name: 'basic-material-definition-change',
    component: () => import('../pages/material/definition/change')
  },
  {
    path: '/basic/material/definition/detail/:id',
    name: 'basic-material-definition-detail',
    component: () => import('../pages/material/definition/detail')
  },
  {
    path: '/basic/material/definition/list',
    name: 'basic-material-definition-list',
    component: () => import('../pages/material/definition/list')
  },
  {
    path: '/basic/material/bom/add',
    name: 'basic-material-bom-add',
    component: () => import('../pages/material/bom/add')
  },
  {
    path: '/basic/material/bom/list',
    name: 'basic-material-bom-list',
    component: () => import('../pages/material/bom/list')
  },
  {
    path: '/basic/material/bom/change/:id',
    name: 'basic-material-bom-change',
    component: () => import('../pages/material/bom/change')
  },
  {
    path: '/basic/material/bom/detail/:id',
    name: 'basic-material-bom-detail',
    component: () => import('../pages/material/bom/detail')
  },
  {
    path: '/basic/material/unit/list',
    name: 'basic-material-unit-list',
    component: () => import('../pages/material/unit/list')
  },
  {
    path: '/basic/material/unit/add',
    name: 'basic-material-unit-add',
    component: () => import('../pages/material/unit/add')
  },
  {
    path: '/basic/material/unit/change/:id',
    name: 'basic-material-unit-change',
    component: () => import('../pages/material/unit/change')
  },
  {
    path: '/basic/warehouse/type/add',
    name: 'basic-warehouse-type-add',
    component: () => import('../pages/warehouse/type/add')
  },
  {
    path: '/basic/warehouse/type/list',
    name: 'basic-warehouse-type-list',
    component: () => import('../pages/warehouse/type/list')
  },
  {
    path: '/basic/warehouse/type/change/:id',
    name: 'basic-warehouse-type-change',
    component: () => import('../pages/warehouse/type/change')
  },
  {
    path: '/basic/warehouse/type/detail/:id',
    name: 'basic-warehouse-type-detail',
    component: () => import('../pages/warehouse/type/detail')
  },
  {
    path: '/basic/warehouse/archives/add',
    name: 'basic-warehouse-archives-add',
    component: () => import('../pages/warehouse/archives/add')
  },
  {
    path: '/basic/warehouse/archives/list',
    name: 'basic-warehouse-archives-list',
    component: () => import('../pages/warehouse/archives/list')
  },
  {
    path: '/basic/warehouse/archives/change/:id',
    name: 'basic-warehouse-archives-change',
    component: () => import('../pages/warehouse/archives/change')
  },
  {
    path: '/basic/warehouse/archives/detail/:id',
    name: 'basic-warehouse-archives-detail',
    component: () => import('../pages/warehouse/archives/detail')
  },
  {
    path: '/basic/warehouse/link-position/list',
    name: 'basic-warehouse-link-position-list',
    component: () => import('../pages/warehouse/link-position/list')
  },
  {
    path: '/basic/warehouse/config/add',
    name: 'basic-warehouse-config-add',
    component: () => import('../pages/warehouse/config/add')
  },
  {
    path: '/basic/warehouse/config/list',
    name: 'basic-warehouse-config-list',
    component: () => import('../pages/warehouse/config/list')
  },
  {
    path: '/basic/warehouse/config/change/:id',
    name: 'basic-warehouse-config-change',
    component: () => import('../pages/warehouse/config/change')
  },
  /* {
    path: '/basic/warehouse/storage/add',
    name: 'basic-warehouse-storage-add',
    component: () => import('../pages/warehouse/storage/add')
  },
  {
    path: '/basic/warehouse/storage/list',
    name: 'basic-warehouse-storage-list',
    component: () => import('../pages/warehouse/storage/list')
  },
  {
    path: '/basic/warehouse/storage/change/:id',
    name: 'basic-warehouse-storage-change',
    component: () => import('../pages/warehouse/storage/change')
  },
  {
    path: '/basic/warehouse/storage/detail/:id',
    name: 'basic-warehouse-storage-detail',
    component: () => import('../pages/warehouse/storage/detail')
  }, */
  {
    path: '/basic/merchants/supplier/add',
    name: 'basic-merchants-supplier-add',
    component: () => import('../pages/merchants/supplier/add')
  },
  {
    path: '/basic/merchants/supplier/list',
    name: 'basic-merchants-supplier-list',
    component: () => import('../pages/merchants/supplier/list')
  },
  {
    path: '/basic/merchants/supplier/change/:id',
    name: 'basic-merchants-supplier-change',
    component: () => import('../pages/merchants/supplier/change')
  },
  {
    path: '/basic/merchants/customer/add',
    name: 'basic-merchants-customer-add',
    component: () => import('../pages/merchants/customer/add')
  },
  {
    path: '/basic/merchants/customer/list',
    name: 'basic-merchants-customer-list',
    component: () => import('../pages/merchants/customer/list')
  },
  {
    path: '/basic/merchants/customer/change/:id',
    name: 'basic-merchants-customer-change',
    component: () => import('../pages/merchants/customer/change')
  },
  {
    path: '/basic/abnormal/type/add',
    name: 'basic-abnormal-type-add',
    component: () => import('../pages/abnormal/type/add')
  },
  {
    path: '/basic/abnormal/type/list',
    name: 'basic-abnormal-type-list',
    component: () => import('../pages/abnormal/type/list')
  },
  {
    path: '/basic/abnormal/type/change/:id',
    name: 'basic-abnormal-type-change',
    component: () => import('../pages/abnormal/type/change')
  },
  {
    path: '/basic/abnormal/title/add',
    name: 'basic-abnormal-title-add',
    component: () => import('../pages/abnormal/title/add')
  },
  {
    path: '/basic/abnormal/title/list',
    name: 'basic-abnormal-title-list',
    component: () => import('../pages/abnormal/title/list')
  },
  {
    path: '/basic/abnormal/title/change/:id',
    name: 'basic-abnormal-title-change',
    component: () => import('../pages/abnormal/title/change')
  },
  {
    path: '/basic/abnormal/config/add',
    name: 'basic-abnormal-config-add',
    component: () => import('../pages/abnormal/config/add')
  },
  {
    path: '/basic/abnormal/config/list',
    name: 'basic-abnormal-config-list',
    component: () => import('../pages/abnormal/config/list')
  },
  {
    path: '/basic/abnormal/config/change/:id',
    name: 'basic-abnormal-config-change',
    component: () => import('../pages/abnormal/config/change')
  },
  {
    path: '/basic/schedule/work-time/add',
    name: 'basic-schedule-work-time-add',
    component: () => import('../pages/schedule/work-time/add')
  },
  {
    path: '/basic/schedule/work-time/list',
    name: 'basic-schedule-work-time-list',
    component: () => import('../pages/schedule/work-time/list')
  },
  {
    path: '/basic/schedule/work-time/change/:id',
    name: 'basic-schedule-work-time-change',
    component: () => import('../pages/schedule/work-time/change')
  },
  {
    path: '/basic/schedule/calendar/add',
    name: 'basic-schedule-calendar-add',
    component: () => import('../pages/schedule/calendar/add')
  },
  {
    path: '/basic/schedule/calendar/list',
    name: 'basic-schedule-calendar-list',
    component: () => import('../pages/schedule/calendar/list')
  },
  {
    path: '/basic/schedule/calendar/change/:id',
    name: 'basic-schedule-calendar-change',
    component: () => import('../pages/schedule/calendar/change')
  },
  {
    path: '/basic/schedule/standard-capacity/add',
    name: 'basic-schedule-standard-capacity-add',
    component: () => import('../pages/schedule/standard-capacity/add')
  },
  {
    path: '/basic/schedule/standard-capacity/list',
    name: 'basic-schedule-standard-capacity-list',
    component: () => import('../pages/schedule/standard-capacity/list')
  },
  {
    path: '/basic/schedule/standard-capacity/change/:id',
    name: 'basic-schedule-standard-capacity-change',
    component: () => import('../pages/schedule/standard-capacity/change')
  },
  {
    path: '/basic/schedule/standard-capacity/detail/:id',
    name: 'basic-schedule-standard-capacity-detail',
    component: () => import('../pages/schedule/standard-capacity/detail')
  },
  {
    path: '/basic/schedule/capacity-constraints/add',
    name: 'basic-schedule-capacity-constraints-add',
    component: () => import('../pages/schedule/capacity-constraints/add')
  },
  {
    path: '/basic/schedule/capacity-constraints/list',
    name: 'basic-schedule-capacity-constraints-list',
    component: () => import('../pages/schedule/capacity-constraints/list')
  },
  {
    path: '/basic/schedule/capacity-constraints/change/:id',
    name: 'basic-schedule-capacity-constraints-change',
    component: () => import('../pages/schedule/capacity-constraints/change')
  },
  {
    path: '/basic/schedule/work-center/add',
    name: 'basic-schedule-work-center-add',
    component: () => import('../pages/schedule/work-center/add')
  },
  {
    path: '/basic/schedule/work-center/list',
    name: 'basic-schedule-work-center-list',
    component: () => import('../pages/schedule/work-center/list')
  },
  {
    path: '/basic/schedule/work-center/change/:id',
    name: 'basic-schedule-work-center-change',
    component: () => import('../pages/schedule/work-center/change')
  },
  {
    path: '/basic/schedule/work-center/detail/:id',
    name: 'basic-schedule-work-center-detail',
    component: () => import('../pages/schedule/work-center/detail')
  },
  {
    path: '/basic/schedule/dynamic-prepare-time/add',
    name: 'basic-schedule-dynamic-prepare-time-add',
    component: () => import('../pages/schedule/dynamic-prepare-time/add')
  },
  {
    path: '/basic/schedule/dynamic-prepare-time/list',
    name: 'basic-schedule-dynamic-prepare-time-list',
    component: () => import('../pages/schedule/dynamic-prepare-time/list')
  },
  {
    path: '/basic/schedule/dynamic-prepare-time/change/:id',
    name: 'basic-schedule-dynamic-prepare-time-change',
    component: () => import('../pages/schedule/dynamic-prepare-time/change')
  },
  {
    path: '/basic/logistics/receipt-return-reason/add',
    name: 'basic-logistics-receipt-return-reason-add',
    component: () => import('../pages/logistics/receipt-return-reason/add')
  },
  {
    path: '/basic/logistics/receipt-return-reason/list',
    name: 'basic-logistics-receipt-return-reason-list',
    component: () => import('../pages/logistics/receipt-return-reason/list')
  },
  {
    path: '/basic/logistics/receipt-return-reason/change/:id',
    name: 'basic-logistics-receipt-return-reason-change',
    component: () => import('../pages/logistics/receipt-return-reason/change')
  },
  {
    path: '/basic/logistics/send-return-reason/add',
    name: 'basic-logistics-send-return-reason-add',
    component: () => import('../pages/logistics/send-return-reason/add')
  },
  {
    path: '/basic/logistics/send-return-reason/list',
    name: 'basic-logistics-send-return-reason-list',
    component: () => import('../pages/logistics/send-return-reason/list')
  },
  {
    path: '/basic/logistics/send-return-reason/change/:id',
    name: 'basic-logistics-send-return-reason-change',
    component: () => import('../pages/logistics/send-return-reason/change')
  },
  {
    path: '/basic/tag/in/list',
    name: 'basic-tag-in-list',
    component: () => import('../pages/tag/in/list')
  },
  {
    path: '/basic/tag/in/add',
    name: 'basic-tag-in-add',
    component: () => import('../pages/tag/in/add')
  },
  {
    path: '/basic/tag/in/detail/:id',
    name: 'basic-tag-in-detail',
    component: () => import('../pages/tag/in/detail')
  },
  {
    path: '/basic/tag/mid-material/list',
    name: 'basic-tag-mid-material-list',
    component: () => import('../pages/tag/mid-material/list')
  },
  {
    path: '/basic/tag/out-rule/list',
    name: 'basic-tag-out-rule-list',
    component: () => import('../pages/tag/out-rule/list')
  },
  {
    path: '/basic/tag/out-rule/add',
    name: 'basic-tag-out-rule-add',
    component: () => import('../pages/tag/out-rule/add')
  },
  {
    path: '/basic/tag/out-rule/change/:id',
    name: 'basic-tag-out-rule-change',
    component: () => import('../pages/tag/out-rule/change')
  },
  {
    path: '/basic/tag/out-rule/detail/:id',
    name: 'basic-tag-out-rule-detail',
    component: () => import('../pages/tag/out-rule/detail')
  },
  {
    path: '/basic/tag/out/list',
    name: 'basic-tag-out-list',
    component: () => import('../pages/tag/out/list')
  },
  {
    path: '/basic/tag/out/create/:id',
    name: 'basic-tag-out-create',
    component: () => import('../pages/tag/out/create')
  },
  {
    path: '/basic/tag/out/print/:id',
    name: 'basic-tag-out-print',
    component: () => import('../pages/tag/out/print')
  },
  {
    path: '/basic/tag/fault-reason/list',
    name: 'basic-tag-fault-reason-list',
    component: () => import('../pages/tag/fault-reason/list')
  },
  {
    path: '/basic/tag/fault-reason/add',
    name: 'basic-tag-fault-reason-add',
    component: () => import('../pages/tag/fault-reason/add')
  },
  {
    path: '/basic/tag/fault-appear/list',
    name: 'basic-tag-fault-appear-list',
    component: () => import('../pages/tag/fault-appear/list')
  },
  {
    path: '/basic/tag/fault-appear/add',
    name: 'basic-tag-fault-appear-add',
    component: () => import('../pages/tag/fault-appear/add')
  },
  {
    path: '/basic/tag/device/list',
    name: 'basic-tag-device-list',
    component: () => import('../pages/tag/device/list')
  },
  {
    path: '/basic/tag/device/add',
    name: 'basic-tag-device-add',
    component: () => import('../pages/tag/device/add')
  },
  {
    path: '/basic/tag/knife/list',
    name: 'basic-tag-knife-list',
    component: () => import('../pages/tag/knife/list')
  },
  {
    path: '/basic/tag/knife/add',
    name: 'basic-tag-knife-add',
    component: () => import('../pages/tag/knife/add')
  },
  {
    path: '/basic/tag/new-tag/list',
    name: 'basic-tag-new-tag-list',
    component: () => import('../pages/tag/new-tag/list')
  },
  {
    path: '/basic/tag/new-tag/add',
    name: 'basic-tag-new-tag-add',
    component: () => import('../pages/tag/new-tag/add')
  },
  // {
  //   path: '/basic/tag/device-spare/list',
  //   name: 'basic-tag-device-spare-list',
  //   component: () => import('../pages/tag/device-spare/list')
  // },
  // {
  //   path: '/basic/tag/device-spare/add',
  //   name: 'basic-tag-device-spare-add',
  //   component: () => import('../pages/tag/device-spare/add')
  // },
  // {
  //   path: '/basic/tag/out/detail/:id',
  //   name: 'basic-tag-out-detail',
  //   component: () => import('../pages/tag/out/detail')
  // },
  {
    path: '/basic/schedule/task-pause-reason/list',
    name: 'basic-schedule-task-pause-reason-list',
    component: () => import('../pages/schedule/task-pause-reason/list')
  },
  {
    path: '/basic/schedule/task-pause-reason/change/:id',
    name: 'basic-schedule-task-pause-reason-change',
    component: () => import('../pages/schedule/task-pause-reason/change')
  },
  {
    path: '/basic/schedule/task-pause-reason/add',
    name: 'basic-schedule-task-pause-reason-add',
    component: () => import('../pages/schedule/task-pause-reason/add')
  },
  {
    path: '/basic/device/fail-reason/add',
    name: 'basic-device-fail-reason-add',
    component: () => import('../pages/device/fail-reason/add')
  },
  {
    path: '/basic/device/fail-reason/change/:id',
    name: 'basic-device-fail-reason-change',
    component: () => import('../pages/device/fail-reason/change')
  },
  {
    path: '/basic/device/fail-reason/detail/:id',
    name: 'basic-device-fail-reason-detail',
    component: () => import('../pages/device/fail-reason/detail')
  },
  {
    path: '/basic/device/fail-reason/list',
    name: 'basic-device-fail-reason-list',
    component: () => import('../pages/device/fail-reason/list')
  },
  {
    path: '/basic/device/fail-reason-type/add',
    name: 'basic-device-fail-reason-type-add',
    component: () => import('../pages/device/fail-reason-type/add')
  },
  {
    path: '/basic/device/fail-reason-type/change/:id',
    name: 'basic-device-fail-reason-type-change',
    component: () => import('../pages/device/fail-reason-type/change')
  },
  {
    path: '/basic/device/fail-reason-type/detail/:id',
    name: 'basic-device-fail-reason-type-detail',
    component: () => import('../pages/device/fail-reason-type/detail')
  },
  {
    path: '/basic/device/fail-reason-type/list',
    name: 'basic-device-fail-reason-type-list',
    component: () => import('../pages/device/fail-reason-type/list')
  },
  {
    path: '/basic/device/standing-book/add',
    name: 'basic-device-standing-book-add',
    component: () => import('../pages/device/standing-book/add')
  },
  {
    path: '/basic/device/standing-book/change/:id',
    name: 'basic-device-standing-book-change',
    component: () => import('../pages/device/standing-book/change')
  },
  {
    path: '/basic/device/standing-book/detail/:id',
    name: 'basic-device-standing-book-detail',
    component: () => import('../pages/device/standing-book/detail')
  },
  {
    path: '/basic/device/standing-book/list',
    name: 'basic-device-standing-book-list',
    component: () => import('../pages/device/standing-book/list')
  },
  {
    path: '/basic/device/type/add',
    name: 'basic-device-type-add',
    component: () => import('../pages/device/type/add')
  },
  {
    path: '/basic/device/type/change/:id',
    name: 'basic-device-type-change',
    component: () => import('../pages/device/type/change')
  },
  {
    path: '/basic/device/type/detail/:id',
    name: 'basic-device-type-detail',
    component: () => import('../pages/device/type/detail')
  },
  {
    path: '/basic/device/type/list',
    name: 'basic-device-type-list',
    component: () => import('../pages/device/type/list')
  },
  {
    path: '/basic/device/fail-appear/add',
    name: 'basic-device-fail-appear-add',
    component: () => import('../pages/device/fail-appear/add')
  },
  {
    path: '/basic/device/fail-appear/change/:id',
    name: 'basic-device-fail-appear-change',
    component: () => import('../pages/device/fail-appear/change')
  },
  {
    path: '/basic/device/fail-appear/detail/:id',
    name: 'basic-device-fail-appear-detail',
    component: () => import('../pages/device/fail-appear/detail')
  },
  {
    path: '/basic/device/fail-appear/list',
    name: 'basic-device-fail-appear-list',
    component: () => import('../pages/device/fail-appear/list')
  },
  {
    path: '/basic/device/fail-appear-type/add',
    name: 'basic-device-fail-appear-type-add',
    component: () => import('../pages/device/fail-appear-type/add')
  },
  {
    path: '/basic/device/fail-appear-type/change/:id',
    name: 'basic-device-fail-appear-type-change',
    component: () => import('../pages/device/fail-appear-type/change')
  },
  {
    path: '/basic/device/fail-appear-type/detail/:id',
    name: 'basic-device-fail-appear-type-detail',
    component: () => import('../pages/device/fail-appear-type/detail')
  },
  {
    path: '/basic/device/fail-appear-type/list',
    name: 'basic-device-fail-appear-type-list',
    component: () => import('../pages/device/fail-appear-type/list')
  },
  {
    path: '/basic/order/config/list',
    name: 'basic-order-config-list',
    component: () => import('../pages/order/config/list')
  },
  {
    path: '/basic/order/config/add',
    name: 'basic-order-config-add',
    component: () => import('../pages/order/config/add')
  },
  {
    path: '/basic/order/config/change/:id',
    name: 'basic-order-config-change',
    component: () => import('../pages/order/config/change')
  },
  {
    path: '/basic/order/bad-reason/list',
    name: 'basic-order-bad-reason-list',
    component: () => import('../pages/order/bad-reason/list')
  },
  {
    path: '/basic/order/bad-reason/add',
    name: 'basic-order-bad-reason-add',
    component: () => import('../pages/order/bad-reason/add')
  },
  {
    path: '/basic/order/bad-reason/change/:id',
    name: 'basic-order-bad-reason-change',
    component: () => import('../pages/order/bad-reason/change')
  },
  {
    path: '/basic/order/material/list',
    name: 'basic-order-material-list',
    component: () => import('../pages/order/material/list')
  },
  {
    path: '/basic/order/material/add',
    name: 'basic-order-material-add',
    component: () => import('../pages/order/material/add')
  },
  {
    path: '/basic/order/material/change/:id',
    name: 'basic-order-material-change',
    component: () => import('../pages/order/material/change')
  },
  {
    path: '/basic/order/process/list',
    name: 'basic-order-process-list',
    component: () => import('../pages/order/process/list')
  },
  {
    path: '/basic/order/process/add',
    name: 'basic-order-process-add',
    component: () => import('../pages/order/process/add')
  },
  {
    path: '/basic/order/process/change/:id',
    name: 'basic-order-process-change',
    component: () => import('../pages/order/process/change')
  },
  {
    path: '/basic/order/technology/list',
    name: 'basic-order-technology-list',
    component: () => import('../pages/order/technology/list')
  },
  {
    path: '/basic/order/technology/add',
    name: 'basic-order-technology-add',
    component: () => import('../pages/order/technology/add')
  },
  {
    path: '/basic/order/technology/change/:id',
    name: 'basic-order-technology-change',
    component: () => import('../pages/order/technology/change')
  },
  {
    path: '/basic/order/technology/detail/:id',
    name: 'basic-order-technology-detail',
    component: () => import('../pages/order/technology/detail')
  },
  {
    path: '/basic/order/resource/list',
    name: 'basic-order-resource-list',
    component: () => import('../pages/order/resource/list')
  },
  {
    path: '/basic/order/resource/add',
    name: 'basic-order-resource-add',
    component: () => import('../pages/order/resource/add')
  },
  {
    path: '/basic/order/resource/change/:id',
    name: 'basic-order-resource-change',
    component: () => import('../pages/order/resource/change')
  },
  {
    path: '/basic/personnel/worktype/list',
    name: 'basic-personnel-worktype-list',
    component: () => import('../pages/personnel/worktype/list')
  },
  {
    path: '/basic/personnel/worktype/add',
    name: 'basic-personnel-worktype-add',
    component: () => import('../pages/personnel/worktype/add')
  },
  {
    path: '/basic/personnel/worktype/change/:id',
    name: 'basic-personnel-worktype-change',
    component: () => import('../pages/personnel/worktype/change')
  },
  {
    path: '/basic/personnel/worktype/detail/:id',
    name: 'basic-personnel-worktype-detail',
    component: () => import('../pages/personnel/worktype/detail')
  },
  {
    path: '/basic/personnel/material-price/list',
    name: 'basic-personnel-material-price-list',
    component: () => import('../pages/personnel/material-price/list')
  },
  {
    path: '/basic/personnel/material-price/add',
    name: 'basic-personnel-material-price-add',
    component: () => import('../pages/personnel/material-price/add')
  },
  {
    path: '/basic/personnel/material-price/change/:id',
    name: 'basic-personnel-material-price-change',
    component: () => import('../pages/personnel/material-price/change')
  },
  {
    path: '/basic/personnel/material-price/detail/:id',
    name: 'basic-personnel-material-price-detail',
    component: () => import('../pages/personnel/material-price/detail')
  },
  {
    path: '/basic/order/personnel-material-price/list',
    name: 'basic-order-personnel-material-price-list',
    component: () => import('../pages/order/personnel-material-price/list')
  },
  {
    path: '/basic/order/personnel-material-price/add',
    name: 'basic-order-personnel-material-price-add',
    component: () => import('../pages/order/personnel-material-price/add')
  },
  {
    path: '/basic/order/personnel-material-price/change/:id',
    name: 'basic-order-personnel-material-price-change',
    component: () => import('../pages/order/personnel-material-price/change')
  },
  {
    path: '/basic/order/personnel-material-price/detail/:id',
    name: 'basic-order-personnel-material-price-detail',
    component: () => import('../pages/order/personnel-material-price/detail')
  },
  {
    path: '/basic/knife/type/list',
    name: 'basic-knife-type-list',
    component: () => import('../pages/knife/type/list')
  },
  {
    path: '/basic/knife/type/add',
    name: 'basic-knife-type-add',
    component: () => import('../pages/knife/type/add')
  },
  {
    path: '/basic/knife/type/change/:id',
    name: 'basic-knife-type-change',
    component: () => import('../pages/knife/type/change')
  },
  {
    path: '/basic/knife/type/detail/:id',
    name: 'basic-knife-type-detail',
    component: () => import('../pages/knife/type/detail')
  },
  {
    path: '/basic/knife/structure/list',
    name: 'basic-knife-structure-list',
    component: () => import('../pages/knife/structure/list')
  },
  {
    path: '/basic/knife/structure/add',
    name: 'basic-knife-structure-add',
    component: () => import('../pages/knife/structure/add')
  },
  {
    path: '/basic/knife/structure/change/:id',
    name: 'basic-knife-structure-change',
    component: () => import('../pages/knife/structure/change')
  },
  {
    path: '/basic/knife/structure/detail/:id',
    name: 'basic-knife-structure-detail',
    component: () => import('../pages/knife/structure/detail')
  },
  {
    path: '/basic/knife/definition/list',
    name: 'basic-knife-definition-list',
    component: () => import('../pages/knife/definition/list')
  },
  {
    path: '/basic/knife/definition/add',
    name: 'basic-knife-definition-add',
    component: () => import('../pages/knife/definition/add')
  },
  {
    path: '/basic/knife/definition/change/:id',
    name: 'basic-knife-definition-change',
    component: () => import('../pages/knife/definition/change')
  },
  {
    path: '/basic/knife/definition/detail/:id',
    name: 'basic-knife-definition-detail',
    component: () => import('../pages/knife/definition/detail')
  }
]
