<template>
  <action-item
    id="kt_header_switch_theme_mode_toggle"
    hover-width="80"
    expand-width="150"
    expand-height="136px"
    action-thumb-class="d-flex"
    action-expand-class="menu-state-color menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base"
    variant="color-state"
    @actionShown="showAction"
    :action-expend-attributes="{ 'data-kt-element': 'theme-mode-menu' }"
  >
    <template #action-thumb>
      <a href="#" class="btn btn-icon btn-custom btn-icon-light w-40px h-40px" data-kt-menu-trigger="{default:'click'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
        <div class="theme-light-show d-flex justify-content-end">
          <span class="w-40px hover-primary"><icon name="night-day" paths="10" size="2x" /></span>
        </div>
        <div class="theme-dark-show d-flex justify-content-end">
          <span class="w-40px"><icon name="moon" size="2x" /></span>
        </div>
      </a>
      <span class="w-30px align-self-center text-gray-500 theme-light-show active-show hover-show">浅色</span>
      <span class="w-30px align-self-center text-gray-500 theme-dark-show active-show hover-show">深色</span>
    </template>
    <template #action-expand>
      <div class="menu-item px-3 my-0">
        <a href="#" class="menu-link rounded-1 bg-hover-light-primary px-3 py-2 active" data-kt-element="mode" data-kt-value="light">
          <span class="menu-icon" data-kt-element="icon">
            <icon name="night-day" paths="10" size="2" />
          </span>
          <span class="menu-title">浅色</span>
        </a>
      </div>
      <div class="menu-item px-3 my-0">
        <a href="#" class="menu-link rounded-1 bg-hover-light-primary px-3 py-2" data-kt-element="mode" data-kt-value="dark">
          <span class="menu-icon" data-kt-element="icon">
            <icon name="moon" size="2" />
          </span>
          <span class="menu-title">深色</span>
        </a>
      </div>
      <div class="menu-item px-3 my-0">
        <a href="#" class="menu-link rounded-1 bg-hover-light-primary px-3 py-2" data-kt-element="mode" data-kt-value="system">
          <span class="menu-icon" data-kt-element="icon">
            <icon name="screen" size="2" paths="4" />
          </span>
          <span class="menu-title">跟随系统</span>
        </a>
      </div>
    </template>
  </action-item>
</template>

<script>
import { Component, Vue } from '@min/vue-compatible-decorator'
import ActionItem from '@/components/layout/header-components/action-item'

@Component({
  components: { ActionItem }
})
class ThemeSwitch extends Vue {
  showAction () {
    // eslint-disable-next-line no-undef
    KTThemeMode.init()
  }
}

export default ThemeSwitch
</script>
