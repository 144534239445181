<template>
  <b-row v-if="computedConfig.forms" class="bg-white border-start border-bottom border-gray-200 mx-0">
    <template v-for="(form, i) in computedConfig.forms" :key="`form-${form.key}`">
      <label class="form-control-label-plaintext text-end bg-gray-100 text-gray-700 border-end border-top border-gray-200 min-h-40px col-1">
        <span v-if="form.required" class="required">{{ form.label.text }}</span>
        <span v-else>{{ form.label.text && form.label.text !== '' ? form.label.text : ' ' }}</span>
      </label>
      <b-col :cols="computedConfig.forms.length - 1 === i ? computedConfig.modConfig.containerCol : '3'" class="border-top border-end border-gray-200 min-h-40px d-flex align-items-start">
        <slot :name="form.key" :form="form" :data="props.data">
          <component
            :ref="form.key"
            :name="form.key"
            :static="true"
            :is="form.component || form.type"
            :data="props.data[form.key]"
            v-bind="form.config ?? {}"
            v-model="$_VALUE[form.key]"
          />
        </slot>
      </b-col>
    </template>
  </b-row>
  <template v-else>
    <slot :name="computedConfig.key" :data="props.data" :form="computedConfig">
      <component
        :is="computedConfig.component"
        :isStaticMode="true"
        :name="computedConfig.key"
        :config="computedConfig.config ?? {}"
        :data="(props.data && props.data[computedConfig.key]) ? props.data[computedConfig.key] : undefined"
      />
    </slot>
  </template>
</template>
<script setup>
import { defineProps, ref, watch } from 'vue'
import { getStaticComponent } from './internal-types'
import { isPromise, typeOf } from '../utils/common'

const colList = {
  0: { label: 'col-1', content: 'col-3', container: '3' },
  1: { label: 'col-1', content: 'col-11', container: '11' },
  2: { label: 'col-1', content: 'col-7', container: '7' }
}

const props = defineProps({
  data: { type: Object, default: () => { return {} } },
  config: {}
})

const computedConfig = ref({})
const $_VALUE = ref(props.data)
// const $_HIDDEN_STATUS = ref({})

watch(() => props.data, value => {
  $_VALUE.value = value
  // parseDisplayStatus(value)
  initStatic()
}, { deep: true })

function parseDisplayStatus (value, forms) {
  // display为函数，参数为value，返回true/false
  // 返回true则留在列表中，返回false则从列表中删除
  return forms.filter(form => {
    if (form.display) {
      return form.display(value)
    } else {
      return true
    }
  })
}

function parseLabel (label) {
  if (typeOf(label) === 'string') {
    return {
      text: label
    }
  } else {
    return label
  }
}

async function initStatic () {
  const config = props.config

  // 简单组件集合模式
  if (config.forms) {
  // 平铺forms配置
    const forms = []
    for (const i in config.forms) {
      if (config.forms[i].length) {
        for (const k in config.forms[i]) {
          const form = config.forms[i][k]
          form.componentRaw = form.component ?? form.type
          form.component = getStaticComponent(form.componentRaw)
          form.label = parseLabel(form.label)
          if (!form.config) {
            form.config = {}
          }
          // 初始化含有options的config
          if (form.options) {
            form.config.options = form.options
          }

          if (form.config.options && isPromise(form.config.options)) {
            form.config.options = await form.config.options
          }
          forms.push(form)
        }
      } else {
        const form = config.forms[i]
        form.componentRaw = form.component ?? form.type
        form.component = getStaticComponent(form.componentRaw)
        form.label = parseLabel(form.label)
        if (!form.config) {
          form.config = {}
        }
        // 初始化含有options的config
        if (form.options) {
          form.config.options = form.options
        }

        if (form.config.options && isPromise(form.config.options)) {
          form.config.options = await form.config.options
        }
        forms.push(form)
      }
    }
    config.forms = parseDisplayStatus($_VALUE.value, forms)

    // 计算最后一行填充宽度
    const mod = config.forms.length % 3
    config.modConfig = {
      containerCol: colList[mod].container,
      labelCol: colList[mod].label,
      contentCol: colList[mod].content
    }
  } else {
    config.componentRaw = config.component ?? config.type
    config.component = getStaticComponent(config.componentRaw)
  }

  computedConfig.value = config
}

/* async function getConfig (formConfig) {
  const config = formConfig.config ?? {}
  if (formConfig.options) {
    config.options = formConfig.options
  }

  if (config.options && isPromise(config.options)) {
    config.options = await config.options
  }

  return config
} */

// onMounted(() => {
//   initStatic()
// })
</script>
