<template>
  <wrapper no-header>
    <div class="card card-custom card-stretch shadow-none">
      <div class="card-header pt-6">
        <div class="card-title">
          <div class="d-flex align-items-center position-relative my-1">
            <icon name="magnifier" size="1" paths="2" class="position-absolute ms-6" />
            <input v-model="keywords" type="text" class="form-control form-control-solid w-250px ps-15 me-3" placeholder="关键字">
            <button @click="getRecords" type="button" class="btn btn-flex btn-primary">搜索</button>
          </div>
        </div>
        <div class="card-toolbar">
          <div class="d-flex justify-content-end align-items-center">
            <span class="text-muted me-3">{{ unreadCount }}条未读</span>
            <button type="button" class="btn btn-flex btn-light-primary" @click.prevent="allRead">全部标记已读</button>
          </div>
        </div>
      </div>
      <div class="card-body table-responsive px-8 pb-0 mb-0">
        <div :key="`list-${index}`" v-for="(record, index) in records" class="list border-bottom-dashed border-bottom-1 border-gray-200 min-w-500px">
          <div class="d-flex align-items-start list-item card-spacer-x py-3" @click.prevent="detail(record.id)">
            <div class="d-flex align-self-stretch w-200px">
              <div class="d-flex align-items-center flex-wrap w-180px me-3">
                <a href="#" class="fw-bold text-gray-600">
                  <span v-if="record.status !== 1" class="label label-dot label-success me-3"></span>
                  <span v-else class="label label-dot label-white me-3"></span>
                  <b-label type="rounded" inline variant="light-primary" v-if="record.module" class="me-2">{{ record.module }}</b-label>
                  {{ record.type }}
                </a>
              </div>
            </div>
            <div class="flex-grow-1 mt-2 me-2">
              <div>
                <span :class="['fs-lg me-2 text-gray-700', record.status !== 1 ? 'fw-bold text-gray-900' : '']">{{ record.title }}</span>
              </div>
              <div class="mt-2">
                <span class="text-muted">{{ record.truncatedContent }}</span>
              </div>
            </div>
            <div class="mt-2 w-200px text-right text-muted">{{ record.create_time }}</div>
          </div>
        </div>
      </div>
      <div id="kt_app_table_footer" class="card-footer d-flex justify-content-between px-6 py-0 bg-white border-0 z-index-3">
        <Pagination
          v-model:current="pagination.current"
          v-model:total="pagination.total"
          v-model:size="pagination.size"
          @update:current="pageChange"
          @update:size="pageChange"
        ></Pagination>
      </div>
<!--      <b-row>-->
<!--        <div class="col-12 d-flex align-items-end justify-content-end text-right my-4">-->
<!--          <div class="d-flex align-self-center me-2" data-toggle="tooltip" title="Records per page">-->
<!--            <span class="text-muted fw-bold me-2">{{ (pagination.current - 1) * pagination.size + 1 }} - {{ (pagination.current - 1) * pagination.size + records.length }}, 共 {{ pagination.total}} 条</span>-->
<!--          </div>-->
<!--          <span :class="['btn btn-default btn-icon btn-sm me-2', pagination.current === 1 ? 'disabled' : '']" data-toggle="tooltip" title="上一页" @click.prevent="navigation('prev')">-->
<!--            <i class="ki-duotone ki-left fs-4" />-->
<!--          </span>-->
<!--          <span :class="['btn btn-default btn-icon btn-sm me-2', (pagination.current * pagination.size >= pagination.total) ? 'disabled' : '']" data-toggle="tooltip" title="下一页" @click.prevent="navigation('next')">-->
<!--            <i class="ki-duotone ki-right fs-4" />-->
<!--          </span>-->
<!--        </div>-->
<!--      </b-row>-->
    </div>
    <b-modal id="modal-record-detail" size="lg" v-model="showDetail" centered cancel-title="关闭" cancel-variant="light-primary" ok-disabled :title="`${currentRecord.type} - ${currentRecord.title}`">
      <h3 class="mb-4">{{ currentRecord.title }} <small>{{ currentRecord.type }}</small></h3>
      <p class="fw-bolder mb-3">{{ currentRecord.module }}</p>
      <p class="mb-3">{{ currentRecord.create_time }}</p>
      <p>
        {{ currentRecord.content }}
      </p>
      <template #footer>
        <b-button variant="light-primary" class="px-6" @click.prevent="showDetail = false">关闭</b-button>
      </template>
    </b-modal>
  </wrapper>
</template>

<script>
// 修饰器依赖
import { Component, Vue } from '@min/vue-compatible-decorator'
import NotificationService from '@/services/notification'
import Pagination from '@/components/zero-vue/components/pagination.vue'
import { isEmpty } from '@/components/zero-vue/utils/common'
export default @Component({
  methods: { isEmpty },
  components: { Pagination }
})
class Notification extends Vue {
  keywords = ''
  records = []
  pagination = {
    current: 1,
    pages: 1,
    size: 20,
    total: 0
  }

  unreadCount = 0
  currentRecord = {}
  showDetail = false

  truncateSize = 80

  mounted () {
    this.getRecords()
  }

  async detail (id) {
    this.currentRecord = await NotificationService.getDetail(id)
    this.showDetail = true
    if (this.currentRecord.status === 0) {
      await NotificationService.read(id, 1)
      await this.getRecords()
    }
  }

  navigation (type) {
    let page = this.pagination.current
    switch (type) {
      case 'prev':
        if (page <= 1) return
        page--
        break
      case 'next':
      default:
        if (page * this.pagination.size >= this.pagination.total) return
        page++
        break
    }
    this.pagination.current = page
    this.getRecords()
  }

  async allRead () {
    await NotificationService.allRead(1, 1)
    await this.getRecords()
  }

  async getRecords () {
    // let unreadCount = 0
    const response = await NotificationService.getList({ content: this.keywords, title: this.keywords, page: this.pagination.current, size: this.pagination.size })
    if (response.records) {
      for (const i in response.records) {
        if (response.records[i].status !== 1) {
          // unreadCount++
        }
        if (response.records[i].content && response.records[i].content.length > this.truncateSize) {
          response.records[i].truncatedContent = response.records[i].content.substring(0, this.truncateSize) + '...'
        } else {
          response.records[i].truncatedContent = response.records[i].content
        }
      }
    }
    this.records = response.records || []
    this.pagination = response.pagination || {
      current: 1,
      pages: 1,
      size: 20,
      total: 0
    }
    this.unreadCount = await NotificationService.getAllNoRead()
  }

  pageChange () {
    this.getRecords()
  }
}
</script>
