export default [
  {
    path: '/purchase/purchase-request/add',
    name: 'purchase-purchase-request-add',
    component: () => import('../pages/purchase-request/add')
  },
  {
    path: '/purchase/purchase-request/list',
    name: 'purchase-purchase-request-list',
    component: () => import('../pages/purchase-request/list')
  },
  {
    path: '/purchase/purchase-request/change/:id',
    name: 'purchase-purchase-request-change',
    component: () => import('../pages/purchase-request/change')
  },
  {
    path: '/purchase/purchase-request/detail/:id',
    name: 'purchase-purchase-request-detail',
    component: () => import('../pages/purchase-request/detail')
  },
  {
    path: '/purchase/purchase-order/add',
    name: 'purchase-purchase-order-add',
    component: () => import('../pages/purchase-order/add')
  },
  {
    path: '/purchase/purchase-order/list',
    name: 'purchase-purchase-order-list',
    component: () => import('../pages/purchase-order/list')
  },
  {
    path: '/purchase/purchase-order/change/:id',
    name: 'purchase-purchase-order-change',
    component: () => import('../pages/purchase-order/change')
  },
  {
    path: '/purchase/purchase-order/detail/:id',
    name: 'purchase-purchase-order-detail',
    component: () => import('../pages/purchase-order/detail')
  },
  {
    path: '/purchase/receive-invoice/add',
    name: 'purchase-receive-invoice-add',
    component: () => import('../pages/receive-invoice/add')
  },
  {
    path: '/purchase/receive-invoice/list',
    name: 'purchase-receive-invoice-list',
    component: () => import('../pages/receive-invoice/list')
  },
  {
    path: '/purchase/receive-invoice/change/:id',
    name: 'purchase-receive-invoice-change',
    component: () => import('../pages/receive-invoice/change')
  },
  {
    path: '/purchase/receive-invoice/detail/:id',
    name: 'purchase-receive-invoice-detail',
    component: () => import('../pages/receive-invoice/detail')
  },
  {
    path: '/purchase/return-invoice/add',
    name: 'purchase-return-invoice-add',
    component: () => import('../pages/return-invoice/add')
  },
  {
    path: '/purchase/return-invoice/list',
    name: 'purchase-return-invoice-list',
    component: () => import('../pages/return-invoice/list')
  },
  {
    path: '/purchase/return-invoice/change/:id',
    name: 'purchase-return-invoice-change',
    component: () => import('../pages/return-invoice/change')
  },
  {
    path: '/purchase/return-invoice/detail/:id',
    name: 'purchase-return-invoice-detail',
    component: () => import('../pages/return-invoice/detail')
  }
]
