import { toLineCase } from '../utils/strings'
import { isEmpty, typeOf } from '../utils/common'

const INTERNAL_TYPES = {
  select: {
    alias: 'zero-select'
  },
  'tree-select': 'zero-select',
  'b-select': 'zero-select',
  input: {
    component: 'zero-input',
    static: 'zero-input'
  },
  static: 'zero-input',
  'b-input': 'zero-input',
  'b-form-input': 'zero-input',
  'vue-number-input': 'zero-number-input',
  'number-input': 'zero-number-input',
  number: 'zero-number-input',
  textarea: 'zero-textarea',
  'b-textarea': 'zero-textarea',
  'checkbox-group': 'b-form-checkbox-group',
  'radio-group': 'zero-radio-group',
  tags: 'b-form-tags',
  'file-upload': 'zero-file-upload',
  'zero-form': {
    component: 'zero-form-block',
    static: 'zero-form-block-static',
    fillStatus: true,
    verify: true
  },
  'form-info-block': {
    component: 'zero-form-info-block',
    fillStatus: true,
    verify: true
  },
  'form-table-inline': {
    component: 'form-table-inline',
    static: 'zero-form-table-inline-static',
    fillStatus: true,
    verify: true
  },
  'zero-form-table-inline': {
    alias: 'form-table-inline'
  },
  'form-table': {
    component: 'zero-form-table',
    static: 'zero-form-table-static',
    fillStatus: true,
    verify: true
  },
  'zero-form-tab': {
    alias: 'form-tab'
  },
  'form-tab': {
    component: 'zero-form-tab',
    static: 'zero-form-tab-static',
    fillStatus: true,
    verify: true
  }
}

function hasComponent (name) {
  return !!INTERNAL_TYPES[toLineCase(name)]
}

function getRecord (name) {
  if (!name) {
    return INTERNAL_TYPES.input
  }
  const record = INTERNAL_TYPES[toLineCase(name)] ?? toLineCase(name)
  if (isEmpty(record)) {
    return INTERNAL_TYPES.input
  } else {
    return record.alias ? getRecord(record.alias) : record
  }
}

function getComponent (name) {
  const record = getRecord(name)
  return (typeOf(record) === 'string') ? record : record.component
}

function getStaticComponent (name) {
  const record = getRecord(name)
  return (typeOf(record) === 'string') ? record : (record.static ?? record.component)
}

function getFillStatus (name) {
  const record = getRecord(name)
  return (typeOf(record) === 'string') ? false : record.fillStatus
}

function getVerify (name) {
  const record = getRecord(name)
  return (typeOf(record) === 'string') ? false : record.verify
}

export default INTERNAL_TYPES
export { hasComponent, getComponent, getStaticComponent, getFillStatus, getVerify }
