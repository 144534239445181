import { getVm } from '@/utils/vm'
import Event from '@/utils/event'
import { cancel } from '@/components/layout/page-tabs'
import { ZERO_TABLE_EVENTS } from '@/components/zero-vue/events'

export function registerGlobalMethods (vue) {
  vue.config.globalProperties.$closeModal = () => {
    Event.emit('LAYOUT:ACTION:MODAL:CLOSE')
  }
  vue.config.globalProperties.$getRoute = () => {
    return getVm().$attrs.route || getVm().$route
  }
  vue.config.globalProperties.$closeTabAndSwitch = (route) => {
    const to = cancel(route)
    Event.emit(ZERO_TABLE_EVENTS.RELOAD, 'default', to)
    Event.emit(`${ZERO_TABLE_EVENTS.RELOAD}:${to.name}`, 'default', to)
  }
  vue.provide('$closeTabAndSwitch', vue.config.globalProperties.$closeTabAndSwitch)
}
