/*
 * @Author: xuzhitao
 * @Date: 2023-01-04 09:04:56
 * @LastEditors: xuzhitao
 * @LastEditTime: 2023-01-04 15:51:05
 * @FilePath: /mirrors.in-mes.com-ui/src/stores/menu.ts
 */
import { defineStore } from 'pinia'
import { initCondition } from '@/utils/condition'

let Menus = []

export const useBreadCrumbStore = defineStore({
  id: 'breadCrumb',
  state: () => ({
    store: [],
    raw: [],
    all: [],
    initialized: initCondition('breadCrumb')
  }),
  getters: {
    breadCrumb () {
      return this.store
    },
    isInitialized () {
      return this.initialized.status
    }
  },
  actions: {
    init (route, menus) {
      const store = getBreadCrumb(route2Menu(route, menus))

      for (const n in store) {
        if (store[n].id === 100000010) {
          store.splice(n, 1)
        }
      }

      this.store = store
      this.initialized.set()
    }
  }
})

function route2Menu (route, menus) {
  Menus = menus
  if (!['', '/'].includes(route.fullpath)) {
    for (const n in menus) {
      if (route.name.replace(/-/g, '_') === menus[n].name) {
        return menus[n]
      }
    }
  }
  return null
}

function getBreadCrumb (menu) {
  let currentStack = []
  if (!menu) {
    return currentStack
  } else {
    currentStack = getBreadCrumb(Menus[menu.pid])
    return currentStack.concat([menu])
  }
}
