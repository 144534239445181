<template>
  <action-item
    id="kt_header_dashboard_card"
    hover-width="40"
    expand-width="650"
    expand-height="350px"
    action-thumb-class="d-flex"
    variant="color-state"
  >
    <template #action-thumb>
      <a href="#" class="btn btn-icon btn-icon-light w-40px h-40px">
        <icon type="ri" name="dashboard" mode="fill" size="6" />
      </a>
    </template>
    <template #action-expand>
      <div class="d-flex flex-column bg-primary background-with-dashboard-card">
        <h4 class="text-white fw-semibold px-4 mt-4 mb-2">
          数据看板
        </h4>
        <b-row class="m-4 mb-0">
          <b-col cols="3" class="d-flex justify-content-center mb-4" v-for="(menu, index) in dashboardList" :key="`dashboard-${index}`">
            <div class="w-125px h-125px d-flex flex-column align-items-center justify-content-center bg-white position-relative">
              <img :src="`/assets/img/${menu.meta.icon.name}.svg`" />
              <a href="#" @click.prevent="openDashboard(menu)" class="text-gray-700 text-center w-100 px-3 mt-3 stretched-link text-truncate">{{ menu.meta.title }}</a>
            </div>
          </b-col>
          <b-col cols="3" v-for="(menu, index) in customDashboardList" :key="`dashboard-custom-${index}`">
            <div class="w-125px h-125px d-flex flex-column align-items-center justify-content-center bg-white position-relative">
              <img :src="`/assets/img/${menu.meta.icon.name}.svg`" />
              <a href="#" @click.prevent="openDashboard(menu)" class="text-gray-700 text-center w-100 px-3 px-4 mt-3 stretched-link text-truncate">{{ menu.meta.title }}</a>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="w-125px h-125px d-flex flex-column align-items-center justify-content-center bg-white position-relative">
              <b-badge class="badge-sm badge-pill bg-danger text-white position-absolute top-0 end-0 mt-n2 me-n4">PRO</b-badge>
              <img :src="`/assets/img/dashboard-card/custom.svg`" />
              <a href="#" @click.prevent="" class="text-gray-700 mt-3 stretched-link">自定义看板</a>
            </div>
          </b-col>
        </b-row>
<!--        <ul class="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-semibold fs-6 p-10" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link text-active-primary py-6 active" data-bs-toggle="tab" href="#dashboard_tab_1" aria-selected="true" role="tab">标准看板</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link py-6" :class="{ disabled: hasCustomDashboardList }" data-bs-toggle="tab" :href="hasCustomDashboardList ? '#dashboard_tab_2' : '#'" aria-selected="false" role="tab" tabindex="-1"><pro disabled>定制看板</pro></a>
          </li>
        </ul>-->
      </div>
<!--      <div class="tab-content">
        <div class="tab-pane fade active show" id="dashboard_tab_1" role="tabpanel">
          <div class="scroll-y mh-325px my-5 px-8">
            <b-row>
              <b-col cols="8" class="p-6">
                <b-row>
                  <b-col v-for="(menu, index) in dashboardList" :key="`dashboard-${index}`" cols="6" class="mb-3">
                    <div class="menu-item p-0 m-0">
                      <a href="#" @click.prevent="openDashboard(menu)" class="menu-link bg-hover-light-primary rounded rounded-1">
                        <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                          <icon name="element-11" size="1" class="text-primary" paths="5" />
                        </span>
                        <span class="d-flex flex-column">
                                    <span class="fs-6 fw-bold text-gray-800">{{ menu.meta.title }}</span>
&lt;!&ndash;                                    <span class="fs-7 fw-semibold text-muted">Reports &amp; statistics</span>&ndash;&gt;
                        </span>
                      </a>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="4">
&lt;!&ndash;                <div class="rounded rounded-1 w-100 h-275px bgi-no-repeat bgi-size-cover" :style="{ backgroundImage: 'url(/assets/img/dashboard-card/img-13.jpg)' }"></div>&ndash;&gt;
                <div class="w-100 h-275px bg-light-primary rounded rounded-1 d-flex justify-content-center align-items-center">
                  <icon name="abstract-45" paths="2" size="6x" class="text-primary" />
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="tab-pane fade" id="dashboard_tab_2" role="tabpanel">
          <div class="scroll-y h-300px my-5 px-8">
            <b-row>
              <b-col cols="8" class="p-6">
                <b-row>
                  <b-col v-for="(menu, index) in customDashboardList" :key="`dashboard-${index}`" cols="6" class="mb-3">
                    <div class="menu-item p-0 m-0">
                      <a href="#" @click.prevent="openDashboard(menu)" class="menu-link bg-hover-light-primary rounded rounded-1">
                        <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                          <icon name="element-11" size="1" class="text-primary" paths="5" />
                        </span>
                        <span class="d-flex flex-column">
                                    <span class="fs-6 fw-bold text-gray-800">{{ menu.meta.title }}</span>
                          &lt;!&ndash;                                    <span class="fs-7 fw-semibold text-muted">Reports &amp; statistics</span>&ndash;&gt;
                        </span>
                      </a>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="4">
&lt;!&ndash;                <div class="rounded rounded-1 w-100 h-275px bgi-no-repeat bgi-size-cover" :style="{ backgroundImage: 'url(/assets/img/dashboard-card/img-3.jpg)' }"></div>&ndash;&gt;
                <div class="w-100 h-275px bg-light-success rounded rounded-1 d-flex justify-content-center align-items-center">
                  <icon name="abstract-47" paths="2" size="6x" class="text-success" />
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>-->
    </template>
  </action-item>

  <dashboard-modal />

</template>

<script>
import { Component, Vue } from '@min/vue-compatible-decorator'
import ActionItem from '@/components/layout/header-components/action-item'
// #ifdef HAS_SUITE_DASHBOARD
import DashboardModal from '@/modular/dashboard/layout/modal'
// #endif
import AccountService from '@/services/account'
import { useTenantStore } from '@/stores/tenant'
import Event from '@/utils/event'

@Component({
  components: {
    // #ifdef HAS_SUITE_DASHBOARD
    DashboardModal,
    // #endif
    ActionItem
  }
})
class DashboardCard extends Vue {
  dashboardList = []
  customDashboardList = []
  hasCustomDashboardList = true

  async initDashboard (tenant) {
    const dashboardList = await AccountService.getDashboardList()
    if (dashboardList.has_dashboard) {
      this.dashboardList = dashboardList.list

      if (dashboardList.custom_permission) {
        const menus = []
        // 请求sql配置与dashboard-app中的看板页
        // const dashboardMenus = await Promise.all([AccountService.getDashboardMenu(), AccountService.getExternalDashboardMenu()])
        // 只请求dashboard-app中的看板页
        const dashboardMenus = await Promise.all([AccountService.getExternalDashboardMenu(tenant)])
        if (dashboardMenus[0]) {
          for (const n in dashboardMenus[0]) {
            menus.push(dashboardMenus[0][n])
          }
        }
        if (dashboardMenus[1]) {
          for (const n in dashboardMenus[1]) {
            menus.push(dashboardMenus[1][n])
          }
        }
        this.customDashboardList = menus
      }
    }
  }

  openDashboard (menu) {
    Event.emit('DASHBOARD.OPEN', 'default', menu)
  }

  mounted () {
    useTenantStore().initialized.run(
      async () => { await this.initDashboard(useTenantStore().data.id) },
      'DASHBOARD_CARD_MOUNT'
    )
  }
}

export default DashboardCard
</script>
