<template>
  <div class="content flex-column-fluid p-0">
    <chart-area :configs="parsedConfigs"></chart-area>
  </div>
</template>

<script>
import { Component, Prop, Vue } from '@min/vue-compatible-decorator'
import ZChart from '@/components/dashboard/z-chart'
// import ZEcharts from '@/components/dashboard/z-echarts'
import DashboardService from '@/services/dashboard'
import ChartArea from '@/components/dashboard/chart-area'

const DEFAULTS = {
  COLS: 4
}

export default
@Component({
  components: { ZChart, ChartArea }
})
class Dashboard extends Vue {
  @Prop({ type: Number }) dashboardId

  parsedConfigs = {}

  configs = []

  async initConfig () {
    this.parsedConfigs = await this.parseConfig(this.configs)
  }

  async parseConfig (config) {
    if (config instanceof Array) {
      for (const n in config) {
        config[n] = await this.parseConfig(config[n])
      }
      config = {
        items: config
      }
    } else {
      config.type = config.type || 'category'
      config.cols = config.cols || DEFAULTS.COLS

      if (config.data && config.data instanceof Promise) {
        try {
          config.data = await config.data
        } catch (e) {
          config.data = []
        }
      } else if (!['object', 'array'].includes(typeof config.data)) {
        try {
          config.data = await DashboardService(config.data)
        } catch (e) {
          config.data = []
        }
      } else {
        config.data = await this.initData(config.data || [], config)
      }

      if (!config.options) {
        config.options = {
          xAxis: { type: config.type },
          yAxis: {}
        }
      } else {
        config.options.xAxis = config.options.xAxis || { type: config.type }
        config.options.yAxis = config.options.yAxis || {}
      }
      if (config.series) {
        for (const n in config.series) {
          if (config.series[n].type === 'bar') {
            config.series[n].barWidth = config.series[n].barWidth || 16
            if (config.series[n].itemStyle?.normal?.barBorderRadius === undefined) {
              config.series[n].itemStyle = {
                normal: {
                  barBorderRadius: [6, 6, 0, 0]
                }
              }
            }
          }
        }
      }
    }

    return config
  }

  async initData (dataConfig, itemConfig) {
    if (dataConfig.config) {
      let data = []

      switch (itemConfig.type) {
        case 'widget':
          if (dataConfig.config.source) {
            if (dataConfig.config.columns) {
              data.push(Object.keys(dataConfig.config.columns))
            }
            switch (dataConfig.config.source.type) {
              case 'SQL':
                if (dataConfig.config.source.id) {
                  const response = await DashboardService.getData(dataConfig.config.source.id)

                  const columns = Object.values(dataConfig.config.columns)
                  for (const n in response) {
                    const row = []
                    for (const k in columns) {
                      row[k] = columns[k].replace(/#\{(.+?)\}/g, function (match, group) { return response[n][group] || '' })
                    }
                    data.push(row)
                  }
                }
                break
              default:
            }
          }
          break
        case 'table':
          if (dataConfig.config.source) {
            if (dataConfig.config.source.id) {
              data = await DashboardService.getData(dataConfig.config.source.id)
            }
          }
          break
        case 'device':
          if (dataConfig.config.source) {
            if (dataConfig.config.source.id) {
              data = await DashboardService.getData(dataConfig.config.source.id)
            }
          }
          break
        case 'category':
        default:
          if (dataConfig.config.source) {
            if (dataConfig.config.columns) {
              data.push(Object.keys(dataConfig.config.columns))
            }
            switch (dataConfig.config.source.type) {
              case 'SQL':
                if (dataConfig.config.source.id) {
                  const response = await DashboardService.getData(dataConfig.config.source.id)

                  const columns = Object.values(dataConfig.config.columns)
                  for (const n in response) {
                    const row = []
                    for (const k in columns) {
                      row[k] = columns[k].replace(/#\{(.+?)\}/g, function (match, group) { return response[n][group] || '' })
                    }
                    data.push(row)
                  }
                }
                break
              default:
            }
          }
      }
      return data
    } else {
      return dataConfig
    }
  }

  async mounted () {
    this.configs = await DashboardService.getConfig(this.dashboardId)
    await this.initConfig()
  }
}
</script>
