<template>
  <div class="zero-form card bg-white full-height-content-sticky-footer" data-kt-app-footer-sticky-bottom="on">
    <div class="card-header border-0 bg-white">
      <h3 class="card-title fs-4 text-gray-800">
        <span id="title">{{ computedTitle }}</span>
      </h3>
      <div v-if="$slots['action-buttons']" class="card-toolbar">
        <slot name="action-buttons" />
      </div>
    </div>
    <div class="card-body flex-column p-0">
      <template v-for="(blockConfig, index) in config">
        <div class="mb-5 card-section py-0" v-if="!$_HIDDEN_STATUS[index]" :key="`form-block-${blockConfig.key}`">
          <slot :name="`title(${blockConfig.key})`" :form="blockConfig" :config="blockConfig" :data="data">
            <template v-if="blockConfig.title && blockConfig.title !== ''">
              <div :id="`form-title-${blockConfig.key ?? index}`" class="card-header d-flex border-0 ps-0 pt-2 min-h-40px h-40px">
                <h3 class="card-title text-gray-800 fs-5 m-0 me-2"><span class="bullet bullet-vertical rounded-0 bg-primary h-15px me-5"></span>{{ blockConfig.title }}</h3>
              </div>
            </template>
          </slot>
          <div class="card-body p-4">
            <zero-form-block-static
              :data="data"
              :config="blockConfig"
            >
              <template v-for="(_, name) in $slots" #[name]="slotData">
                <slot :name="name" v-bind="slotData" />
              </template>
            </zero-form-block-static>
          </div>
        </div>
      </template>
    </div>
    <div v-if="showButton" class="form-footer bg-white d-flex align-items-center justify-content-end">
      <slot name="actions" :cancel="goback">
        <b-row>
          <b-col cols="12" class="d-flex justify-content-end px-6">
            <button type="button" class="btn btn-lg btn-dark w-100px me-4" @click="goback">
              返回
            </button>
          </b-col>
        </b-row>
      </slot>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, defineProps, nextTick, watch, defineEmits } from 'vue'
import { useBreadCrumbStore } from '@/stores/bread-crumb'
import { display } from '../utils/common'

const emit = defineEmits(['cancel'])
const props = defineProps({
  title: { type: String, default: '' },
  // form配置（主要为页面内表单的类型等信息）
  config: { type: [Array, Object], default: () => {} },
  // 是否显示底部按钮区域
  showButton: { type: Boolean, default: true },
  onCancel: {},
  data: {}
})

const breadCrumb = ref([])
const titleBlock = ref({})
const $_HIDDEN_STATUS = ref({})
const $_KEY_LIST = ref([])

const computedTitle = computed(() => props.title && props.title !== '' ? props.title : (titleBlock.value?.meta?.title || ''))
/* const hasBackRoute = computed(() => {
  if (!currentMenu.value) {
    return false
  }
  if (currentMenu.value?.meta?.show !== true) {
    return true
  }
  return currentMenu.value?.backRouter && currentMenu.value?.backRouter !== ''
}) */

watch(() => props.data, value => {
  parseDisplayStatus(value)
})

function parseDisplayStatus (value) {
  const status = []
  for (const n in $_KEY_LIST.value) {
    status[n] = !$_KEY_LIST.value[n].display(value ?? props.data)
  }
  $_HIDDEN_STATUS.value = status
}

onMounted(() => {
  const keyListRaw = []
  for (const key in props.config) {
    keyListRaw[key] = {
      display: display(props.config[key].display)
    }
  }

  $_KEY_LIST.value = keyListRaw

  // parseDisplayStatus(props.data)
  useBreadCrumbStore().initialized.run(() => {
    nextTick(() => {
      breadCrumb.value = useBreadCrumbStore().breadCrumb
      titleBlock.value = [].concat(breadCrumb.value).pop()
    })
  // }, 'ZERO_FORM_STATIC_INIT_TITLE')
  })
})

function goback () {
  emit('cancel')
}

/* function cancel () {
  formData.value = {}
  emit('cancel')
} */
</script>
