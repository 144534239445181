import Event from '@/utils/event'
import { getVm } from '@/utils/vm'
import { typeOf } from '@/components/zero-vue/utils/common'
import { useMenuStore } from '@/stores/menu'

const PAGE_TABS_EVENTS = {
  ADD: 'PAGE-TAB:ADD',
  ADDED: 'PAGE-TAB:ADDED',
  ACTIVE: 'PAGE-TAB:ACTIVE',
  OPEN: 'PAGE-TAB:OPEN',
  OPENED: 'PAGE-TAB:OPENED',
  CLOSE: 'PAGE-TAB:CLOSE',
  CLOSED: 'PAGE-TAB:CLOSED',
  SWITCH: 'PAGE-TAB:SWITCH',
  SWITCHED: 'PAGE-TAB:SWITCHED',
  RESTORE: 'PAGE-TAB:RESTORE',
  CLEAR: 'PAGE-TAB:CLEAR'
}

function getTabs () {
  let tabs = {}
  try {
    tabs = JSON.parse(window.sessionStorage.getItem('INTELLIMES_PAGE_TABS'))
  } catch (e) {}
  return tabs || {}
}

function activeTab (menu, open = true) {
  Event.emit(PAGE_TABS_EVENTS.ACTIVE, 'default', menu, open)
}

function closeTab (menu) {
  Event.emit(PAGE_TABS_EVENTS.CLOSE, 'default', menu)
}

function clear () {
  Event.emit(PAGE_TABS_EVENTS.CLEAR, 'default')
}

function cancel (route) {
  let to = {
    name: '',
    path: ''
  }
  if (route) {
    if (typeOf(route) === 'object') {
      to = route
    } else {
      // route = route.replace(/_/g, '-')
      let menu = {}
      if (route.indexOf('/') > -1) {
        menu = useMenuStore().getMenu(route)
      } else {
        menu = useMenuStore().getMenuByName(route)
      }
      to.name = menu.name.replace(/_/g, '-') ?? ''
      to.path = menu.path ?? ''
    }
  } else {
    const pathArr = getVm().$route.path.split('/')
    const nameArr = getVm().$route.name.split('-')
    if (pathArr.includes('change') || pathArr.includes('detail')) {
      pathArr.pop()
    }
    // 将pathArr最后一个值替换为list
    pathArr[pathArr.length - 1] = 'list'
    nameArr[nameArr.length - 1] = 'list'
    to = { name: nameArr.join('-'), path: pathArr.join('/') }
    // getVm().$router.push(pathArr.join('/'))
  }
  Event.emit(PAGE_TABS_EVENTS.CLOSE, 'default', { name: getVm().$route.name }, to)
  return to
}

function restore ($router) {
  const pageTabs = JSON.parse(window.sessionStorage.getItem('INTELLIMES_PAGE_TABS'))
  if (pageTabs.showing && pageTabs.showing.length > 0) {
    for (const n in pageTabs.showing) {
      if (pageTabs.showing[n].active) {
        $router.push(pageTabs.showing[n])
        return
      }
    }
  } else {
    $router.push('/')
  }
}

export { PAGE_TABS_EVENTS, activeTab, closeTab, restore, cancel, clear, getTabs }
