/*
 * @Author: xuzhitao
 * @Date: 2023-01-04 16:48:06
 * @LastEditors: xuzhitao
 * @LastEditTime: 2023-01-04 18:06:00
 * @FilePath: /mirrors.in-mes.com-ui/src/utils/axios-config.ts
 */
import { configDefaults } from '@min/axios'
const setAxiosConfig = vue => {
  configDefaults(defaults => {
    defaults.prefix = '/api'
    defaults.fallback = (error, resolve, reject) => {
      if (error?.status === 401 || error?.response?.status === 401) {
        if (vue.$route.name !== 'Login' && vue.$route.name !== 'ConsoleLogin') {
          vue.$router.push('/account/login')
          resolve(true)
        } else {
          reject(error)
        }
      } else if (error?.status === 403 || error?.response?.status === 403) {
        console.error(error)
        resolve({})
      } else {
        reject(error)
      }
    }
    return defaults
  })
}

export { setAxiosConfig }
