<template>
  <div :class="computedClass">
    <div v-if="haveHeader" class="card-header border-0 pt-5">
      <h3 v-if="title" class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder">{{ title }}</span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">{{ summary }}</span>
      </h3>
      <div v-if="showToolbar" class="card-toolbar">
        <div class="dropdown dropdown-inline">
          <a href="#" class="btn btn-clean btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="ki ki-bold-more-hor"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-md dropdown-menu-right">
            <!--begin::Navigation-->
            <ul class="navi navi-hover py-5">
              <slot name="toolbar"></slot>
            </ul>
            <!--end::Navigation-->
          </div>
        </div>
      </div>
    </div>
    <div class="card-body row m-0 p-0 position-relative overflow-hidden">
      <slot />
      <div v-if="showSpacer">
        <slot name="spacer" />
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from '@min/vue-compatible-decorator'

export default
@Component()
class ZCard extends Vue {
  @Prop({ type: Boolean, default: true }) haveHeader
  @Prop({ type: String }) variant
  @Prop({ type: String }) hoverVariant
  @Prop({ type: Boolean, default: false }) stretch
  @Prop({ type: Boolean, default: false }) showToolbar
  @Prop({ type: Boolean, default: false }) showSpacer
  @Prop({ type: String }) bgIcon
  @Prop({ type: String, default: 'right top' }) bgIconPosition
  @Prop({ type: String }) title
  @Prop({ type: String, default: 'left' }) titleAlign
  @Prop({ type: String }) titleVariant
  @Prop({ type: String }) titleHoverVariant
  @Prop({ type: String }) summary

  get computedTitleClass () {
    const classObject = ['font-weight-bolder font-size-h5']

    if (this.variant) {
      classObject.push('text-white')
    } else if (this.titleVariant) {
      classObject.push(`text-${this.titleVariant}`)
    } else {
      classObject.push('text-dark-75')
    }

    return classObject
  }

  get computedClass () {
    const classObject = ['card card-custom']

    if (this.stretch) {
      classObject.push('card-stretch')
    }

    if (this.$attrs.class) {
      classObject.push(this.$attrs.class)
    }

    if (this.variant) {
      classObject.push(`bg-${this.variant}`)
    }

    if (this.hoverVariant) {
      classObject.push(`bg-${this.hoverVariant}`)
    }

    if (this.bgIcon && this.bgIcon !== '') {
      classObject.push(`background-position: ${this.bgIconPosition}`)
      classObject.push('background-size: 30% auto')
      classObject.push('background-repeat: no-repeat')
      if (this.bgIcon.indexOf('/') !== -1) {
        classObject.push(`background-image: url(${this.bgIcon})`)
      } else {
        classObject.push(`background-image: url(@assets/img/icon/svg/${this.name.replace(/\./g, '/')}.svg)`)
      }
    }

    return classObject
  }
}
</script>
