<template>
  <p v-if="props.static" class="form-control-plaintext text-gray-800">{{ modelValue }}</p>
  <b-form-textarea :class="[props.class, invalid ? 'is-invalid' : '']" v-else v-bind="$attrs" class="form-control" v-model="modelValue" />
</template>

<script setup>
import { defineProps, defineModel, ref, defineExpose } from 'vue'
import { isEmpty } from '../../utils/common'

const props = defineProps({
  required: { type: Boolean, default: false },
  static: { type: Boolean, default: false },
  label: { type: String, default: '' },
  for: { type: String, default: '' }
})

const modelValue = defineModel()
const invalid = ref(false)

/**
 *
 * @returns { Object } data
 * @returns { number } data.status 0: 正常, 1: error, 2: warning
 * @returns { string } data.message
 * @returns { any } data.data
 */
function validate () {
  if (props.required && isEmpty(modelValue.value)) {
    invalid.value = true
    return { status: 1, message: `${props.label ?? props.for ?? '此项'}必填` }
  } else {
    invalid.value = false
    return { status: 0, message: '' }
  }
}

defineExpose({ validate })
</script>
