/*
 * @Author: xuzhitao
 * @Date: 2023-01-04 09:26:17
 * @LastEditors: xuzhitao
 * @LastEditTime: 2023-01-30 15:19:59
 * @FilePath: /intellidevice-web-app/src/stores/profile.ts
 */
import { defineStore } from 'pinia'
import { usePermissionStore } from './permissions'
import { useTenantStore } from './tenant'
import { useMenuStore } from './menu'
import AccountService from '@/services/account'
import { initCondition } from '@/utils/condition'

export const useProfileStore = defineStore({
  id: 'profile',
  state: () => ({
    store: {},
    initialized: initCondition('profile')
  }),
  getters: {
    data () {
      return this.store
    },
    isInitialized () {
      return this.initialized.status
    },
    isSuperAdmin () {
      return Number(this.store.admin_type) === 1
    }
  },
  actions: {
    async init () {
      const profile = await AccountService.profile()
      if (profile?.tenant_id !== 0) {
        useTenantStore().init(profile.tenant)
      }
      usePermissionStore().init(profile.permission_codes)
      useMenuStore().init(profile.menus)
      delete profile.menus
      delete profile.permission_codes
      delete profile.tenant
      this.store = profile
      this.initialized.set()
    }
  }
})
