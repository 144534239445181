export default [
  {
    path: '/material/inventory/in-invoice/list',
    name: 'material-inventory-in-invoice-list',
    component: () => import('../pages/inventory/in-invoice/list')
  },
  {
    path: '/material/inventory/in-invoice/add',
    name: 'material-inventory-in-invoice-add',
    component: () => import('../pages/inventory/in-invoice/add')
  },
  {
    path: '/material/inventory/in-invoice/change/:id',
    name: 'material-inventory-in-invoice-change',
    component: () => import('../pages/inventory/in-invoice/change')
  },
  {
    path: '/material/inventory/in-invoice/detail/:id',
    name: 'material-inventory-in-invoice-detail',
    component: () => import('../pages/inventory/in-invoice/detail')
  },
  {
    path: '/material/inventory/out-invoice/list',
    name: 'material-inventory-out-invoice-list',
    component: () => import('../pages/inventory/out-invoice/list')
  },
  {
    path: '/material/inventory/out-invoice/add',
    name: 'material-inventory-out-invoice-add',
    component: () => import('../pages/inventory/out-invoice/add')
  },
  {
    path: '/material/inventory/out-invoice/change/:id',
    name: 'material-inventory-out-invoice-change',
    component: () => import('../pages/inventory/out-invoice/change')
  },
  {
    path: '/material/inventory/out-invoice/detail/:id',
    name: 'material-inventory-out-invoice-detail',
    component: () => import('../pages/inventory/out-invoice/detail')
  },
  {
    path: '/material/inventory/else-invoice/list',
    name: 'material-inventory-else-invoice-list',
    component: () => import('../pages/inventory/else-invoice/list')
  },
  {
    path: '/material/inventory/else-invoice/add',
    name: 'material-inventory-else-invoice-add',
    component: () => import('../pages/inventory/else-invoice/add')
  },
  {
    path: '/material/inventory/else-invoice/change/:id',
    name: 'material-inventory-else-invoice-change',
    component: () => import('../pages/inventory/else-invoice/change')
  },
  {
    path: '/material/inventory/else-invoice/detail/:id',
    name: 'material-inventory-else-invoice-detail',
    component: () => import('../pages/inventory/else-invoice/detail')
  },
  {
    path: '/material/inventory/material-transfer/list',
    name: 'material-inventory-material-transfer-list',
    component: () => import('../pages/inventory/material-transfer/list')
  },
  {
    path: '/material/inventory/material-transfer/add',
    name: 'material-inventory-material-transfer-add',
    component: () => import('../pages/inventory/material-transfer/add')
  },
  {
    path: '/material/inventory/material-transfer/merge',
    name: 'material-inventory-material-transfer-merge',
    component: () => import('../pages/inventory/material-transfer/merge')
  },
  {
    path: '/material/inventory/material-transfer/change/:id',
    name: 'material-inventory-material-transfer-change',
    component: () => import('../pages/inventory/material-transfer/change')
  },
  {
    path: '/material/inventory/material-transfer/detail/:id',
    name: 'material-inventory-material-transfer-detail',
    component: () => import('../pages/inventory/material-transfer/detail')
  },
  {
    path: '/material/inventory/material-transfer/transfer/:id',
    name: 'material-inventory-material-transfer-transfer',
    component: () => import('../pages/inventory/material-transfer/transfer')
  },
  {
    path: '/material/inventory/material-preparation/add',
    name: 'material-inventory-material-preparation-add',
    component: () => import('../pages/inventory/material-preparation/add')
  },
  {
    path: '/material/inventory/material-preparation/list',
    name: 'material-inventory-material-preparation-list',
    component: () => import('../pages/inventory/material-preparation/list')
  },
  {
    path: '/material/inventory/material-preparation/change/:id',
    name: 'material-inventory-material-preparation-change',
    component: () => import('../pages/inventory/material-preparation/change')
  },
  {
    path: '/material/inventory/material-preparation/detail/:id',
    name: 'material-inventory-material-preparation-detail',
    component: () => import('../pages/inventory/material-preparation/detail')
  },
  {
    path: '/material/inventory/alteration/list',
    name: 'material-inventory-alteration-list',
    component: () => import('../pages/inventory/alteration/list')
  },
  {
    path: '/material/inventory/alteration/add',
    name: 'material-inventory-alteration-add',
    component: () => import('../pages/inventory/alteration/add')
  },
  {
    path: '/material/inventory/alteration/detail/:id',
    name: 'material-inventory-alteration-detail',
    component: () => import('../pages/inventory/alteration/detail')
  },
  {
    path: '/material/query/enter-factory',
    name: 'material-query-enter-factory',
    component: () => import('../pages/query/enter-factory')
  },
  {
    path: '/material/query/leave-factory',
    name: 'material-query-leave-factory',
    component: () => import('../pages/query/leave-factory')
  },
  {
    path: '/material/query/material-return',
    name: 'material-query-material-return',
    component: () => import('../pages/query/material-return')
  },
  {
    path: '/material/query/transfer',
    name: 'material-query-transfer',
    component: () => import('../pages/query/transfer')
  },
  {
    path: '/material/query/check',
    name: 'material-query-check',
    component: () => import('../pages/query/check')
  },
  {
    path: '/material/query/goods-return',
    name: 'material-query-goods-return',
    component: () => import('../pages/query/goods-return')
  },
  {
    path: '/material/query/transaction/list',
    name: 'material-query-transaction-list',
    component: () => import('../pages/query/transaction/list')
  },
  {
    path: '/material/query/transaction/detail',
    name: 'material-query-transaction-detail',
    component: () => import('../pages/query/transaction/detail')
  },
  {
    path: '/material/exist/stock/list',
    name: 'material-exist-stock-list',
    component: () => import('../pages/exist/stock/list')
  },
  {
    path: '/material/exist/stock/adjustment/:id',
    name: 'material-exist-stock-adjustment',
    component: () => import('../pages/exist/stock/adjustment')
  },
  {
    path: '/material/exist/batch/list',
    name: 'material-exist-batch-list',
    component: () => import('../pages/exist/batch/list')
  },
  {
    path: '/material/exist/code/list',
    name: 'material-exist-code-list',
    component: () => import('../pages/exist/code/list')
  },
  {
    path: '/material/exist/code/adjustment/:id',
    name: 'material-exist-code-adjustment',
    component: () => import('../pages/exist/code/adjustment')
  },
  {
    path: '/material/query/lend/list',
    name: 'material-query-lend-list',
    component: () => import('@/modular/base/material/pages/query/lend/list')
  },
  {
    path: '/material/query/lend/detail/:id',
    name: 'material-query-lend-detail',
    component: () => import('@/modular/base/material/pages/query/lend/detail.vue')
  },
  {
    path: '/material/query/restructuring/list',
    name: 'material-query-restructuring-list',
    component: () => import('@/modular/base/material/pages/query/restructuring/list')
  },
  {
    path: '/material/query/restructuring/detail/:id',
    name: 'material-query-restructuring-detail',
    component: () => import('@/modular/base/material/pages/query/restructuring/detail.vue')
  },
  {
    path: '/material/report/inbound/list',
    name: 'material-report-inbound-list',
    component: () => import('@/modular/base/material/pages/report/inbound/list')
  },
  {
    path: '/material/report/outbound/list',
    name: 'material-report-outbound-list',
    component: () => import('@/modular/base/material/pages/report/outbound/list')
  },
  {
    path: '/material/report/age-warning/list',
    name: 'material-report-age-warning-list',
    component: () => import('@/modular/base/material/pages/report/age-warning/list')
  },
  {
    path: '/material/report/number-warning/list',
    name: 'material-report-number-warning-list',
    component: () => import('@/modular/base/material/pages/report/number-warning/list')
  },
  {
    path: '/material/management/freeze/list',
    name: 'material-management-freeze-list',
    component: () => import('../pages/management/freeze/list')
  },
  {
    path: '/material/management/freeze/add',
    name: 'material-management-freeze-add',
    component: () => import('../pages/management/freeze/add')
  },
  {
    path: '/material/management/freeze/detail/:id',
    name: 'material-management-freeze-detail',
    component: () => import('../pages/management/freeze/detail')
  }
]
