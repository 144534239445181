<template>
  <form class="d-flex position-relative px-4">
    <div class="flex-grow-1 ps-2 pe-4">
      <b-row class="pb-4" :label-length="labelLength = []">
        <template v-for="(shortFilter, i) in filterOptions">
          <b-col v-if="i < columns" :key="i" :cols="parseInt(12 / columns)" class="row m-0 px-2" :label-length="labelLength[i] = strLen(shortFilter.title)">
            <label :for="`${shortFilter.key}-filter`" class="col-form-label col-4 col-xxl-3 fw-normal text-gray-700 text-nowrap text-truncate">
              <span v-if="labelLength[i] > 8" v-b-tooltip.hover.bottom :title="shortFilter.title">{{ shortFilter.title }}</span>
              <span v-else>{{ shortFilter.title }}</span>
            </label>
            <div class="col-8 col-xxl-9 px-0">
              <slot :name="`${shortFilter.key}-filter`" :data="filterItems" :configs="shortFilter.configs">
                <component :is="shortFilter.type" v-bind="shortFilter.configs" :placeholder="labelLength[i] > 8 ? shortFilter.title : ''" v-model="filterItems[shortFilter.key]" />
              </slot>
            </div>
          </b-col>
        </template>
      </b-row>
      <b-row v-if="filterOptions.length > columns" v-show="!isCollapse" :label-length="labelLengthMore = []">
        <template v-for="(longFilter, i) in filterOptions">
          <b-col v-if="i >= columns" :key="i" class="row m-0 px-2 pb-4" :cols="parseInt(12 / columns)" :label-length="labelLengthMore[i] = strLen(longFilter.title)">
            <label :for="`${longFilter.key}-filter`" class="col-form-label col-4 col-xxl-3 fw-normal text-gray-800 pe-2 text-nowrap text-truncate">
              <span v-if="labelLengthMore[i] > 8" v-b-tooltip.hover.bottom :title="longFilter.title">{{ longFilter.title }}</span>
              <span v-else>{{ longFilter.title }}</span>
            </label>
            <div class="col-8 col-xxl-9 px-0">
              <slot :name="longFilter.key + '-filter'" :data="filterItems" :configs="longFilter.configs">
                <component :is="longFilter.type" v-bind="longFilter.configs" :placeholder="labelLengthMore[i] > 8 ? longFilter.title : ''" v-model="filterItems[longFilter.key]" />
              </slot>
            </div>
          </b-col>
        </template>
      </b-row>
    </div>
    <div class="d-flex justify-content-between align-items-start">
      <b-button v-if="!noReset" class="w-80px ms-2" variant="dark" @click="refresh">重置</b-button>
      <b-button :disabled="isSearching" class="w-80px ms-2" variant="primary" @click="searchItems()">
        查询
      </b-button>
    </div>
    <b-badge v-if="filterOptions.length > columns" class="filter-button badge-collapse align-self-center" @click.prevent="toggleShowAllFilter">
      <icon type="ri" :name="isCollapse ? 'arrow-down-s' : 'arrow-up-s'" />
    </b-badge>
  </form>
</template>

<script setup>
import { onMounted, ref, watch, defineProps, defineEmits, defineModel, defineExpose } from 'vue'
import { hasAuth } from '../utils/auth'
// import { usePermissionStore } from '@/stores/permissions'
import { strLen } from '../utils/strings'

const INTERNAL_TYPE = {
  select: 'zero-select',
  'tree-select': 'zero-select',
  'b-select': 'zero-select',
  input: 'b-form-input',
  'b-input': 'b-form-input'
}

const props = defineProps({
  filter: Object,
  noReset: { type: Boolean, default: false },
  columns: { type: [Number, String], default: 4 },
  collapse: { type: Boolean, default: true },
  handleSearch: Function
})

const emits = defineEmits(['update:modelValue', 'update:collapse', 'search'])

// 定义搜索条件
const filterOptions = ref([])
// 定义搜索条件值
const filterItems = ref({})
const confirmFilterItems = defineModel({ default: {} })
// 定义是否搜索中
const isSearching = ref(false)
const isCollapse = ref(true)

onMounted(() => {
  initFilter()
})

function toggleShowAllFilter () {
  isCollapse.value = !isCollapse.value
}

async function initFilter () {
  confirmFilterItems.value = {}
  let list = []
  const filter = Object.assign({}, props.filter)
  for (const i in filter) {
    filterItems.value[i] = null
    if (filter[i].configs && 'options' in filter[i].configs && Object.prototype.toString.call(filter[i].configs.options) === '[object Promise]') {
      filter[i].configs.options = await filter[i].configs.options
    }
    if (!filter[i].type || filter[i].type === '') {
      filter[i].type = 'input'
    }
    if (INTERNAL_TYPE[filter[i].type]) {
      filter[i].type = INTERNAL_TYPE[filter[i].type]
    }
    filter[i].key = i
    list.push(filter[i])
  }
  list = list.filter((val) => {
    return hasAuth(val.auth)
  })
  filterOptions.value = [].concat(list)
}

function searchItems () {
  confirmFilterItems.value = JSON.parse(JSON.stringify(filterItems.value))
  emits('search', confirmFilterItems.value)
}

/**
 * @name refresh
 * @description 重置
 * @return void
 */
function refresh () {
  for (const i in filterItems.value) {
    filterItems.value[i] = null
  }
  confirmFilterItems.value = {}
  emits('search', confirmFilterItems.value)
}

watch(() => props.collapse, (value) => {
  isCollapse.value = value
}, { immediate: true })

watch(isCollapse, (value) => {
  emits('update:collapse', value)
}, { immediate: true })

defineExpose({ searchItems, refresh })
</script>
