<template>
  <div>
    <b-modal
      :hide-header="dashboard.open_mode === 'fullscreen'"
      :body-class="dashboard.open_mode === 'fullscreen' ? 'p-0 overflow-hidden' : ''"
      :modal-class="dashboard.open_mode === 'fullscreen' ? 'fullscreen-img' : ''"
      :content-class="dashboard.open_mode === 'fullscreen' ? 'h-100' : ''"
      id="modal-dashboard"
      v-model="dashboard.show"
      size="fullscreen"
      :title="dashboard.title" hide-footer
      @show="requestFullscreen(dashboard.open_mode)"
      @hide="exitFullscreen"
    >
      <iframe v-if="dashboard.type === 'external' && dashboard.show" class="vh-100 vw-100 border-0 overflow-hidden" scrolling="no" :src="dashboard.route" />
      <component v-else-if="dashboard.component !== '' && dashboard.show" :is="dashboard.component" :dashboard-id="dashboard.parameters.dashboardId" :parameters="dashboard.parameters || {}" />
    </b-modal>
  </div>
</template>

<script>
// 修饰器依赖
import { Component, Vue } from '@min/vue-compatible-decorator'
import Dashboard from './dashboard'
import DeviceOverviewDashboard from '../pages/device-overview'
import ProductionOverviewDashboard from '../pages/production-overview'
import StockOverviewDashboard from '../pages/stock-overview'
// import GlxclMiniOrderOverview from '../pages/glxcl-mini-order-overview'
import GlxclOrderOverview from '../pages/glxcl-order-overview'
import XxzgProductionOverview from '../pages/xxzg-production-overview'
import Event from '@/utils/event'

export default
@Component({
  components: { Dashboard, DeviceOverviewDashboard, StockOverviewDashboard, ProductionOverviewDashboard, GlxclOrderOverview, XxzgProductionOverview }
})
class DashboardModal extends Vue {
  dashboard = {
    show: false,
    component: '',
    title: '生产看板',
    parameters: {
      id: 0
    },
    open_mode: 'modal',
    route: '',
    type: 'internal'
  }

  requestFullscreen (openMode) {
    if (openMode === 'fullscreen') {
      const de = document.documentElement
      de.requestFullscreen && de.requestFullscreen()
      de.mozRequestFullScreen && de.mozRequestFullScreen()
      de.webkitRequestFullScreen && de.webkitRequestFullScreen()
    }
  }

  // 退出全屏
  exitFullscreen () {
    const isFull = document.mozFullScreen ||
      document.fullScreen ||
      // 谷歌浏览器及Webkit内核浏览器
      document.webkitIsFullScreen ||
      document.webkitRequestFullScreen ||
      document.mozRequestFullScreen ||
      document.msFullscreenEnabled
    if (isFull) {
      const d = document
      d.exitFullscreen && d.exitFullscreen()
      d.mozCancelFullScreen && d.mozCancelFullScreen()
      d.webkitCancelFullScreen && d.webkitCancelFullScreen()
    }
    console.log(123123)
    this.dashboard.show = false
  }

  created () {
    Event.on('DASHBOARD.OPEN', (name, menu, item) => {
      this.dashboard.show = true
      this.dashboard.type = menu.type || 'internal'
      this.dashboard.route = menu.route || ''
      this.dashboard.component = menu.component
      this.dashboard.title = menu.meta.title
      this.dashboard.parameters = menu.meta.parameters || {}
      this.dashboard.open_mode = menu.open_mode || 'modal'
    })
  }
}
</script>
