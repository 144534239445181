import { CustomOverwriteRoutes } from '@/modular/custom/routes'

function overwrite (rawRoutes) {
  const routes = []
  for (const n in rawRoutes) {
    const route = Object.assign({}, rawRoutes[n])
    for (const k in CustomOverwriteRoutes) {
      if (CustomOverwriteRoutes[k].name === rawRoutes[n].name) {
        route.component = CustomOverwriteRoutes[k].component
      }
    }
    if (rawRoutes[n].children && rawRoutes[n].children.length > 0) {
      route.children = overwrite(rawRoutes[n].children, CustomOverwriteRoutes)
    }
    routes.push(route)
  }
  return routes
}

export {
  overwrite
}
