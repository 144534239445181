import DatePicker from './date-picker.vue'
import ZeroInput from './zero-input.vue'
import ZeroTextarea from './zero-textarea.vue'
import ZeroRadioGroup from './zero-radio-group.vue'
import ZeroSelect from './zero-select.vue'
import ZeroNumberInput from './zero-number-input.vue'

export function useFormInput (vue) {
  vue.component('ZeroSelect', ZeroSelect)
  vue.component('ZeroNumberInput', ZeroNumberInput)
  vue.component('DatePicker', DatePicker)
  vue.component('ZeroInput', ZeroInput)
  vue.component('ZeroTextarea', ZeroTextarea)
  vue.component('ZeroRadioGroup', ZeroRadioGroup)
}
