<template>
  <vxe-table ref="tableInstance"
             :data="$_DISPLAY_VALUE"
             :max-height="500"
             :column-config="{ resizable: true }"
             border="inner"
             class="border-top border-gray-200"
  >
    <vxe-column type="seq" title="序号" width="64" fixed="left" align="center" :col-id="$_INDEX_KEY">
      <template v-if="$slots[$_INDEX_KEY] || $slots[`cell(${$_INDEX_KEY})`]" v-slot="slotParams">
        <slot :name="$_INDEX_KEY" :for="$_INDEX_KEY" :data="{ item: slotParams.row, value: slotParams.row[$_INDEX_KEY], index: slotParams.$rowIndex }" :form="{ key: $_INDEX_KEY}" />
      </template>
    </vxe-column>
    <template :key="`table-column-${column.key}`" v-for="column in config.fields">
      <vxe-column v-if="!['do', 'action', $_INDEX_KEY].includes(column.key)"
                  min-width="150"
                  show-overflow
                  :sortable="!!column.sort"
                  :formatter="parseFormatter(column)"
                  :fixed="column.fixed ?? undefined"
                  :title="column.label ?? column.key"
                  :field="column.key"
                  :col-id="column.key"
      >
        <template v-if="$slots[column.key] || $slots[`cell(${column.key})`]" v-slot="slotParams">
          <slot :name="column.key" :value="slotParams.row[column.key]" :data="{ item: slotParams.row, value: slotParams.row[column.key] }" :item="slotParams.row" :index="slotParams.$rowIndex" />
        </template>
      </vxe-column>
    </template>
  </vxe-table>
</template>

<script setup>
import { defineProps, onMounted, ref, watch } from 'vue'
import { isEmpty } from '../utils/common'

const props = defineProps({
  modelValue: { type: Array, default: () => [] },
  data: { type: [Array], default: () => [] },
  config: Object,
  edit: { type: Boolean, default: true },
  transformRule: {},
  backwardTransformRule: {},
  modalId: { type: String, default: 'table' },
  noButton: { type: Boolean, default: false }
})
const $_INDEX_KEY = ref('index')
const $_DISPLAY_VALUE = ref([])
const $_LIST = ref([])
const cellOptionWrapper = ref([])
const cellOptionWidth = ref(100)

watch(() => props.modelValue, value => {
  if (!isEmpty(value)) {
    $_LIST.value = value
    init()
  }
})

watch(() => props.data, value => {
  if (!isEmpty(value)) {
    $_LIST.value = value
    init()
  }
})

watch(cellOptionWrapper, value => {
  const minWidth = 100
  let width = 0
  for (const i in value) {
    const options = value[i].querySelectorAll('.cell-option')
    let currentWidth = 0
    for (const n in options) {
      if (options[n].clientWidth) {
        currentWidth += options[n].clientWidth + 12
      }
    }
    currentWidth += 32
    width = (currentWidth > width ? currentWidth : width)
  }
  cellOptionWidth.value = (minWidth > width ? minWidth : width)
  // tableInstance.value.refreshColumn()
}, { deep: true, once: true })

function parseFormatter (field) {
  return ({ cellValue, row, column }) => {
    return translateValue(cellValue, row, column, field)
  }
}

function translateValue (data, row, column, field) {
  let value = ''
  if (field.format) {
    value = row[field.key] !== undefined ? field.format(row[field.key], row) : undefined
  } else {
    value = row[field.key]
  }
  return value ? value.toString() : '-'
}

function init () {
  const data = [...$_LIST.value]
  for (const i in data) {
    data[i] = transformData(data[i])
  }
  $_DISPLAY_VALUE.value = data
}

function transformData (item, type = 'forward') {
  const rule = (type === 'forward' ? props.transformRule : props.backwardTransformRule)
  let display = {}

  if (rule) {
    switch (typeof rule) {
      case 'function':
        display = rule(item)
        break
      case 'object':
        for (const i in item) {
          if (item[i] && item[i] !== '') {
            if (typeof rule[i] === 'object') {
              display[i] = rule[i][item[i]]
            } else if (typeof rule[i] === 'function') {
              display[i] = rule[i](item[i])
            } else {
              display[i] = item[i]
            }
          } else {
            display[i] = '-'
          }
        }
        break
      default:
        return item
    }
  } else {
    return item
  }
  return display
}

onMounted(() => {
  init()
})
</script>
