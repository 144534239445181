<template>
  <div v-if="hasDashboard" class="dropup pb-16">
    <a href="#" class="nav-link btn btn-icon btn-clean btn-hover-transparent-white px-3 btn-lg w-auto d-flex align-items-center dropdown-toggle" data-offset="180, 10" data-toggle="dropdown" data-placement="right">
      <icon-svg size="lg" name="Media.Equalizer" />
      <span class="ms-2">工厂看板</span>
    </a>
    <div class="dropdown-menu dropdown-menu-lg" style="">
      <ul class="navi navi-hover">
        <li class="navi-header font-weight-bold py-5">工厂看板
          <i class="flaticon2-information" data-placement="right" title="工厂生产、质量、库存等看板信息"></i>
        </li>
        <li class="navi-separator mb-4"></li>
        <li :key="`dashboard-menu-${menu.type}-${menu.id}`" v-for="menu in dashboardMenus" class="navi-item">
          <a class="navi-link" href="#" @click.prevent="openDashboard(menu)">
                <span class="navi-icon">
                  <i class="flaticon2-analytics"></i>
                </span>
            <div class="navi-text">
              {{ menu.meta.title }}
            </div>
          </a>
        </li>
        <li class="navi-separator mt-4"></li>
      </ul>
      <!--          <div class="d-flex flex-center p-4 pb-0"><button type="button" class="btn font-weight-bold btn-light-primary btn-sm btn-block">查看全部</button></div>-->
    </div>
  </div>
</template>

<script>
// 修饰器依赖
import { Component, Vue } from '@min/vue-compatible-decorator'
import Event from '@/utils/event'
import AccountService from '@/services/account'

export default
@Component()
class DashboardAsideMenu extends Vue {
  hasDashboard = false
  dashboardMenus = []

  openDashboard (menu) {
    Event.emit('dashboard.open', 'default', menu)
  }

  async created () {
    const dashboardList = await AccountService.getDashboardList()
    this.hasDashboard = dashboardList.has_dashboard || false
    const menus = dashboardList.list

    if (dashboardList.custom_permission) {
      const dashboardMenus = await Promise.all([AccountService.getDashboardMenu(), AccountService.getExternalDashboardMenu()])
      if (dashboardMenus[0]) {
        for (const n in dashboardMenus[0]) {
          menus.push(dashboardMenus[0][n])
        }
      }
      if (dashboardMenus[1]) {
        for (const n in dashboardMenus[1]) {
          menus.push(dashboardMenus[1][n])
        }
      }
    }
    this.dashboardMenus = menus
  }
}
</script>
