<template>
  <b-col :cols="config.cols || undefined">
    <template v-if="config.type && config.type === 'widget'">
      <z-card :title="config.title || ''" :have-header="config.haveHeader">
        <div :class="[`col-${ config.options.cols }`]" v-for="(s, i) in config.data[0]" :key="i">
          <z-widget  :title="s" :value="config.data[1][i]">
          </z-widget>
        </div>
      </z-card>
    </template>
    <template v-if="config.type && config.type === 'device'">
      <z-card :title="config.title || ''">
        <z-device-card :config="config" :data="config.data"></z-device-card>
      </z-card>
    </template>
    <template v-if="config.type && config.type === 'category'">
      <z-echarts autoresize v-bind="config" scale="2"></z-echarts>
    </template>
    <template v-if="config.type && config.type === 'table'">
      <z-card :title="config.title || ''">
        <z-table v-bind="config.options" :data="config.data"></z-table>
      </z-card>
    </template>
  </b-col>
</template>

<script>
import { Component, Prop, Vue } from '@min/vue-compatible-decorator'
import ZWidget from './z-widget'
import ZCard from './z-card'
import ZChart from './z-chart'
import ZEcharts from './z-echarts'
import ZTable from './z-table'
import ZDeviceCard from './z-device-card'

export default
@Component({
  name: 'chart-item',
  components: { ZChart, ZEcharts, ZTable, ZCard, ZWidget, ZDeviceCard }
})
class ChartItem extends Vue {
  @Prop({ type: Object, required: true, default: () => {} }) config
}
</script>
