<template>
  <!--  <div class="app-blank bgi-size-cover bgi-attachment-fixed bgi-position-center bgi-no-repeat">
    </div>-->
  <b-row class="login login-3 d-flex justify-content-center align-content-start">
    <div class="logo"><img class="w-150px" src="/assets/img/logo-brand-blue.svg" /></div>
    <div class="login-wrapper">
      <b-col cols="5" offset="7">
        <b-row class="login-container d-flex justify-content-end">
          <div class="login-area">
            <form class="form fv-plugins-bootstrap fv-plugins-framework" novalidate>
              <div class="py-15">
                <span class="fw-normal text-muted fs-3">数字汇聚, 赋能智造</span>
                <h3 class="fw-normal text-dark-50 fs-3 mt-3">正在使用超级管理员账号, 请谨慎登录</h3>
              </div>
              <div class="card">
                <div class="card-body p-0">
                  <div class="form-floating mx-6 my-4">
                    <p class="form-control form-control-plaintext border-0 text-muted">超级管理员</p>
                    <!--                    <input @keydown.enter="login" v-model="username" class="form-control border-0 form-control-plaintext" type="text" name="username" autocomplete="off" placeholder="用户名/邮箱/账号" tabindex="2">-->
                    <label class="fs-6 text-muted" for="username">用户名/邮箱/账号</label>
                  </div>
                  <div class="separator m-0" />
                  <div class="form-floating mx-6 my-4">
                    <input @keydown.enter="login" v-model="password" class="form-control border-0" type="password" name="password" autocomplete="off" placeholder="密码" tabindex="3">
                    <label class="fs-6 text-muted" for="password">密码</label>
                  </div>
                </div>
              </div>
              <b-row class="my-12">
                <b-col cols="7" class="d-grid gap-2">
                  <b-button class="shadow" variant="primary" size="lg" :disabled="isLoggingIn" @keydown.enter="login"  @click="login" tabindex="4"><span class="fs-4 fw-bolder">登录</span></b-button>
                </b-col>
              </b-row>
            </form>
          </div>
        </b-row>
      </b-col>
    </div>
    <div class="login-splash"></div>
  </b-row>
</template>

<script>
import { Component, Vue } from '@min/vue-compatible-decorator'
import AccountService from '@/services/account'
import notification from '@/utils/notification'
import Encrypt from '@/utils/encrypt'

@Component()
class ConsoleLogin extends Vue {
  username = 'superAdmin'
  password = ''
  isLoggingIn = false

  async login () {
    const waiting = notification.info('登录中', '登录中, 请稍后...')
    waiting.then(data => {
      this.isLoggingIn = false
    }).catch(() => {
      this.isLoggingIn = false
    })
    try {
      if (this.username === '' || this.password === '') {
        await notification.warning('登录失败', '账号、密码不能为空')
        return
      }
      this.isLoggingIn = true
      const encrypt = new Encrypt()
      const password = await encrypt.encode(this.password)
      await AccountService.login(this.username, password, '', encrypt.getKey())
      waiting.close()
      localStorage.setItem('admin_type', '1')
      this.isLoggingIn = false
      this.$router.push('/')
    } catch (e) {
      console.log(e)
      waiting.close()
      this.isLoggingIn = false
      await notification.warning('登录失败', e.data.message)
    }
  }
}

export default ConsoleLogin
</script>
