<template>
  <b-table :fields="fields" :items="data" v-bind="extraConfig" table-class="text-nowrap" :responsive="true" :sticky-header="true" thead-tr-class="text-muted text-center" tbody-class="text-center" style="max-height: none">
    <template v-for="field in fields" v-slot:[`cell(${field.key})`]="data">
      <slot :name="field.key" :data="data">
        <div v-if="!field.type" :key="'tag'">
          {{ data.value }}
        </div>
        <div v-if="field.type && field.type === 'tag'" :key="'tag'">
          <span class="label label-inline font-weight-bold" :class="[`label-light-${field.color[data.value]}`]">{{ data.value }}</span>
        </div>
        <div v-if="field.type && field.type === 'process'" :key="'tag'">
          <div class="d-flex flex-column w-100 me-2">
            <div class="d-flex align-items-center justify-content-between mb-1">
              <span class="text-muted me-2 font-size-sm font-weight-bold">{{ data.value }}</span>
              <span class="text-muted font-size-sm font-weight-bold">进度</span>
            </div>
            <div class="progress progress-xs w-100">
              <div class="progress-bar bg-info" role="progressbar" :style="`width: ${data.value}%;`" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
      </slot>
    </template>
  </b-table>
</template>

<script>
import { Component, Vue, Prop } from '@min/vue-compatible-decorator'

export default
@Component()
class ZTable extends Vue {
  @Prop() fields
  @Prop() data
  @Prop() extraConfig
}
</script>
