<template>
  <zero-select v-model="list" :options="options" :multiple="true" value-consists-of="LEAF_PRIORITY" :disabled="disabled"></zero-select>
</template>

<script setup>
import { onMounted, watch, ref, defineProps, defineEmits } from 'vue'
import UserService from '../../services/system/user'
import UserGroupService from '../../services/system/user-group'

const list = ref([])
const options = ref([
  { id: 'user', label: '用户' },
  { id: 'group', label: '用户组' }
])

const props = defineProps({
  userOptions: { type: Object },
  userGroupOptions: { type: Object },
  modelValue: { type: Array },
  disabled: { type: Boolean }
})

const emit = defineEmits(['update:modelValue'])

onMounted(async () => {
  let userOptions = []
  let userGroupOptions = []
  userOptions = props.userOptions ? (await props.userOptions).records : (await UserService.getList({ page: 1, size: 1000, status: 0 })).records
  userGroupOptions = props.userGroupOptions ? (await props.userGroupOptions).records : (await UserGroupService.getList({ page: 1, size: 1000, status: 0 })).records
  options.value = translateOptions(userOptions, userGroupOptions)
})

watch(list, onChangeList, { deep: true })
watch(() => props.modelValue, onChangeValue, { immediate: true })

function translateOptions (user, group) {
  const userList = []
  const groupList = []
  for (const i in user) {
    userList.push({ id: 'u' + user[i].id, label: user[i].name })
  }
  for (const i in group) {
    groupList.push({ id: 'g' + group[i].id, label: group[i].name })
  }
  return [
    { id: 'user', label: '用户', children: userList },
    { id: 'group', label: '用户组', children: groupList }
  ]
}

function onChangeList (newVal, oldVal) {
  if (newVal.length !== oldVal.length) {
    const selectList = newVal.concat([])
    const list = []
    if (selectList.length !== 0) {
      for (const i in selectList) {
        if (selectList[i].substr(0, 1) === 'u') {
          list.push({ user_category: 1, user_id: selectList[i].slice(1) })
        } else if (selectList[i].substr(0, 1) === 'g') {
          list.push({ user_category: 2, user_id: selectList[i].slice(1) })
        }
      }
      emit('update:modelValue', list)
    }
  }
}

function onChangeValue () {
  if (props.modelValue && props.modelValue.length !== 0) {
    const value = props.modelValue.concat([])
    const valueList = []
    for (const i in value) {
      if (value[i].user_category === 1) {
        valueList.push('u' + value[i].user_id)
      }
      if (value[i].user_category === 2) {
        valueList.push('g' + value[i].user_id)
      }
    }
    list.value = valueList
  }
}
</script>
