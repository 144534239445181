<template>
  <action-item id="kt_header_switch_theme_app_download" hover-width="40" no-action-expand action-thumb-class="d-flex" variant="color-state">
    <template #action-thumb>
      <a href="#" class="btn btn-icon btn-icon-light w-40px h-40px" @click.prevent="showLanding">
        <icon type="ri" name="download-cloud" mode="fill" size="6" variant="light" />
      </a>
      <b-modal ref="modal-app-landing" id="modal-app-landing" size="lg" centered title="客户端下载" @show="initDownload" v-model="showLandingModal" ok-disabled cancel-title="关闭">
        <b-row>
          <b-col cols="12" class="text-center my-10">
            <h1 class="fs-3 fw-normal text-gray-900">数联智造客户端下载</h1>
            <p class="text-primary">数字汇聚, 赋能制造</p>
            <p class="text-gray-700">PC、工位机、PDA、手机客户端安装</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col ref="app-download-scope-pc" cols="3">
            <div class="d-flex flex-column bg-gray-100 position-relative p-4 h-150px">
              <div class="d-flex flex-column text-primary">
                <icon type="ri" name="mac" mode="line" size="2hx" />
                <h6 class="fw-medium text-primary my-2">浏览器</h6>
              </div>
              <p class="fs-7 text-gray-600 mb-0">适用版本: Chrome 54+、Firefox 36+、Edge 86+</p>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="d-flex flex-column">
              <div class="p-4 position-relative bg-gray-100 h-150px" v-if="!showAndroidQRCode">
                <a href="#" @click.prevent="showAndroidQRCode = true" class="qrcode-angle" />
                <div class="d-flex flex-column text-primary">
                  <icon type="ri" name="android" mode="line" size="2hx" />
                  <h6 class="fw-medium text-primary my-2">Android</h6>
                </div>
                <p class="fs-7 text-gray-600 mb-0">适用版本: Android 4.2+</p>
              </div>
              <div class="w-100 bg-primary d-flex justify-content-center cursor-pointer" v-if="showAndroidQRCode" @click.prevent="showAndroidQRCode = false">
                <vue-qrcode :value="data['android_4_2'].url" :options="{ width: 150, color: { dark: '#ffffff', light: '#ffffff00' } }" />
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="d-flex flex-column">
              <div class="p-4 position-relative bg-gray-100 h-150px" v-if="!showIOSQRCode">
                <a href="#" @click.prevent="showIOSQRCode = true" class="qrcode-angle" />
                <div class="d-flex flex-column text-primary">
                  <icon type="ri" name="app-store" mode="line" size="2hx" />
                  <h6 class="fw-medium text-primary my-2">iOS</h6>
                </div>
                <p class="fs-7 text-gray-600 mb-0">适用版本: iOS 14.2+</p>
              </div>
              <div class="w-100 bg-primary d-flex justify-content-center cursor-pointer" v-if="showIOSQRCode" @click.prevent="showIOSQRCode = false">
                <vue-qrcode value="https://apps.apple.com/cn/app/id1636235447" :options="{ width: 150, color: { dark: '#ffffff', light: '#ffffff00' } }" />
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="d-flex flex-column bg-gray-100 position-relative p-4 h-150px">
              <div class="d-flex flex-column text-primary">
                <icon type="ri" name="mac" mode="line" size="2hx" />
                <h6 class="fw-medium text-primary my-2">工位机</h6>
              </div>
              <p class="fs-7 text-gray-600 mb-0">适用版本: Windows 7+</p>
            </div>
          </b-col>
        </b-row>
        <template #footer>
          <b-button class="w-80px btn-outline-primary" size="lg" variant="dark" @click.prevent="closeModalLanding">关闭</b-button>
        </template>
      </b-modal>
    </template>
  </action-item>
</template>

<script>
import { Component, Vue } from '@min/vue-compatible-decorator'
import ActionItem from '@/components/layout/header-components/action-item'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import AppLandingService from '@/services/app-landing'

@Component({
  components: { VueQrcode, ActionItem }
})
class AppDownload extends Vue {
  showLandingModal = false
  data = {}

  showAndroidQRCode = false
  showIOSQRCode = false

  showLanding () {
    this.showLandingModal = true
  }

  closeModalLanding () {
    this.$refs['modal-app-landing'].hide()
  }

  async initDownload () {
    this.data = await AppLandingService.getList()
  }
}

export default AppDownload
</script>
