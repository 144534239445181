// import AppContainer from '@/components/container/app.vue'
import RoutesSystem from '@/modular/base/system/routes'
import RoutesBasic from '@/modular/base/basic/routes'
import RoutesSale from './sale/routes'
import RoutesPurchase from './purchase/routes'
import RoutesProduction from './production/routes'
import RoutesMaterial from './material/routes'
import RoutesQuality from './quality/routes'
import RoutesDevice from './device/routes'
import RoutesTelemetry from './telemetry/routes'
import RoutesHr from './hr/routes'
import RoutesMonitor from './monitor/routes'
import RoutesOutsource from './outsource/routes'
import RoutesKnife from './knife/routes'
import RoutesSop from './sop/routes'
import RoutesOperation from './operation/routes'
import Integration from './integration/routes'
import { setKeepAlive } from '@/router/keep-alive'

export default [
  // 系统管理
  ...setKeepAlive(RoutesSystem),
  /* {
    path: '/basic',
    name: '基础管理',
    component: RouterView,
    children: RoutesBasic
  }, */
  // 基础管理
  ...setKeepAlive(RoutesBasic),
  // 销售管理
  ...setKeepAlive(RoutesSale),
  // 采购管理,
  ...setKeepAlive(RoutesPurchase),
  // 生产管理,
  ...setKeepAlive(RoutesProduction),
  // 物料管理,
  ...setKeepAlive(RoutesMaterial),
  // 质量管理
  ...setKeepAlive(RoutesQuality),
  // 设备管理
  ...setKeepAlive(RoutesDevice),
  // 设备数采
  ...setKeepAlive(RoutesTelemetry),
  // 人员绩效
  ...setKeepAlive(RoutesHr),
  // SOP管理
  ...setKeepAlive(RoutesSop),
  // 外协管理
  ...setKeepAlive(RoutesOutsource),
  // 刀具管理
  ...setKeepAlive(RoutesKnife),
  // 视频监控
  ...setKeepAlive(RoutesMonitor),
  // 智能运营
  ...setKeepAlive(RoutesOperation),
  // 系统集成
  ...setKeepAlive(Integration)
]
