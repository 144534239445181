<template>
  <b-row class="chart-area">
    <template v-if="configs.items">
      <template v-for="(config, index) in configs.items">
        <b-col :key="`area-${index}`" cols="12" v-if="config.items">
          <chart-area :configs="config"></chart-area>
        </b-col>
        <chart-item :key="`area-${index}`" v-else :config="config"></chart-item>
      </template>
    </template>
    <template v-else>
      <chart-item :config="configs"></chart-item>
    </template>
  </b-row>
</template>

<script>
import { Component, Prop, Vue } from '@min/vue-compatible-decorator'
import ChartItem from './chart-item'

export default
@Component({
  name: 'chart-area',
  components: { ChartItem }
})
class ChartArea extends Vue {
  @Prop({ type: [Object, Array], required: true, default: () => {} }) configs
}
</script>
