<template>
  <div class="bg-main">
    <div style="padding: 64px 30px 0 30px;">
      <div class="d-flex">
        <div style="width: 459px;margin-left: 34px;">
          <!--安全库存预警-->
          <div class="rule-border-1" style="height: 276px">
            <div class="card-title-bg-1 text-left" style="height: 34px;">
              安全库存预警
            </div>
            <div class="card-in-border-1 mx-5" style="height: 303px;margin-top: -33px;">
              <z-original-echarts ref="safeStockWarn" :options="safeStockWarnOptions" title="techOnePie"
                                  style="height: 260px;width: 400px;"></z-original-echarts>
            </div>
          </div>
          <!--成品仓物料数量-->
          <div class="rule-border-1" style="height: 276px">
            <div class="card-title-bg-1 text-left" style="height: 34px;">
              成品仓物料数量
            </div>
            <div class="card-in-border-1 mx-5" style="height: 303px;margin-top: -33px;">
              <z-original-echarts ref="productWarehouse" :options="productWarehouseOptions" title="techTwoPie"
                                  style="height: 260px;width: 400px;"></z-original-echarts>
            </div>
          </div>
        </div>
        <div>
          <div style="width: 880px">
            <!--原料仓-->
            <div class="rule-border-4">
              <div class="card-title-bg-4">原料仓</div>
              <div class="card-table-1">
                <table>
                  <tr class="card-table-body-1" v-for="(item, i) in materialList" :key="i">
                    <td>
                      <div class="overflow-ellipsis" style="width: 120px;margin-right: 10px;">{{ item.name }}</div>
                    </td>
                    <td>
                      <div class="overflow-ellipsis" style="width: 52px;">{{ item.number }}</div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <!--成品仓-->
            <div class="rule-border-5">
              <div class="card-title-bg-4">成品仓</div>
              <div class="card-table-1">
                <table>
                  <tr class="card-table-body-1" v-for="(item, i) in productList" :key="i">
                    <td>
                      <div class="overflow-ellipsis" style="width: 120px;margin-right: 10px;">{{ item.name }}</div>
                    </td>
                    <td>
                      <div class="overflow-ellipsis" style="width: 52px;">{{ item.number }}</div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 459px">
          <!--不良品地图-->
          <div class="rule-border-1" style="height: 276px">
            <div class="card-title-bg-1 text-left" style="height: 34px;">不良品地图</div>
            <div class="card-in-border-1 mx-5" style="height: 303px;margin-top: -33px;">
              <z-original-echarts ref="defectiveMap" :options="defectiveMapOptions" title="techThreePie"
                                  style="height: 260px;width: 400px;"></z-original-echarts>
            </div>
          </div>
          <!--出入库统计-->
          <div class="rule-border-1" style="height: 276px">
            <div class="card-title-bg-1 text-left" style="height: 34px;">
              出入库统计
            </div>
            <div class="card-in-border-1 mx-5" style="height: 303px;margin-top: -33px;">
              <z-original-echarts ref="inOutStatistic" :options="inOutStatisticOptions" title="techFourPie"
                                  style="height: 260px;width: 400px;"></z-original-echarts>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <!--采购入库/销售出库-->
        <div style="width: 459px;margin-left: 34px;">
          <div style="display: inline-flex">
            <div :class="{ 'chose-active': isTopIn }">
              <div class="card-title-bg-2" :class="isInto ? 'card-selected' : 'card-default'"
                   style="height: 34px;margin-right: -18px;">
                <div @mousedown="isTopIn = true" @mouseup="isTopIn = false"
                     style="position: relative; left: 34px;top: 8px; height: 26px;width: 91px;"
                     @click="selectIntoStock">采购入库
                </div>
              </div>
            </div>
            <div :class="{ 'chose-active': isTopOut }">
              <div class="card-title-bg-2" :class="!isInto ? 'card-selected' : 'card-default'"
                   style="height: 34px;margin-left: -17px;">
                <div @mousedown="isTopOut = true" @mouseup="isTopOut = false"
                     style="position: relative; left: 34px;top: 8px; height: 26px;width: 91px;"
                     @click="selectOutStock">销售出库
                </div>
              </div>
            </div>
          </div>
          <div class="rule-border-2" style="height: 374px;">
            <div class="card-table-2">
              <table>
                <tr class="card-table-head-2">
                  <th style="width: 120px;">物料名称</th>
                  <th style="width: 66px;">计划</th>
                  <th style="width: 110px;">进度</th>
                  <th style="width: 52px;">实际</th>
                </tr>
                <template v-if="isInto">
                  <tr class="card-table-body-2 card-progress-bg" v-for="(item, i) in purchaseInList" :key="i">
                    <td>
                      <div class="overflow-ellipsis" style="width: 110px;">
                        {{ item.material_name }}
                      </div>
                    </td>
                    <td>{{ item.plan_number }}</td>
                    <td>
                      <div>
                        <cProgress :value="item.rate" style="width: 86%;margin-left: 4px;"></cProgress>
                      </div>

                    </td>
                    <td style="font-family: ds-digib;font-size: 15px;">{{ item.number }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr class="card-table-body-2 card-progress-bg" v-for="(item, i) in saleOutList" :key="i">
                    <td>
                      <div class="overflow-ellipsis" style="width: 110px;">
                        {{ item.code }}
                      </div>
                   </td>
                    <td>{{ item.plan_number }}</td>
                    <td>
                      <cProgress :value="item.rate" style="width: 86%;margin-left: 4px;"></cProgress>
                    </td>
                    <td style="font-family: ds-digib;font-size: 15px;">{{ item.number }}</td>
                  </tr>
                </template>
              </table>
            </div>
          </div>
        </div>
        <!--备料进度/质量等级-->
        <div style="margin-left: 17px;width: 848px;">
          <div class="card-title-bg-3 text-left" style="height: 34px;">
          </div>
          <div class="rule-border-7" style="height: 361px;width: 848px;display: inline-flex;">
            <div class="rule-pie-1" style="display: inline-flex;">
              <div class="rule-pie-chart-1" style="width: 221px;">
                <z-original-echarts ref="qualityLevel" :options="qualityLevelOptions" title="techFivePie"
                                    style="height: 169px;width: 169px;"></z-original-echarts>
              </div>
              <div class="rule-pie-body-1">
                <div class="body-row-1">
                  <div class="color-div-1" style="background-color: #F4FA6A;"></div>
                  {{ qualityLevelRate[0] }}
                </div>
                <div class="body-row-1">
                  <div class="color-div-1" style="background-color: #89F78B;"></div>
                  {{ qualityLevelRate[1] }}
                </div>
                <div class="body-row-1">
                  <div class="color-div-1" style="background-color: #0EE5C3;"></div>
                  {{ qualityLevelRate[2] }}
                </div>
                <div class="body-row-1">
                  <div class="color-div-1" style="background-color: #08CFFD;"></div>
                  {{ qualityLevelRate[3] }}
                </div>
                <div class="body-row-1">
                  <div class="color-div-1" style="background-color: #0063FE;"></div>
                  {{ qualityLevelRate[4] }}
                </div>
              </div>
            </div>
            <div class="rule-pie-2">
              <div class="rule-pie-chart-2" style="width: 195px;margin-left: 26px;">
                <z-original-echarts ref="materProgress" :options="materProgressOptions" title="techSixPie"
                                    style="height: 169px;width: 169px;"></z-original-echarts>
              </div>
              <div class="rule-pie-body-2" style="margin-left: 26px;">
                <div class="body-row-2">
                  <div class="color-div-2" style="background-color: #F4FA6A;width: 17px;margin-right: 10px;"></div>
                  {{ materProgressRate[0] }}
                </div>
                <div class="body-row-2">
                  <div class="color-div-2" style="background-color: #0EE5C3;width: 17px;margin-right: 10px;"></div>
                  {{ materProgressRate[1] }}
                </div>
                <div class="body-row-2">
                  <div class="color-div-2" style="background-color: #89F78B;width: 17px;margin-right: 10px;"></div>
                  {{ materProgressRate[2] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--库存预警-->
        <div style="width: 459px;margin-left: 17px;">
          <div class="card-title-bg-3 text-left" style="height: 34px;">
            库存预警
          </div>
          <div class="rule-border-3" style="height: 361px;">
            <div class="card-table-2">
              <table>
                <tr class="card-table-head-2">
                  <th style="width: 125px;">物料名称</th>
                  <th style="width: 117px;">告警信息</th>
                  <th style="width: 98px;">时间</th>
                </tr>
                <tr class="card-table-body-2" style="height: 36px;" v-for="(item, i) in stockWarnList" :key="i">
                  <td>{{ item.material_name }}</td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.create_time }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 修饰器依赖
import { Component, Vue } from '@min/vue-compatible-decorator'
import cProgress from '../components/stock-overview/c-progress'
import zOriginalEcharts from '@/components/dashboard/z-original-echarts'
import * as echarts from 'echarts'
import DashboardService from '@/services/dashboard'

export default @Component({
  name: 'device-overview',
  components: { cProgress, zOriginalEcharts, echarts }
})
class DeviceOverview extends Vue {
  isInto = true
  isTopIn = false
  isTopOut = false

  timer = null

  async mounted () {
    await this.init()
    this.timer = setInterval(this.update, 300000)
  }

  destroyed () {
    clearInterval(this.timer)
  }

  async init () {
    const promiseList = [this.getMaterialList(), this.getProductList(), this.getStockWarnList(), this.getPurchaseSaleList(), this.getQualityLevelRate(), this.getMaterProgressRate()]
    await Promise.all(promiseList)
  }

  async update () {
    const promiseList = [this.$refs.safeStockWarn.updateData(), this.$refs.productWarehouse.updateData(), this.$refs.defectiveMap.updateData(), this.$refs.inOutStatistic.updateData(), this.$refs.qualityLevel.updateData(), this.$refs.materProgress.updateData(), this.init()]
    await Promise.all(promiseList)
  }

  // 安全库存预警图表配置
  safeStockWarnOptions = {
    color: ['#EF725D', '#2CFADD'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    grid: {
      left: '0%',
      right: '0%',
      top: '20%',
      bottom: '0%',
      containLabel: true,
      textStyle: {
        color: '#FFF'
      }
    },
    legend: {
      show: false
    },
    xAxis: [
      {
        axisTick: {
          show: false
        },
        axisLabel: {
          interval: 0
        },
        type: 'category',
        boundaryGap: true,
        axisLine: {
          lineStyle: {
            color: 'hsla(181, 70%, 68%, 1)',
            opacity: '0.3',
            type: 'dotted'
          }
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        lineStyle: {
          color: 'hsla(181, 70%, 68%, 1)',
          opacity: '0.3',
          type: 'solid'
        },
        show: true,
        splitNumber: 8,
        splitLine: {
          lineStyle: {
            color: 'rgba(0, 228, 255, 0.08)',
            type: 'dashed',
            width: '2'
          }
        },
        axisLine: {
          lineStyle: {
            color: 'hsla(181, 70%, 68%, 1)',
            opacity: '0.3',
            type: 'dotted'
          }
        }
      }
    ],
    dataset: {
      dimensions: ['title', 'item1', 'item2'],
      source: []
    },
    dataSource: {
      type: 'promise',
      promise: this.getSafeStockWarnList
    },
    series: [
      {
        name: '物料库存',
        type: 'line',
        stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 1
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(243, 115, 93, 0.5)'
            },
            {
              offset: 1,
              color: 'rgba(250, 182, 115, 0)'
            }
          ])
        },
        emphasis: {
          focus: 'series'
        }
      },
      {
        name: '安全库存',
        type: 'line',
        stack: 'Total',
        smooth: true,
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(44, 250, 221, 1)'
            },
            {
              offset: 1,
              color: 'rgba(44, 250, 221, 0)'
            }
          ])
        },
        emphasis: {
          focus: 'series'
        }
      }
    ]
  }

  // 不良品地图图表配置
  defectiveMapOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '1%',
      right: '0%',
      top: '20%',
      bottom: '0%',
      containLabel: true,
      textStyle: {
        color: '#FFF'
      }
    },
    xAxis: {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: 'rgba(0, 189, 245, .3)',
          type: 'solid'
        }
      },
      axisLabel: {
        color: '#74E5E6',
        interval: 0,
        formatter: function (value) {
          // 使用\n手动折行
          return value.length > 5 ? value.slice(0, 5) + '\n' + value.slice(5) : value
        }
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#74E5E6'
      },
      scale: true,
      axisLine: {
        show: true,
        lineStyle: {
          color: 'hsla(181, 70%, 68%, 1)',
          opacity: '0.3',
          type: 'solid'
        }
      },
      splitNumber: 7,
      splitLine: {
        lineStyle: {
          color: 'rgba(0, 228, 255, 0.08)',
          type: 'dashed',
          width: '2'
        }
      }
    },
    dataset: {
      dimensions: ['title', 'item1', 'item2', 'item3'],
      source: []
    },
    dataSource: {
      type: 'promise',
      promise: this.getDefectiveMapList
    },
    series: [
      {
        type: 'line',
        name: '螺栓',
        itemStyle: {
          color: '#64EC9A'
        }
      },
      {
        type: 'line',
        name: '螺母',
        itemStyle: {
          color: '#F2745F'
        }
      },
      {
        showBackground: false,
        name: '总数',
        barWidth: 15,
        type: 'bar',
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(44, 249, 220, 1)'
            },
            {
              offset: 1,
              color: 'rgba(44, 249, 220, 0)'
            }
          ]),
          borderRadius: [8, 8, 0, 0]
        }
      }
    ]
  }

  // 成品仓物料数量图表配置
  productWarehouseOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '0%',
      right: '0%',
      top: '20%',
      bottom: '0%',
      containLabel: true,
      textStyle: {
        color: '#FFF'
      }
    },
    xAxis: {
      type: 'category',
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: 'hsla(181, 70%, 68%, 1)',
          opacity: '0.3',
          type: 'solid'
        }
      },
      axisLabel: {
        color: '#74E5E6',
        interval: 0
      }
    },
    yAxis: {
      type: 'value',
      min: 0,
      axisLine: {
        show: true,
        lineStyle: {
          color: 'hsla(181, 70%, 68%, 1)',
          opacity: '0.3',
          type: 'solid'
        }
      },
      splitNumber: 8,
      splitLine: {
        lineStyle: {
          color: 'rgba(0, 228, 255, 0.08)',
          type: 'dashed',
          width: '2'
        }
      }
    },
    dataset: {
      dimensions: ['title', 'item'],
      source: []
    },
    dataSource: {
      type: 'promise',
      promise: this.getProductWarehouseList
    },
    series: [
      {
        type: 'bar',
        name: '数量',
        showBackground: false,
        barWidth: 15,
        itemStyle: {
          borderRadius: [18, 18, 0, 0],
          color: {
            x: 0,
            y: 1,
            colorStops: [{
              offset: 0, color: 'rgba(44, 250, 221, 0)'
            }, {
              offset: 1, color: 'rgba(44, 250, 221, 1)'
            }]
          }
        }
      }
    ]
  }

  // 出入库统计图表配置
  inOutStatisticOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '1%',
      right: '0%',
      top: '20%',
      bottom: '0%',
      containLabel: true,
      textStyle: {
        color: '#FFF'
      }
    },
    xAxis: {
      type: 'category',
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: 'hsla(181, 70%, 68%, 1)',
          opacity: '0.3',
          type: 'dotted'
        }
      },
      axisLabel: {
        color: '#74E5E6',
        size: '30',
        interval: 0,
        fontFamily: 'Microsoft YaHei',
        formatter: function (value) {
          // 使用\n手动折行
          return value.length > 5 ? value.slice(0, 5) + '\n' + value.slice(5) : value
        }
      }
    },
    yAxis: {
      type: 'value',
      min: 0,
      axisLine: {
        show: true,
        lineStyle: {
          color: 'hsla(181, 70%, 68%, 1)',
          opacity: '0.3',
          type: 'solid'
        }
      },
      splitNumber: 7,
      splitLine: {
        lineStyle: {
          color: 'rgba(0, 228, 255, 0.08)',
          type: 'dashed',
          width: '2'
        }
      }
    },
    dataset: {
      dimensions: ['title', 'item1', 'item2'],
      source: []
    },
    dataSource: {
      type: 'promise',
      promise: this.getInOutStatisticList
    },
    series: [
      {
        type: 'bar',
        name: '实际数量',
        showBackground: false,
        barWidth: 13,
        itemStyle: {
          color: {
            x: 0,
            y: 1,
            colorStops: [{
              offset: 0, color: 'rgba(44, 250, 221, 0)' // 0% 处的颜色
            }, {
              offset: 1, color: 'rgba(44, 249, 220, 0.5)' // 100% 处的颜色
            }]
          },
          borderColor: {
            x: 0,
            y: 1,
            colorStops: [{
              offset: 0, color: 'rgba(44, 250, 221, 0)' // 0% 处的颜色
            }, {
              offset: 1, color: 'rgba(44, 249, 220, 1)' // 100% 处的颜色
            }]
          }
        }
      },
      {
        type: 'bar',
        name: '计划数量',
        showBackground: false,
        barWidth: 13,
        itemStyle: {
          color: {
            x: 0,
            y: 1,
            colorStops: [{
              offset: 0, color: 'rgba(242, 116, 95, 0)' // 0% 处的颜色
            }, {
              offset: 1, color: 'rgba(242, 116, 95, 0.5)' // 100% 处的颜色
            }]
          },
          borderColor: {
            x: 0,
            y: 1,
            colorStops: [{
              offset: 0, color: 'rgba(242, 116, 95, 0)' // 0% 处的颜色
            }, {
              offset: 1, color: 'rgba(242, 116, 95, 1)' // 100% 处的颜色
            }]
          }
        }
      }
    ]
  }

  // 质量等级图表配置
  qualityLevelOptions = {
    color: ['#F4FA6A', '#89F78B', '#0EE5C3', '#08CFFD', '#0063FE'],
    tooltip: {
      trigger: 'item'
    },
    dataset: {
      dimensions: ['name', 'value'],
      source: []
    },
    dataSource: {
      type: 'promise',
      promise: this.getQualityLevelList
    },
    series: [
      {
        name: 'Access',
        type: 'pie',
        radius: ['85%', '100%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 0,
          borderColor: '#0A1718',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          scale: false
        },
        labelLine: {
          show: false
        }
      }
    ]
  }

  // 备料进度图表配置
  materProgressOptions = {
    color: ['#F4FA6A', '#0EE5C3', '#89F78B'],
    tooltip: {
      trigger: 'item'
    },
    dataset: {
      dimensions: ['name', 'value'],
      source: []
    },
    dataSource: {
      type: 'promise',
      promise: this.getMaterProgressList
    },
    series: [
      {
        name: 'Access',
        type: 'pie',
        radius: ['85%', '100%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 0,
          borderColor: '#0A1718',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          scale: false
        },
        labelLine: {
          show: false
        }
      }
    ]
  }

  // 安全库存预警图表配置
  async getSafeStockWarnList () {
    const demo = await DashboardService.getData(2001)
    const data = {
      title: [],
      item1: [],
      item2: []
    }
    for (const i in demo) {
      data.title.push(demo[i].name)
      data.item1.push(parseInt(demo[i].total_number))
      data.item2.push(parseInt(demo[i].safe_number))
    }
    if (demo.length > 6) {
      demo.splice(6, demo.length)
    }
    return data
  }

  // 原料仓列表
  materialList = []

  async getMaterialList () {
    this.materialList = await DashboardService.getData(2008)
    for (const i in this.materialList) {
      this.materialList[i].number = parseInt(this.materialList[i].number) + this.materialList[i].unit_name
    }
    if (this.materialList.length > 5) {
      this.materialList.splice(5, this.materialList.length)
    }
  }

  // 成品仓列表
  productList = []

  async getProductList () {
    this.productList = await DashboardService.getData(2002)
    for (const i in this.productList) {
      this.productList[i].number = parseInt(this.productList[i].number) + this.productList[i].unit_name
    }
    if (this.productList.length > 5) {
      this.productList.splice(5, this.productList.length)
    }
  }

  // 采购入库/销售出库
  purchaseInList = []
  saleOutList = []

  async getPurchaseSaleList () {
    // 采购入库
    this.purchaseInList = await DashboardService.getData(2009)
    if (this.purchaseInList.length > 7) {
      this.purchaseInList.splice(7, this.purchaseInList.length)
    }
    for (const i in this.purchaseInList) {
      this.purchaseInList[i].number = parseInt(this.purchaseInList[i].number)
      this.purchaseInList[i].plan_number = parseInt(this.purchaseInList[i].plan_number)
      this.purchaseInList[i].rate = parseInt(this.purchaseInList[i].rate) * 100
    }
    // 销售出库
    this.saleOutList = await DashboardService.getData(2010)
    if (this.saleOutList.length > 7) {
      this.saleOutList.splice(7, this.saleOutList.length)
    }
    for (const i in this.saleOutList) {
      this.saleOutList[i].number = parseInt(this.saleOutList[i].number)
      this.saleOutList[i].plan_number = parseInt(this.saleOutList[i].plan_number)
      this.saleOutList[i].rate = parseInt(this.saleOutList[i].rate) * 100
    }
  }

  // 不良品地图列表
  async getDefectiveMapList () {
    const demo = await DashboardService.getData(2004)
    const data = {
      title: [],
      item1: [],
      item2: [],
      item3: []
    }
    if (demo.length > 6) {
      demo.splice(5, demo.length)
    }
    for (const i in demo) {
      data.title.push(demo[i].name)
      data.item1.push(parseInt(demo[i].螺栓))
      data.item2.push(parseInt(demo[i].螺母))
      data.item3.push(parseInt(demo[i].total_number))
    }
    return data
  }

  // 成品仓物料数量列表
  async getProductWarehouseList () {
    const demo = await DashboardService.getData(2011)
    const data = { title: [], item: [] }
    for (const i in demo) {
      data.title.push(demo[i].month)
      data.item.push(parseInt(demo[i].number))
    }
    if (demo.length > 6) {
      demo.splice(6, demo.length)
    }
    return data
  }

  // 出入库统计列表
  async getInOutStatisticList () {
    const demo = await DashboardService.getData(2006)
    const data = {
      title: [],
      item1: [],
      item2: []
    }
    if (demo.length > 6) {
      demo.splice(6, demo.length)
    }
    for (const i in demo) {
      data.title.push(demo[i].material_name)
      data.item1.push(parseInt(demo[i].number))
      data.item2.push(parseInt(demo[i].plan_number))
    }
    return data
  }

  // 质量等级列表
  qualityLevelRate = []

  async getQualityLevelList () {
    const demo = await DashboardService.getData(2005)
    const data = []
    const quality = {
      1: '合格', 2: '不合格', 3: '让步合格', 4: '暂控', 5: '待检'
    }
    for (const i in demo) {
      data.push({ value: parseInt(demo[i].number), name: quality[parseInt(demo[i].status)] })
    }
    return data
  }

  async getQualityLevelRate () {
    this.qualityLevelRate = []
    const demo = await DashboardService.getData(2005)
    let totalNum = 0
    for (const i in demo) {
      totalNum += parseInt(demo[i].number)
    }
    for (const i in demo) {
      const rate = (demo[i].number / totalNum * 100).toFixed(1)
      const name = this.transformStatus(demo[i].status)
      this.qualityLevelRate.push(name + '：' + rate + '%')
    }
  }

  // 备料进度列表
  materProgressRate = []

  async getMaterProgressList () {
    const demo = await DashboardService.getData(2003)
    const data = []
    for (const i in demo) {
      data.push({ value: parseInt(demo[i].num), name: demo[i].STATUS })
    }
    return data
  }

  async getMaterProgressRate () {
    const demo = await DashboardService.getData(2003)
    console.log(demo)
    console.log(demo)
    let totalNum = 0
    for (const i in demo) {
      totalNum += parseInt(demo[i].num)
    }
    for (const i in demo) {
      const rate = (demo[i].num / totalNum * 100).toFixed(1)
      this.materProgressRate.push(demo[i].STATUS + '：' + rate + '%')
    }
  }

  // 库存预警列表
  stockWarnList = []

  async getStockWarnList () {
    this.stockWarnList = await DashboardService.getData(2007)
    if (this.stockWarnList.length > 7) {
      this.stockWarnList.splice(7, this.stockWarnList.length)
    }
  }

  selectIntoStock () {
    this.isInto = true
  }

  selectOutStock () {
    this.isInto = false
  }

  transformStatus (data) {
    data = parseInt(data)
    switch (data) {
      case 1:
        return '合格'
      case 2:
        return '不合格'
      case 3:
        return '让步合格'
      case 4:
        return '暂控'
      case 5:
        return '待检'
    }
  }
}
</script>
<style lang="scss" scoped>
.bg-main {
  background-image: url("@assets/img/dashboard/stock-overview/bg-main.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: alibaba-puhuiti;

  .rule-border-1 {
    background-image: url("@assets/img/dashboard/stock-overview/rule-border-1.png");
    background-repeat: no-repeat;
    background-size: 100%;
    margin-bottom: 29px;

    .card-title-bg-1 {
      font-size: 22px;
      line-height: 18px;
      color: #E8FBFF;
      position: relative;
      top: -10px;
      left: 13px;
      font-family: hylingxintij;
    }
  }

  .card-title-bg-2 {
    background-repeat: no-repeat;
    background-size: 100%;
    width: 161px;
    z-index: 1;
    font-size: 22px;
    line-height: 18px;
    color: #E8FBFF;
    font-family: hylingxintij;
    position: relative;
    top: -13px;
  }

  .card-title-bg-2 :hover {
    cursor: pointer;
  }

  .chose-active {
    position: relative;
    top: 2px;
  }

  .rule-border-2 {
    background-image: url("@assets/img/dashboard/stock-overview/rule-border-2.png");
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    top: -36px;
    padding: 47px 29px 33px 64px;
  }

  .card-selected {
    background-image: url("@assets/img/dashboard/stock-overview/card-title-bg-2.png");
  }

  .card-default {
    background-image: url("@assets/img/dashboard/stock-overview/card-title-bg-3.png");
  }

  .rule-border-3 {
    background-image: url("@assets/img/dashboard/stock-overview/rule-border-3.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: -34px;
    padding: 47px 29px 33px 64px;
  }

  .card-title-bg-3 {
    font-size: 22px;
    line-height: 18px;
    color: #E8FBFF;
    position: relative;
    top: -10px;
    left: 13px;
    z-index: 1;
    font-family: hylingxintij;
  }

  .card-title-bg-4 {
    width: 161px;
    z-index: 1;
    font-size: 18px;
    line-height: 18px;
    color: #E8FBFF;
    font-family: hylingxintij;
    position: relative;
    top: 26px;
    left: 44px;
  }

  .rule-border-4 {
    background-image: url("@assets/img/dashboard/stock-overview/rule-border-4.png");
    background-repeat: no-repeat;
    height: 211px;
    width: 221px;
    background-size: 100%;
    position: relative;
    top: 295px;
    left: 124px;
  }

  .rule-border-5 {
    background-image: url("@assets/img/dashboard/stock-overview/rule-border-5.png");
    background-repeat: no-repeat;
    height: 211px;
    width: 221px;
    background-size: 100%;
    position: relative;
    top: -38px;
    left: 533px;
  }

  .rule-border-6 {
    // background-image: url("@assets/img/dashboard/stock-overview/rule-border-6.png");
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    top: -36px;
    padding: 47px 29px 33px 64px;
  }

  .rule-border-7 {
    background-image: url("@assets/img/dashboard/stock-overview/rule-border-7.png");
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    top: -34px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #74E5E6;
    font-size: 18px;
  }
}

.text-digital {
  font-size: 26px;
  font-family: ds-digib;
  font-weight: 400;
  color: #00FEFF;
  line-height: 18px;
}

.info-text {
  font-weight: 400;
  color: #E8FBFF;
  line-height: 18px;
}

.card-table-1 {
  margin: 34px 29px 39px 34px;
}

.card-table-body-1 {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #74E5E6;
  line-height: 13px;
  height: 23px;
}

.card-table-2 {
}

.card-table-head-2 {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #74E5E6;
  line-height: 20px;
  opacity: 0.4;
  height: 38px;
}

.card-table-body-2 {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #74E5E6;
  line-height: 13px;
  height: 37px;
}

.rule-pie-chart-1 {
  padding: 1px 0px 0 8px;
  background-image: url("@assets/img/dashboard/stock-overview/chart-bg-1.png");
  background-size: 186px 172px;
  background-repeat: no-repeat;
}

.rule-pie-1 {
  width: 468px;
  height: 100%;
  padding: 91px 0px 65px 26px;
}

.rule-pie-body-1 {
  width: 228px;
  height: 100%;
  background-image: url("@assets/img/dashboard/stock-overview/progress-bg.png");
  background-size: 82px 195px;
  background-position: right;
  background-repeat: no-repeat;
  margin-top: -13px;
}

.rule-pie-2 {
  width: 429px;
  height: 100%;
  display: inline-flex;
}

.rule-pie-body-2 {
  width: 117px;
  padding-top: 78px;
  height: 100%;
}

.rule-pie-chart-2 {
  margin-top: 91px;
  padding: 1px 0px 0 8px;
  background-image: url("@assets/img/dashboard/stock-overview/chart-bg-2.png");
  background-size: 186px 172px;
  background-repeat: no-repeat;
}

.color-div-1 {
  margin: 7px 17px 23px 13px;
  width: 18px;
  height: 12px;
}

.body-row-1 {
  display: flex;
}

.body-row-2 {
  display: flex;
  margin-bottom: 20px;
}

.color-div-2 {
  margin: 7px 7px 26px 0px;
  width: 29px;
  height: 12px;
}

.card-progress-bg {
  background-image: url("@assets/img/dashboard/stock-overview/inout-progress-bg.png");
  background-size: 110px 29px;
  background-repeat: no-repeat;
  background-position: 182px 4px;
}
</style>
