<template>
  <div class="d-flex flex-grow-1 justify-content-center">
    <div class="w-100px d-flex flex-column align-items-start overflow-visible">
      <img class="w-100px" src="@/assets/img/symbol/no-data.svg" />
      <h5 class="text-start w-400px mt-4">{{ emptyText }}</h5>
      <template v-if="typeOf(props.emptyTextDescription) === 'array'">
        <p class="text-gray-500 text-start w-400px" v-for="(text, index) in props.emptyTextDescription" :key="`empty-text-${index}`">{{ text }}</p>
      </template>
      <p class="text-gray-500 text-start w-400px" v-else>
        {{ props.emptyTextDescription }}
      </p>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from 'vue'
import { typeOf } from '../utils/common.js'

const props = defineProps({
  emptyText: { type: String },
  emptyTextDescription: { type: [String, Array] }
})
</script>
