import { Restful, RestfulRequest, IntelliApi } from '@/services/intelli-api'
import moment from 'moment'

/**
 * IntelliApi Class
 * @class public
  **/
@Restful({
  name: 'public',
  path: '/system/public'
})
class PublicService extends IntelliApi {
  getPublicKey () {
    const timestamp = moment().format('x')
    return this._getPublicKey(timestamp)
  }

  @RestfulRequest({
    method: 'GET',
    path: 'public-key',
    parameters: ['timestamp']
  })
  _getPublicKey (timestamp) {}
}

export default new PublicService()
