<template>
  <div>
    <wrapper v-if="adminType === '1'">
      <div class="mt-3" style="font-weight: bold;font-size: 20px;">欢迎您，超级管理员！</div>
    </wrapper>
    <wrapper v-else class="position-relative" no-header>
      <div class="black-bg" />
      <b-button v-if="!noPageTabs()" variant="primary" class="btn-affix min-w-30px d-flex justify-content-end position-fixed end-0 top-25 text-hover-white rounded-start-pill" @click="restorePage">
        <icon class="text-hover-white" type="ri" name="arrow-left" mode="line" />
        <span class="d-none d-hover-block me-2">返回功能页</span>
      </b-button>
      <b-row class="mt-4">
        <b-col v-for="(widgetLgOption, index) in widgetLgOptions" cols="3" :key="index">
          <overview-widget-lg size="large" :background="widgetLgOption.background" :svgUrl="widgetLgOption.svgUrl" :icon="widgetLgOption.icon" :text="widgetLgOption.text" :number="widgetLgInfo[index]" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="12">
          <overview-progress title="设备实时状态" :progress-item="progressInfo" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col v-for="(widgetOption, index) in widgetOptions" cols="3" :key="index">
          <overview-widget :text="widgetOption.text" :svg-url="widgetOption.svgUrl" :number="widgetInfo[index]"/>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="6">
          <overview-table title="工序任务情况" :fields="processTaskFields" :items="processTaskInfo"/>
        </b-col>
        <b-col cols="6">
          <overview-table title="生产任务情况" :fields="productTaskFields" :items="productTaskInfo"/>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="3">
          <overview-chart ref="deviceErrorRadar" v-bind="deviceErrorRadarConfig"/>
        </b-col>
        <b-col cols="3">
          <overview-chart ref="completeRateGauge" v-bind="completeRateGaugeConfig"/>
        </b-col>
        <b-col cols="3">
          <overview-chart ref="qualityRateGauge" v-bind="qualityRateGaugeConfig"/>
        </b-col>
        <b-col cols="3">
          <overview-chart ref="qualityReasonPie" v-bind="qualityReasonPieConfig"/>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="12">
          <overview-chart ref="productionPlanBar" v-bind="productionPlanBarConfig"/>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="6">
          <overview-chart ref="productionCompleteBar" v-bind="productionCompleteBarConfig"/>
        </b-col>
        <b-col cols="6">
          <overview-table title="当日产量" :fields="dailyProductionFields" :items="dailyProductionInfo" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="12">
          <overview-chart ref="saleOrderLine" v-bind="saleOrderLineConfig"/>
        </b-col>
      </b-row>
    </wrapper>
  </div>
</template>

<script setup>
// 修饰器依赖
import { onMounted, ref, computed, watch, onUnmounted } from 'vue'
import OverviewWidget from '@/components/overview/overview-widget.vue'
import OverviewTable from '@/components/overview/overview-table.vue'
import OverviewChart from '@/components/overview/overview-chart.vue'
import OverviewProgress from '@/components/overview/overview-progress.vue'
import OverviewWidgetLg from '@/components/overview/overview-widget-lg.vue'
import DashboardService from '@/services/dashboard'
import { useProfileStore } from '@/stores/profile'
import dayjs from 'dayjs'
import { restore, getTabs } from '@/components/layout/page-tabs'
import { useRouter } from 'vue-router'

const router = useRouter()
let timeClock = null
let widgetLgInfoClock = null

const adminType = localStorage.getItem('admin_type')

onMounted(() => {
  // eslint-disable-next-line no-undef
  KTApp.init()

  // eslint-disable-next-line no-undef
  KTMenu.createInstances()
  updateFunction()
  timeClock = setInterval(updateFunction, 5 * 60 * 1000)
})

onUnmounted(() => {
  clearInterval(timeClock)
  clearInterval(widgetLgInfoClock)
})

const updateFunction = () => {
  updateProgressInfo()
  updateWidgetInfo()
  updateProcessTaskInfo()
  updateProductTaskInfo()
  updateDeviceErrorRadar()
  updateCompleteRateGauge()
  updateQualityRateGauge()
  updateQualityReasonPie()
  updateProductionPlanBar()
  updateProductionCompleteBar()
  updateDailyProductionInfo()
  updateSaleOrderLine()
}

const profile = computed(() => {
  return useProfileStore().data
})

watch(profile, (value) => {
  if (value) {
    updateWidgetLgInfo()
    widgetLgInfoClock = setInterval(updateWidgetLgInfo, 5 * 60 * 1000)
  }
})

const widgetLgOptions = [
  { text: '成品产量', background: 'url(/assets/img/overview/card-bg-1.png)', svgUrl: '/assets/img/overview/production.png', icon: 'export' },
  { text: '库存量', background: 'url(/assets/img/overview/card-bg-2.png)', svgUrl: '/assets/img/overview/storage.png', icon: 'picture-in-picture-2' },
  { text: '预警数', background: 'url(/assets/img/overview/card-bg-3.png)', svgUrl: '/assets/img/overview/warn.png', icon: 'alert' },
  { text: '通知数', background: 'url(/assets/img/overview/card-bg-4.png)', svgUrl: '/assets/img/overview/notice.png', icon: 'message-2' }
]
const widgetLgInfo = ref([2771, 3650, 0, 16])
const updateWidgetLgInfo = async () => {
  const data = (await DashboardService.getSqlData(13001, { user: profile.value.id }))[0]
  widgetLgInfo.value = [parseInt(data.complete_number || 0), parseInt(data.stock_number || 0), parseInt(data.event_number || 0), parseInt(data.notice_number || 0)]
}

const progressInfo = ref([
  { name: '运行中', number: 55, rate: 55, color: '#24A148', textColor: '#198038' },
  { name: '故障中', number: 15, rate: 15, color: '#DA1E28', textColor: '#A2191F' },
  { name: '待机中', number: 15, rate: 15, color: '#F1C21B', textColor: '#D8A70B' },
  { name: '关机中', number: 15, rate: 15, color: '#C6C6C6', textColor: '#161616' }
])
const updateProgressInfo = async () => {
  const data = (await DashboardService.getData(1901))[0]
  if (data) {
    const allNumber = parseInt(data.yx) + parseInt(data.gz) + parseInt(data.dj) + parseInt(data.gj)
    progressInfo.value = [
      { name: '运行中', number: data.yx, rate: ((data.yx / allNumber) * 100).toFixed(1), color: '#24A148', textColor: '#198038' },
      { name: '故障中', number: data.gz, rate: ((data.gz / allNumber) * 100).toFixed(1), color: '#DA1E28', textColor: '#A2191F' },
      { name: '待机中', number: data.dj, rate: ((data.dj / allNumber) * 100).toFixed(1), color: '#F1C21B', textColor: '#D8A70B' },
      { name: '关机中', number: data.gj, rate: ((data.gj / allNumber) * 100).toFixed(1), color: '#C6C6C6', textColor: '#161616' }
    ]
  }
}

const widgetOptions = [
  { text: '生产异常', svgUrl: '/assets/img/overview/production-warn.svg' },
  { text: '质量异常', svgUrl: '/assets/img/overview/quality-warn.svg' },
  { text: '设备异常', svgUrl: '/assets/img/overview/device-warn.svg' },
  { text: '物料异常', svgUrl: '/assets/img/overview/material-warn.svg' }
]
const widgetInfo = ref([0, 0, 0, 0])
const updateWidgetInfo = async () => {
  const data = await DashboardService.getData(13002)
  for (const item of data) {
    if (item.NAME.includes('生产异常')) widgetInfo.value[0] = parseInt(item.number)
    if (item.NAME.includes('质量异常')) widgetInfo.value[1] = parseInt(item.number)
    if (item.NAME.includes('设备异常')) widgetInfo.value[2] = parseInt(item.number)
    if (item.NAME.includes('物料异常')) widgetInfo.value[3] = parseInt(item.number)
  }
}

const processTaskFields = [
  { key: 'product_line', label: '产线' },
  { key: 'plan_order', label: '工单' },
  { key: 'production', label: '产品' },
  { key: 'start_time', label: '开工时间' },
  { key: 'number', label: '数量' },
  { key: 'technology', label: '当前工序' },
  { key: 'status', label: '状态', type: 'tag' }
]
const processTaskInfo = ref([
  { product_line: '后连接管产线', plan_order: '456', production: '震动芯片套装', start_time: '2024-05-03', number: 202, technology: '制管组二制管（60管）', status: { text: '未开始', variant: 'light-primary' } },
  { product_line: '后连接管产线', plan_order: '456', production: '震动芯片套装', start_time: '2024-05-03', number: 202, technology: '制管组二制管（60管）', status: { text: '未开始', variant: 'light-primary' } },
  { product_line: '后连接管产线', plan_order: '456', production: '震动芯片套装', start_time: '2024-05-03', number: 202, technology: '制管组二制管（60管）', status: { text: '未开始', variant: 'light-primary' } },
  { product_line: '后连接管产线', plan_order: '456', production: '震动芯片套装', start_time: '2024-05-03', number: 202, technology: '制管组二制管（60管）', status: { text: '未开始', variant: 'light-primary' } },
  { product_line: '后连接管产线', plan_order: '456', production: '震动芯片套装', start_time: '2024-05-03', number: 202, technology: '制管组二制管（60管）', status: { text: '未开始', variant: 'light-primary' } },
  { product_line: '后连接管产线', plan_order: '456', production: '震动芯片套装', start_time: '2024-05-03', number: 202, technology: '制管组二制管（60管）', status: { text: '未开始', variant: 'light-primary' } }
])
const updateProcessTaskInfo = async () => {
  const originData = await DashboardService.getData(9110)
  if (originData.length > 0) {
    const data = originData.splice(0, 6)
    for (const item of data) {
      item.number = item.number ? parseFloat(item.number).toFixed(2) : 0
      item.start_time = item.start_time ? item.start_time.slice(0, 10) : ''
      item.status = translateStatus(item.status)
    }
    processTaskInfo.value = data
  }
}
const translateStatus = (status) => {
  switch (status) {
    case '未开始':
      return { text: '未开始', variant: 'light-primary' }
    case '进行中':
      return { text: '进行中', variant: 'light-success' }
    case '暂停中':
      return { text: '暂停中', variant: 'light-warning' }
    case '已结束':
      return { text: '已完成', variant: 'light-info' }
    case '已取消':
      return { text: '已取消', variant: 'light-error' }
    case '正常':
      return { text: '正常', variant: 'light-primary' }
    case '暂停':
      return { text: '暂停', variant: 'light-warning' }
    case '结束':
      return { text: '结束', variant: 'light-info' }
    default:
      return { text: status, variant: 'light-primary' }
  }
}

const productTaskFields = [
  { key: 'order_number', label: '单号' },
  { key: 'customer_name', label: '客户' },
  { key: 'status', label: '状态', type: 'tag' },
  { key: 'production_name', label: '产品' },
  { key: 'deliver_date', label: '交期' },
  { key: 'plan_date', label: '计划完工' },
  { key: 'number', label: '数量' },
  { key: 'schedule_number', label: '进度', variant: 'info' }
]
const productTaskInfo = ref([
  { customer_name: '后连接管产线', order_number: '456', production_name: '震动芯片套装', deliver_date: '2024-05-03', plan_date: '2024-05-13', number: 202, process_name: '制管组二制管（60管）', status: { text: '未开始', variant: 'light-primary' }, schedule_number: '50%' },
  { customer_name: '后连接管产线', order_number: '456', production_name: '震动芯片套装', deliver_date: '2024-05-03', plan_date: '2024-05-23', number: 202, process_name: '制管组二制管（60管）', status: { text: '未开始', variant: 'light-primary' }, schedule_number: '50%' },
  { customer_name: '后连接管产线', order_number: '456', production_name: '震动芯片套装', deliver_date: '2024-05-03', plan_date: '2024-05-13', number: 202, process_name: '制管组二制管（60管）', status: { text: '未开始', variant: 'light-primary' }, schedule_number: '50%' },
  { customer_name: '后连接管产线', order_number: '456', production_name: '震动芯片套装', deliver_date: '2024-05-03', plan_date: '2024-05-14', number: 202, process_name: '制管组二制管（60管）', status: { text: '未开始', variant: 'light-primary' }, schedule_number: '50%' },
  { customer_name: '后连接管产线', order_number: '456', production_name: '震动芯片套装', deliver_date: '2024-05-03', plan_date: '2024-05-15', number: 202, process_name: '制管组二制管（60管）', status: { text: '未开始', variant: 'light-primary' }, schedule_number: '50%' },
  { customer_name: '后连接管产线', order_number: '456', production_name: '震动芯片套装', deliver_date: '2024-05-03', plan_date: '2024-05-16', number: 202, process_name: '制管组二制管（60管）', status: { text: '未开始', variant: 'light-primary' }, schedule_number: '50%' }
])
const updateProductTaskInfo = async () => {
  const originData = await DashboardService.getData(9111)
  if (originData.length > 0) {
    const data = originData.splice(0, 6)
    for (const item of data) {
      item.number = item.number ? parseFloat(item.number).toFixed(2) : 0
      item.schedule_number = parseInt(item.schedule_number) + '%'
      item.status = translateStatus(item.status)
    }
    productTaskInfo.value = data
  }
}

const deviceErrorRadarConfig = ref({
  title: '设备故障分析',
  chartRef: 'deviceErrorRadar',
  options: {
    radar: {
      // shape: 'circle',
      indicator: [
        {
          name: '电流大',
          max: 800,
          axisLabel: {
            show: true,
            fontSize: 12,
            color: '#525252'
          }
        },
        { name: '内燃', max: 800 },
        { name: '电压高', max: 800 },
        { name: '漏油', max: 800 },
        { name: '其他', max: 800 }
      ],
      axisName: {
        color: '#525252'
      },
      splitArea: {
        show: false
      },
      splitNumber: 4
    },
    series: [
      {
        name: 'Budget vs spending',
        type: 'radar',
        symbol: 'none',
        itemStyle: {
          color: '#DA1E28'
        },
        areaStyle: {
          opacity: 0.2
        }
      }
    ],
    dataset: {
      source: [[300, 600, 800, 100, 780]]
    }
  }
})
const updateDeviceErrorRadar = async () => {
  // const data = await DashboardService.getData(9106)
}

function noPageTabs () {
  const pageTabs = getTabs().showing || []
  return !pageTabs.length || pageTabs.length <= 0
}

const completeRateGaugeConfig = ref({
  title: '完成率',
  chartRef: 'completeRateGauge',
  options: {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false
        },
        center: ['50%', '45%'],
        progress: {
          show: true,
          overlap: false,
          clip: false,
          itemStyle: {
            color: '#24A148'
          }
        },
        axisLine: {
          lineStyle: {
            width: 16
          }
        },
        axisLabel: {
          show: false
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10
        },
        axisTick: {
          show: false
        },
        data: [
          {
            value: 70,
            name: '完成率'
          }
        ],
        title: {
          fontSize: 16
        },
        detail: {
          valueAnimation: true,
          fontSize: 50,
          fontFamily: 'Oswald',
          fontWeight: 400,
          offsetCenter: [0, '-10%'],
          formatter: '{value}%'
        }
      }
    ]
  }
})
const completeRateGauge = ref(null)
const updateCompleteRateGauge = async () => {
  const data = (await DashboardService.getData(9103))[0]
  completeRateGauge.value.$refs.completeRateGauge.chart.setOption({
    series: [
      {
        data: [
          {
            value: parseInt(parseFloat(data.product_rate)),
            name: '完成率'
          }
        ]
      }
    ]
  })
}

const qualityRateGaugeConfig = ref({
  title: '合格率',
  chartRef: 'qualityRateGauge',
  options: {
    series: [
      {
        type: 'gauge',
        startAngle: -180,
        endAngle: 0,
        radius: '90%',
        center: ['50%', '60%'],
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          clip: false,
          itemStyle: {
            color: '#1192E8'
          }
        },
        axisLine: {
          lineStyle: {
            width: 16
          }
        },
        axisLabel: {
          show: true
        },
        splitNumber: 1,
        splitLine: {
          show: false,
          distance: 0,
          length: 10
        },
        axisTick: {
          show: false
        },
        data: [
          {
            value: 70,
            name: '合格率'
          }
        ],
        title: {
          fontSize: 16,
          offsetCenter: [0, '-18%']
        },
        detail: {
          valueAnimation: true,
          fontSize: 40,
          fontFamily: 'Oswald',
          fontWeight: 400,
          offsetCenter: [0, '-45%'],
          formatter: '{value}%'
        }
      }
    ]
  }
})
const qualityRateGauge = ref(null)
const updateQualityRateGauge = async () => {
  const data = (await DashboardService.getData(14002))[0]
  if (parseFloat(data.rate)) {
    qualityRateGauge.value.$refs.qualityRateGauge.chart.setOption({
      series: [
        {
          data: [
            {
              value: parseInt(parseFloat(data.rate) * 100),
              name: '合格率'
            }
          ]
        }
      ]
    })
  }
}

const qualityReasonPieConfig = ref({
  title: '不合格原因',
  chartRef: 'qualityReasonPie',
  options: {
    tooltip: {
      trigger: 'item'
    },
    color: ['#8A3FFC', '#1192E8', '#005D5D', '#9F1853', '#FA4D56', '#740937', '#24A148'],
    legend: {
      bottom: '8%',
      left: 'center'
    },
    series: [
      {
        type: 'pie',
        radius: ['50%', '65%'],
        center: ['50%', '40%'],
        avoidLabelOverlap: false,
        label: {
          show: true,
          formatter: '{d}%'
        },
        labelLine: {
          show: true
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: 'bold'
          }
        },
        data: [
          { value: 1048, name: '口外径不合格' },
          { value: 735, name: '瓶高不合格' },
          { value: 580, name: '口内径不合格' },
          { value: 484, name: '结石' },
          { value: 300, name: '污点' }
        ]
      }
    ]
  }
})
const qualityReasonPie = ref(null)
const updateQualityReasonPie = async () => {
  const data = await DashboardService.getData(13003)
  qualityReasonPie.value.$refs.qualityReasonPie.chart.setOption({
    series: [
      {
        data: data.map(item => {
          return {
            value: (parseFloat(item.num) * 100).toFixed(2),
            name: item.name
          }
        })
      }
    ]
  })
}

const productionPlanBarConfig = ref({
  title: '计划与实际产量趋势',
  chartRef: 'productionPlanBar',
  options: {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      bottom: '2%',
      left: '4%'
    },
    grid: {
      left: '2%',
      right: '2%',
      bottom: '10%',
      top: '5%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#6F6F6F'
          }
        },
        axisTick: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        show: true,
        type: 'value',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#8D8D8D'
          }
        }
      }
    ],
    series: [
      {
        name: '已生产数量',
        type: 'bar',
        stack: 'production',
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          color: '#1192E8'
        },
        barWidth: 16
      },
      {
        name: '未生产数量',
        type: 'bar',
        stack: 'production',
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          color: '#EB6200'
        },
        barWidth: 16
      }
    ],
    dataset: {
      dimensions: ['DATE', 'product_number', 'number'],
      source: [
        { DATE: '15日', product_number: 210, number: 0 },
        { DATE: '16日', product_number: 120, number: 2 },
        { DATE: '17日', product_number: 132, number: 5 },
        { DATE: '18日', product_number: 210, number: 4 },
        { DATE: '19日', product_number: 120, number: 3 },
        { DATE: '20日', product_number: 132, number: 2 },
        { DATE: '21日', product_number: 101, number: 1 },
        { DATE: '23日', product_number: 120, number: 22 },
        { DATE: '24日', product_number: 132, number: 0 },
        { DATE: '25日', product_number: 101, number: 19 },
        { DATE: '26日', product_number: 134, number: 23 },
        { DATE: '27日', product_number: 90, number: 0 },
        { DATE: '28日', product_number: 230, number: 33 }
      ]
    }
  }
})

const defaultProductionPlan = [
  { DATE: '15日', product_number: 210, number: 0 },
  { DATE: '16日', product_number: 120, number: 2 },
  { DATE: '17日', product_number: 132, number: 5 },
  { DATE: '18日', product_number: 210, number: 4 },
  { DATE: '19日', product_number: 120, number: 3 },
  { DATE: '20日', product_number: 132, number: 2 },
  { DATE: '21日', product_number: 101, number: 1 },
  { DATE: '23日', product_number: 120, number: 22 },
  { DATE: '24日', product_number: 132, number: 0 },
  { DATE: '25日', product_number: 101, number: 19 },
  { DATE: '26日', product_number: 134, number: 23 },
  { DATE: '27日', product_number: 90, number: 0 },
  { DATE: '28日', product_number: 230, number: 3 },
  { DATE: '29日', product_number: 241, number: 0 },
  { DATE: '30日', product_number: 233, number: 31 }
]

const productionPlanBar = ref(null)
const updateProductionPlanBar = async () => {
  const data = await DashboardService.getData(13004)
  if (data && data.length > 0) {
    productionPlanBar.value.$refs.productionPlanBar.updateData(data.map((item, index) => {
      const number = (item.plan_number ? item.plan_number : (defaultProductionPlan[index].product_number + defaultProductionPlan[index].number)) - (item.product_number ? item.product_number : defaultProductionPlan[index].product_number)
      return {
        DATE: dayjs(item.DATE).format('DD日'),
        product_number: item.product_number ? item.product_number : defaultProductionPlan[index].product_number,
        number: number > 0 ? number : 0
      }
    }))
  }
}

const productionCompleteBarConfig = ref({
  title: '产量完成情况',
  chartRef: 'productionCompleteBar',
  options: {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      bottom: '2%',
      left: '4%'
    },
    grid: {
      left: '2%',
      right: '2%',
      bottom: '10%',
      top: '5%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#6F6F6F'
          }
        },
        axisTick: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        show: true,
        type: 'value',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#8D8D8D'
          }
        }
      }
    ],
    series: [
      {
        name: '计划生产',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          color: '#009D9A'
        },
        barWidth: 8
      },
      {
        name: '实际生产',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          color: '#1192E8'
        },
        barWidth: 8
      }
    ],
    dataset: {
      dimensions: ['date', 'plan_number', 'product_number'],
      source: [
        { date: '22日', plan_number: 210, product_number: 201 },
        { date: '23日', plan_number: 120, product_number: 112 },
        { date: '24日', plan_number: 132, product_number: 118 },
        { date: '25日', plan_number: 101, product_number: 99 },
        { date: '26日', plan_number: 134, product_number: 123 },
        { date: '27日', plan_number: 90, product_number: 88 },
        { date: '28日', plan_number: 230, product_number: 213 }
      ]
    }
  }
})

const defaultProductionComplete = [
  { date: '22日', plan_number: 210, product_number: 201 },
  { date: '23日', plan_number: 120, product_number: 112 },
  { date: '24日', plan_number: 132, product_number: 118 },
  { date: '25日', plan_number: 101, product_number: 99 },
  { date: '26日', plan_number: 134, product_number: 123 },
  { date: '27日', plan_number: 90, product_number: 88 },
  { date: '28日', plan_number: 230, product_number: 213 }
]

const productionCompleteBar = ref(null)
const updateProductionCompleteBar = async () => {
  const data = await DashboardService.getData(9109)
  if (data && data.length > 0) {
    productionCompleteBar.value.$refs.productionCompleteBar.updateData(data.map((item, index) => {
      return {
        date: dayjs(item.date).format('DD日'),
        plan_number: item.plan_number || defaultProductionComplete[index].plan_number,
        product_number: item.product_number || defaultProductionComplete[index].product_number
      }
    }))
  }
}

const dailyProductionFields = ref([
  { key: 'name', label: '工艺' },
  { key: 'plan_number', label: '计划' },
  { key: 'number', label: '实际' },
  { key: 'rate', label: '进度', type: 'progress' }
])
const dailyProductionInfo = ref([
  { tech: '混料', plan: 456, real: 228, rate: '50%' },
  { tech: '压砖', plan: 456, real: 228, rate: '50%' },
  { tech: '烘干', plan: 456, real: 228, rate: '50%' },
  { tech: '出窑', plan: 456, real: 456, rate: '100%' }
])
const updateDailyProductionInfo = async () => {
  const originData = await DashboardService.getData(9305)
  if (originData.length > 0) {
    const data = originData.splice(0, 6)
    for (const item of data) {
      item.number = item.number ? parseFloat(item.number).toFixed(2) : 0
      item.plan_number = item.plan_number ? parseFloat(item.plan_number).toFixed(2) : 0
      item.rate = (item.rate ? parseFloat(item.rate).toFixed(2) : 0) + '%'
    }
    dailyProductionInfo.value = data
  }
}

const saleOrderLineConfig = ref({
  title: '销售订单月度统计',
  chartRef: 'saleOrderLine',
  options: {
    grid: {
      left: 20,
      right: 30,
      bottom: '4%',
      top: '10%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLine: {
        show: true,
        lineStyle: {
          color: '#6F6F6F'
        }
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: true
      }
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: true,
        lineStyle: {
          color: '#6F6F6F'
        }
      },
      axisTick: {
        show: false
      }
    },
    series: [
      {
        type: 'line',
        itemStyle: {
          color: '#4589FF'
        },
        showSymbol: false,
        areaStyle: {
          color: 'rgba(69, 137, 255, .3)'
        }
      }
    ],
    dataset: {
      dimensions: ['mon', 'plan_number'],
      source: [
        { mon: '2023-12', plan_number: 820 },
        { mon: '2024-01', plan_number: 932 },
        { mon: '2024-02', plan_number: 901 },
        { mon: '2024-03', plan_number: 934 },
        { mon: '2024-04', plan_number: 1290 },
        { mon: '2024-05', plan_number: 1330 },
        { mon: '2024-06', plan_number: 1320 }
      ]
    }
  }
})
const saleOrderLine = ref(null)
const updateSaleOrderLine = async () => {
  const data = await DashboardService.getData(9108)
  saleOrderLine.value.$refs.saleOrderLine.updateData(data.map(item => {
    return {
      mon: item.mon,
      plan_number: parseFloat(item.plan_number)
    }
  }))
}

function restorePage () {
  restore(router)
}

</script>
<style scoped>
.row {
  --bs-gutter-x: 1rem;
}
.black-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 11.25rem;
  background-color: var(--bs-gray-900);
  z-index: -1;
  &:before, &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  &:after {
    opacity: .0781823;
    background-image: linear-gradient(45deg, transparent, var(--bs-gray-900)), url(@assets/img/bg/milling-444493_1280.jpg);
    background-repeat: no-repeat;
    background-position: left;
    background-size: 50%;
  }
  &:before {
    opacity: .1781823;
    background-image: url("@assets/img/logo.svg");
    background-size: 24rem;
    background-position: top right;
    background-repeat: no-repeat;
  }
}

.btn-affix {
  padding: .375rem .25rem .375rem .75rem !important;
  &:hover {
    .d-hover-block {
      display: block !important;
    }
  }

  i {
    line-height: 1.25rem;
    margin: 0;
    &:before {
      margin: 0;
    }
  }
}
</style>
