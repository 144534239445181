/*
 * @Author: xuzhitao
 * @Date: 2023-01-04 09:17:11
 * @LastEditors: xuzhitao
 * @LastEditTime: 2023-01-05 09:08:57
 * @FilePath: /mirrors.in-mes.com-ui/src/stores/tenant.ts
 */
import { defineStore } from 'pinia'
import { initCondition } from '@/utils/condition'

export const useTenantStore = defineStore({
  id: 'tenant',
  state: () => ({
    raw: {},
    initialized: initCondition('tenant')
  }),
  getters: {
    data () {
      return this.raw
    },
    isInitialized () {
      return this.initialized.status
    }
  },
  actions: {
    init (tenant) {
      this.raw = tenant
      this.initialized.set()
    }
  }
})
