import { Vue } from '@min/vue-compatible-decorator'
import { useRoute } from 'vue-router'

class VueWithChangeable extends Vue {
  getId () {
    return (useRoute().params.id || '0').toString()
  }
}

export default VueWithChangeable
