<template>
  <span v-bind="$attrs">
    <slot /><sup class="badge badge-sm ms-1" :class="[disabled ? 'badge-light-secondary' : 'badge-light-primary']">PRO</sup>
  </span>
</template>
<script>
import { Vue, Component, Prop } from '@min/vue-compatible-decorator'

@Component()
class Pro extends Vue {
  @Prop({ type: Boolean, default: false })
  disabled
}
export default Pro
</script>
