<template>
  <div class="bg-main">
    <img src="@assets/img/dashboard/production-overview/title-main.png" style="width: 100%" alt="title"/>
    <div style="padding: 13px 33px 22px 33px ">
      <div class="d-flex justify-content-between">
        <div style="width: 429px">
          <div class="bg-left-title" style="height: 26px">
            <div class="card-title pt-1 ps-10">不良率（制成品和成品批次）</div>
          </div>
          <div class="bg-left-card px-2 py-6" style="height: 621px;margin-top: 7px">
            <div class="bg-left-small-title ps-5" style="height: 23px;line-height: 23px;">制成品不良率</div>
            <div class="d-flex">
              <div v-for="(item, i) in productFailureRateList" :key="i" style="width: 20%">
                <div class="d-flex justify-content-center mt-2">
                  <div class="bg-left-bar-title-1 text-center pt-1" style="height: 31px;width: 56px;">{{ item.result }}%</div>
                </div>
                <div class="d-flex justify-content-center mt-2">
                  <div class="bg-left-bar-1 d-flex align-items-end justify-content-center" style="height: 182px;width: 42px;">
                    <div>
                      <div style="background-color:#18ffb2;height: 1px;width: 26px;margin-bottom: 2px"></div>
                      <div style="background-image: linear-gradient(180deg,#00efff 0%,rgba(0, 186, 255, 0) 100%);width: 26px;height: 42px" :style="`height: ${40*(item.result/100)}px;margin-bottom: ${-40*(item.result/100)}px`"></div>
                      <div class="d-flex justify-content-center">
                        <div class="bg-left-bar-value-1" :style="`height: ${175*(item.result/100)}px`"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center mt-2">
                  <div class="bg-left-bar-bottom text-center pt-1 overflow-hidden overflow-ellipsis" style="height: 26px;width: 75px;font-size: 12px">{{ item.name }}</div>
                </div>
              </div>
            </div>
            <div class="bg-left-small-title ps-5 mt-3" style="height: 23px;line-height: 23px;">成品批次不良率</div>
            <div class="d-flex">
              <div v-for="(item, i) in batchFailureRateList" :key="i" style="width: 25%">
                <div class="d-flex justify-content-center mt-2">
                  <div class="bg-left-bar-title-2 text-center pt-1" style="height: 31px;width: 72px;">{{ item.rate }}%</div>
                </div>
                <div class="d-flex justify-content-center mt-2">
                  <div class="bg-left-bar-2 d-flex align-items-end justify-content-center" style="height: 182px;width: 52px;">
                    <div class="text-center">
                      <img src="@assets/img/dashboard/production-overview/bg-left-bar-header.png" style="width: 31px;height: 35px;margin-bottom: -33px"/>
                      <img src="@assets/img/dashboard/production-overview/process.png" style="width: 52px;" :style="`height: ${175*(item.rate/100)}px`"/>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center mt-2">
                  <div class="bg-left-bar-bottom text-center pt-1 overflow-hidden overflow-ellipsis" style="height: 26px;width: 95px;font-size: 12px">{{ item.material }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-center-card" style="height: 654px;width: 904px;padding: 60px 26px 0 26px;">
          <div class="d-flex justify-content-between" style="height: 260px">
            <div class="bg-center-left-card" style="width: 410px">
              <div class="card-title pt-1 ps-9 m-0">完成率和合格率</div>
              <div class="px-10 py-8">
                <div class="bg-center-completion-rate d-flex pt-8" style="height: 82px">
                  <div style="width: 20%;"></div>
                  <div class="text-center" style="width: 50%;">生产完成率</div>
                  <div class="text-digital text-white" style="width: 30%;">{{ productionRate.finish }}%</div>
                </div>
                <div class="bg-center-qualified-rate d-flex pt-8 mt-6" style="height: 82px">
                  <div style="width: 20%;"></div>
                  <div class="text-center" style="width: 50%;">合格率</div>
                  <div class="text-digital text-white" style="width: 30%;">{{ productionRate.qualified }}%</div>
                </div>
              </div>
            </div>
            <div class="bg-center-right-card" style="width: 410px">
              <div class="card-title pt-1 pe-9 text-end m-0">日质量事件</div>
              <div class="px-10 py-8">
                <div class="bg-center-error d-flex pt-8" style="height: 82px">
                  <div style="width: 20%;"></div>
                  <div class="text-center" style="width: 50%;">一般事件</div>
                  <div class="text-digital text-center text-white" style="width: 30%;">20</div>
                </div>
                <div class="bg-center-warning d-flex pt-8 mt-6" style="height: 82px">
                  <div style="width: 20%;"></div>
                  <div class="text-center" style="width: 50%;">严重事件</div>
                  <div class="text-digital text-center text-white" style="width: 30%;">13</div>
                </div>
              </div>
            </div>
          </div>
          <img class="my-4" src="@assets/img/dashboard/production-overview/separator-center.png" style="height: 26px;width: 100%" alt="separator">
          <div class="d-flex">
            <div class="w-50">
              <div class="bg-center-left-title" style="height: 31px;width: 195px;">
                <div class="card-title pt-1 ps-9">不良分布</div>
              </div>
              <div>
                <z-original-echarts ref="deviceErrorPie" :options="deviceErrorPieOptions" title="deviceErrorPie" style="height: 208px;width: 416px;"></z-original-echarts>
              </div>
            </div>
            <div class="w-50 ps-5">
              <div class="bg-center-right-title" style="height: 31px;width: 216px;">
                <div class="card-title pt-1 ps-9 m-0">周质检不合格</div>
              </div>
              <div class="py-3">
                <div v-for="(item, i) in weekUnqualifiedRateList" :key="i" class="d-flex mb-5">
                  <img src="@assets/img/dashboard/production-overview/icon-product.png" style="height: 20px" alt="product"/>
                  <div class="ps-5" style="width: 91px">{{ item.name }}</div>
                  <div class="bg-center-right-process pt-2 d-flex" style="width: 338px;height: 25px;">
                    <div class="progress-value" style="height: 10px;" :style="`width: ${273*(parseInt(item.number)/weekMax)}px`"></div>
                    <div class="ms-4" style="line-height: 10px;">{{ parseInt(item.number) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 429px">
          <div class="bg-left-title" style="height: 26px;line-height: 26px;">
            <div class="card-title ps-10">不良率（验货批次）</div>
          </div>
          <div class="bg-right-card" style="height: 287px;margin-top: 7px;">
            <z-original-echarts ref="qualifiedRateArea" :options="qualifiedRateAreaOptions" title="qualifiedRateArea" style="height: 286px;width: 429px;"/>
          </div>
          <div class="bg-left-title" style="height: 26px;margin-top: 14px;">
            <div class="card-title pt-1 ps-10">日产出缺陷波动</div>
          </div>
          <div class="bg-right-card" style="height: 287px;margin-top: 7px;">
            <z-original-echarts ref="productionErrorBar" :options="productionErrorBarOptions" title="productionErrorBar" style="height: 286px;width: 429px;"/>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between" style="padding-top: 26px">
        <div style="width: 910px">
          <div class="bg-bottom-left-title" style="height: 26px;line-height: 26px;">
            <div class="card-title ps-10">工序任务情况</div>
          </div>
          <div class="bg-bottom-left-card " style="height: 254px;margin-top: 7px;">
            <div class="pt-5" style="padding: 7px">
              <div class="bg-bottom-table-header d-flex px-4 pt-2" style="height: 36px;font-weight: 500">
                <div style="width: 15%">产线</div>
                <div style="width: 15%">工单</div>
                <div style="width: 18%">产品</div>
                <div style="width: 18%">开工时间</div>
                <div style="width: 15%">数量</div>
                <div style="width: 15%">当前工序</div>
                <!--                <div style="width: 15%">暂停时间</div>-->
                <div style="width: 5%">状态</div>
              </div>
              <div v-for="(item, i) in processTaskList" :key="i" class="d-flex mx-4 py-3" style="border-bottom: 1px rgba(0, 132, 255, 0.2) solid">
                <div class="overflow-ellipsis" style="width: 15%">{{ item.product_line }}</div>
                <div class="overflow-ellipsis" style="width: 15%">{{ item.plan_order }}</div>
                <div class="overflow-ellipsis" style="width: 18%">{{ item.production }}</div>
                <div class="overflow-ellipsis" style="width: 18%">{{ item.start_time }}</div>
                <div class="overflow-ellipsis" style="width: 15%">{{ item.number }}</div>
                <div class="overflow-ellipsis" style="width: 15%">{{ item.technology }}</div>
                <!--                <div style="width: 15%">2001.09.25</div>-->
                <div style="width: 5%" :style="`color: ${translateStatusColor(item.status)}`">{{ item.status }}</div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 910px">
          <div class="bg-bottom-right-title" style="height: 26px;">
            <div class="card-title pt-1 ps-10">生产任务情况</div>
          </div>
          <div class="bg-bottom-left-card" style="height: 254px;margin-top: 7px;">
            <div class="pt-5" style="padding: 7px">
              <div class="bg-bottom-table-header d-flex px-4 pt-2" style="height: 36px;font-weight: 500">
                <div style="width: 20%">客户</div>
                <div style="width: 15%">单号</div>
                <div style="width: 8%">状态</div>
                <div style="width: 18%">产品名称</div>
                <div style="width: 10%">交期</div>
                <div style="width: 10%">计划完工时期</div>
                <div style="width: 7%">数量</div>
                <div class="text-center" style="width: 13%">进度</div>
              </div>
              <div v-for="(item, i) in productTaskList" :key="i" class="d-flex mx-4 py-3" style="border-bottom: 1px rgba(0, 132, 255, 0.2) solid">
                <div class="overflow-ellipsis" style="width: 20%">{{ item.customer_name }}</div>
                <div class="overflow-ellipsis" style="width: 15%">{{ item.order_number }}</div>
                <div class="overflow-ellipsis" style="width: 8%" :style="`color: ${translateStatusColor(item.status)}`">{{ item.status }}</div>
                <div class="overflow-ellipsis" style="width: 18%">{{ item.production_name }}</div>
                <div class="overflow-ellipsis" style="width: 10%">{{ item.deliver_date }}</div>
                <div class="overflow-ellipsis" style="width: 10%">{{ item.plan_date }}</div>
                <div class="overflow-ellipsis" style="width: 7%">{{ parseFloat(item.number).toFixed(2) }}</div>
                <div style="width: 13%">
                  <div class="d-flex align-items-center justify-content-between" style="margin-top: -7px">
                    <span class="text-muted me-2 font-weight-bold" style="font-size: 12px">{{ item.number && Number(item.schedule_number) ? (item.number / item.schedule_number).toFixed(2) * 100 : 0 }}%</span>
                    <span class="text-muted font-weight-bold" style="font-size: 12px">进度</span>
                  </div>
                  <div class="progress progress-xs w-100 me-2 h-8px">
                    <div class="progress-bar bg-info" role="progressbar" :style="`width: ${item.number && Number(item.schedule_number) ? parseInt((item.number / item.schedule_number) * 100).toFixed(2) : 0}%;`" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
                <!--                <div style="width: 13%">{{ parseInt((item.number / item.schedule_number) * 100) || 0 }}</div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 修饰器依赖
import { Component, Vue } from '@min/vue-compatible-decorator'
import zOriginalEcharts from '@/components/dashboard/z-original-echarts'
import * as echarts from 'echarts'
import DashboardService from '@/services/dashboard'

export default
@Component({
  name: 'device-overview',
  components: { zOriginalEcharts }
})
class ProductionOverview extends Vue {
  qualifiedRateAreaOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line'
      }
    },
    grid: {
      left: '5%',
      right: '5%',
      top: '12%',
      bottom: '5%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: 'rgba(0, 189, 245, .3)',
          type: 'solid'
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: 'rgba(201, 255, 253, 1)'
      }
    },
    yAxis: [
      {
        type: 'value',
        axisLine: {
          show: true,
          lineStyle: {
            color: 'rgba(0, 189, 245, .3)'
          }
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(0, 132, 255, 0.1)'
          }
        },
        axisLabel: {
          color: 'rgba(201, 255, 253, 1)',
          formatter: '{value}%'
        }
      }
    ],
    dataset: {
      dimensions: ['material', 'rate'],
      source: []
    },
    dataSource: {
      type: 'sql',
      sql: 2106
    },
    series: [
      {
        name: '不良率',
        type: 'line',
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(28, 254, 244, .1)'
            },
            {
              offset: 1,
              color: 'rgba(28, 254, 244, 0)'
            }
          ])
        },
        smooth: true,
        showSymbol: false,
        lineStyle: {
          width: 1
        },
        itemStyle: {
          color: 'rgba(28, 254, 244, 1)'
        }
      }
    ]
  }

  productionErrorBarOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '5%',
      right: '5%',
      top: '12%',
      bottom: '5%',
      containLabel: true
    },
    legend: {
      right: '2%',
      top: '3%',
      textStyle: {
        color: 'rgba(201, 255, 253, 1)'
      }
    },
    xAxis: {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: 'rgba(0, 189, 245, .3)'
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: 'rgba(201, 255, 253, 1)'
      }
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(0, 189, 245, .3)'
        }
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(0, 132, 255, 0.1)'
        }
      },
      axisLabel: {
        color: 'rgba(201, 255, 253, 1)'
      }
    },
    dataset: {
      dimensions: ['date', 'unqualified_number', 'qualified_number'],
      source: []
    },
    dataSource: {
      type: 'promise',
      promise: this.getProductionErrorInfo
    },
    series: [
      {
        name: '不合格数量',
        type: 'bar',
        stack: 'all',
        barWidth: 16,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(255, 40, 117, 1)'
            },
            {
              offset: 1,
              color: 'rgba(255, 40, 117, 0)'
            }
          ]),
          borderRadius: 8
        }
      },
      {
        name: '合格数量',
        type: 'bar',
        stack: 'all',
        barWidth: 16,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(0, 239, 255, 1)'
            },
            {
              offset: 1,
              color: 'rgba(0, 186, 255, 0)'
            }
          ]),
          borderRadius: 8
        }
      }
    ]
  }

  deviceErrorPieOptions = {
    tooltip: {
      trigger: 'item'
    },
    grid: {
      left: '1%',
      right: '3%',
      top: '10%',
      bottom: '5%',
      containLabel: true,
      textStyle: {
        color: '#FFF'
      }
    },
    color: ['#F4FA6A', '#89F78B', '#0EE5C3', '#08CFFD', '#0063FE'],
    series: [
      {
        name: '状态',
        type: 'pie',
        radius: ['65%', '85%'],
        itemStyle: {
          borderRadius: 5,
          borderColor: '#092033',
          borderWidth: 5
        },
        label: {
          color: 'white',
          formatter: '{@defective_reason}',
          fontSize: 14
        },
        tooltip: {
          formatter: '{b}:{d}%'
        }
      }
    ],
    dataset: {
      dimensions: ['defective_reason', 'rate'],
      source: [
        { defective_reason: '外观不达标', rate: 10 },
        { defective_reason: '直径过大/过小', rate: 5 }
      ]
    },
    dataSource: {
      type: 'sql',
      sql: '2104'
    }
  }

  timer = null

  async mounted () {
    await this.init()
    this.timer = setInterval(this.update, 300000)
  }

  destroyed () {
    clearInterval(this.timer)
  }

  async update () {
    const promiseList = [this.$refs.qualifiedRateArea.updateData(), this.$refs.productionErrorBar.updateData(), this.$refs.deviceErrorPie.updateData(), this.init()]
    await Promise.all(promiseList)
  }

  init () {
    const promiseList = [this.getProductFailureRate(), this.getBatchFailureRateList(), this.getProductionRate(), this.getEventInfo(), this.getWeekUnqualifiedRateList(), this.getProcessTaskList(), this.getProductTaskList()]
    Promise.all(promiseList)
  }

  productFailureRateList = []

  async getProductFailureRate () {
    this.productFailureRateList = (await DashboardService.getData(2101)).splice(0, 5)
  }

  batchFailureRateList = []

  async getBatchFailureRateList () {
    this.batchFailureRateList = (await DashboardService.getData(2102)).splice(0, 4)
  }

  productionRate = {
    finish: 0,
    qualified: 0
  }

  async getProductionRate () {
    this.productionRate.finish = (await DashboardService.getData(2109))[0].product_rate || 0
    this.productionRate.qualified = (await DashboardService.getData(2110))[0].quality_rate || 0
  }

  eventInfo = {}

  async getEventInfo () {
    this.eventInfo = await DashboardService.getData(2103)
  }

  async getProductionErrorInfo () {
    return (await DashboardService.getData(2107)).splice(0, 5)
  }

  processTaskList = []
  productTaskList = []

  async getProcessTaskList () {
    this.processTaskList = await DashboardService.getData(2105)
  }

  async getProductTaskList () {
    this.productTaskList = await DashboardService.getData(2111)
  }

  weekUnqualifiedRateList = []

  weekMax = 0

  async getWeekUnqualifiedRateList () {
    let max = 0
    const data = await DashboardService.getData(2108)
    for (const i in data) {
      max = parseInt(data[i].number) > max ? parseInt(data[i].number) : max
    }
    this.weekUnqualifiedRateList = data
    this.weekMax = max
  }

  translateStatusColor (status) {
    switch (status) {
      case '已取消':
        return 'rgba(255, 40, 117, 1)'
      case '暂停中':
        return 'rgba(191, 90, 242, 1)'
      case '已结束':
        return 'rgba(92, 112, 162, 1)'
      case '进行中':
        return 'rgba(0, 132, 255, 1)'
      case '未开始':
        return '#f4fa6a'
      default:
        return ''
    }
  }
}
</script>
<style lang="scss" scoped>
.bg-main {
  color:#bbedff;
  background-image: url("@assets/img/dashboard/production-overview/bg-main.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .bg-left-title {
    background-image: url("@assets/img/dashboard/production-overview/bg-left-title.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .bg-right-title {
    background-image: url("@assets/img/dashboard/production-overview/bg-right-title.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .card-title {
    font-weight:700;
    font-size: 14px;
    background-image: linear-gradient(180deg,#45dfff 0%,#0083ff 100%);
    background-clip: text;
    font-family: alibaba-puhuiti-b;
    -webkit-background-clip:text;
    color: transparent;
  }
  .bg-left-card {
    background-image: url("@assets/img/dashboard/production-overview/bg-left-card.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .bg-left-small-title {
      background-image: url("@assets/img/dashboard/production-overview/bg-left-small-title.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .bg-left-bar-title-1 {
      background-image: url("@assets/img/dashboard/production-overview/bg-left-bar-title-1.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .bg-left-bar-1 {
      background-image: url("@assets/img/dashboard/production-overview/bg-left-bar-1.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .bg-left-bar-value-1 {
        background-image:linear-gradient(180deg,#00efff 0%,rgba(0, 186, 255, 0) 100%);
        width: 16px;
      }
    }
    .bg-left-bar-title-2 {
      background-image: url("@assets/img/dashboard/production-overview/bg-left-bar-title-2.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .bg-left-bar-2 {
      background-image: url("@assets/img/dashboard/production-overview/bg-left-bar-2.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .bg-left-bar-bottom {
      background-image:linear-gradient(180deg,rgba(4, 86, 115, 0.79) 0%,rgba(1, 144, 148, 0.16) 100%);
      border:1px rgba(6, 220, 154, 0.11) solid;
    }
  }
  .bg-center-card {
    background-image: url("@assets/img/dashboard/production-overview/bg-center-card.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .bg-center-left-card {
      background-image: url("@assets/img/dashboard/production-overview/bg-center-left-card.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .bg-center-completion-rate {
        background-image: url("@assets/img/dashboard/production-overview/bg-center-completion-rate.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .bg-center-qualified-rate {
        background-image: url("@assets/img/dashboard/production-overview/bg-center-qualified-rate.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    .bg-center-right-card {
      background-image: url("@assets/img/dashboard/production-overview/bg-center-right-card.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .bg-center-error {
        background-image: url("@assets/img/dashboard/production-overview/bg-center-error.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .bg-center-warning {
        background-image: url("@assets/img/dashboard/production-overview/bg-center-warning.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    .bg-center-left-title {
      background-image: url("@assets/img/dashboard/production-overview/bg-center-left-title.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .bg-center-right-title {
      background-image: url("@assets/img/dashboard/production-overview/bg-center-right-title.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .bg-center-right-process {
      background-image: url("@assets/img/dashboard/production-overview/bg-center-right-process.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .progress-value {
        background-image: linear-gradient(270deg, rgba(0, 239, 255, 1) 0%, rgba(0, 186, 255, 0) 100%);
      }
    }
  }
  .bg-right-card {
    background-image: url("@assets/img/dashboard/production-overview/bg-right-card.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .bg-bottom-left-title {
    background-image: url("@assets/img/dashboard/production-overview/bg-bottom-left-title.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .bg-bottom-right-title {
    background-image: url("@assets/img/dashboard/production-overview/bg-bottom-right-title.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .bg-bottom-left-card {
    background-image: url("@assets/img/dashboard/production-overview/bg-bottom-left-card.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .bg-bottom-table-header {
      background-image: url("@assets/img/dashboard/production-overview/bg-bottom-table-header.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
}
.text-digital {
  font-size:20px;
  font-family: ds-digib;
  font-weight: 400;
  color: #00FEFF;
  line-height: 18px;
}
</style>
