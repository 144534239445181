<!--
 * @Author: xuzhitao
 * @Date: 2023-02-16 18:16:10
 * @LastEditors: xuzhitao
 * @LastEditTime: 2023-02-17 10:43:16
 * @FilePath: /intellidevice-web-app/src/components/zero-vue/components/svg-icon.vue
 * @Description:
-->
<template>
  <svg aria-hidden="true" :class="svgClass" :style="style">
    <use class="use-el" :xlink:href="symbolId" :fill="color" />
  </svg>
</template>

<script>
import { Component, Vue, Prop } from '@min/vue-compatible-decorator'
@Component()
class SvgIcon extends Vue {
  @Prop({ type: String, default: 'icon' }) prefix
  @Prop({ type: String, required: true }) name
  @Prop({ type: String, default: '#ffffff' }) color
  @Prop({ type: String, default: '' }) className
  @Prop({ type: String, default: '2rem' }) width
  @Prop({ type: String, default: '2rem' }) height

  get style () {
    return {
      width: this.width,
      height: this.height,
      color: this.color
    }
  }

  get symbolId () {
    return `#${this.prefix}-${this.name}`
  }

  get svgClass () {
    const defaultName = 'svg-icon-component'
    if (this.className) {
      return `${defaultName} ${this.className}`
    }
    return defaultName
  }
}
export default SvgIcon
</script>
