<template>
  <wrapper iframe>
    <iframe :src="link" class="w-100 h-100"></iframe>
  </wrapper>
</template>
<script>
import { Component, Vue, Watch } from '@min/vue-compatible-decorator'

export default
@Component()
class iframeContainer extends Vue {
  link = ''

  @Watch('$route')
  onRouteChanged (value) {
    this.setSrc(this.$route.hash)
  }

  mounted () {
    this.setSrc(this.$route.hash)
  }

  setSrc (srcHash) {
    this.link = decodeURIComponent(srcHash).substring(1)
  }
}
</script>
