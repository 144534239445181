import { vBTooltip } from 'bootstrap-vue-next'

const vZTooltip = {
  mounted (el, binding) {
    if (binding.value?.enable) {
      return vBTooltip.mounted(el, binding)
    }
  },
  updated (el, binding) {
    if (binding.value?.enable) {
      return vBTooltip.updated(el, binding)
    }
  },
  beforeUnmount (el) {
    return vBTooltip.beforeUnmount(el)
  }
}

export {
  vZTooltip
}
