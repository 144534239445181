let vm = null

function setVm (vue) {
  vm = vue
}

function getVm () {
  return vm
}

export { setVm, getVm }
