import { defineStore } from 'pinia'
import { initCondition } from '@/utils/condition'

export const usePermissionStore = defineStore({
  id: 'permission',
  state: () => ({
    data: [],
    initialized: initCondition('permission')
  }),
  getters: {
    list () {
      return this.data
    },
    isInitialized () {
      return this.initialized.status
    }
  },
  actions: {
    init (permissions) {
      this.data = permissions
      this.initialized.set()
    },
    has (key) {
      return this.data.includes(key)
    }
  }
})
