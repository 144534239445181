import DashboardWrapper from '../layout/wrapper'
import AppContainer from '@/components/container/app.vue'
import LayoutFullscreen from '@/components/layout/fullscreen'

const RoutesDashboard = [
  {
    path: ':id',
    // name: '数据看板',
    component: DashboardWrapper
  }
]

const DashboardApp = {
  path: '/dashboard',
  name: '工厂看板',
  component: AppContainer,
  children: RoutesDashboard
}

const FullscreenDashboard = {
  path: '/dashboard/fullscreen',
  name: '工厂看板可视化',
  component: LayoutFullscreen,
  children: RoutesDashboard
}

export {
  FullscreenDashboard,
  DashboardApp
}
