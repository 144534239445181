import { BasicOverwriteRoutes, BasicRoutes } from '@/modular/custom/modular/basic/routes'

const CustomRoutes = [
  ...BasicRoutes
]
const CustomOverwriteRoutes = [
  ...BasicOverwriteRoutes
]

export {
  CustomRoutes,
  CustomOverwriteRoutes
}
