<template>
  <div class="bg-white p-6">
    <div class="fs-5 lh-2r text-gray-1000">
      {{ title }}
    </div>
    <div id="progress-main" class="progress-main">
      <div :id="`progress-item-${i}`" class="progress-item" v-for="(item, i) in progressItem" :key="i" :style="`background-color: ${item.color};`"></div>
    </div>
    <div class="progress-legend d-flex">
      <div class="me-16 d-flex align-items-center" v-for="(item, i) in progressItem" :key="i">
        <div class="progress-legend-item-icon me-2" :style="`background-color: ${item.color};`"></div>
        <div>{{ item.name }}：</div>
        <div :style="`color: ${item.textColor || item.color};`">{{ item.number }}</div>
        <div class="mx-1" style="height: 10px;width: 1px;background: #E0E0E0;"></div>
        <div style="color: #525252;">{{ item.rate }}%</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, watch } from 'vue'

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  title: { type: String, default: '设备实时状态' },
  progressItem: {
    type: Array,
    default: () => [
      { name: '运行中', number: 55, rate: 55, color: '#24A148', textColor: '#198038' },
      { name: '故障中', number: 15, rate: 15, color: '#DA1E28', textColor: '#A2191F' },
      { name: '待机中', number: 15, rate: 15, color: '#F1C21B', textColor: '#D8A70B' },
      { name: '关机中', number: 15, rate: 15, color: '#C6C6C6', textColor: '#161616' }
    ]
  }
})

const computeWidth = (rate) => {
  const width = document.querySelector('#progress-main').offsetWidth - (props.progressItem.length - 1) * 4
  return width * rate / 100
}

watch(() => props.progressItem, (newVal) => {
  for (const index in newVal) {
    const item = document.querySelector(`#progress-item-${index}`)
    item.style.width = computeWidth(newVal[index].rate) + 'px'
  }
})
</script>

<style scoped lang="scss">
.progress-main {
  margin-top: 1.25rem;
  height: .75rem;
  display: flex;
  width: 100%;
  .progress-item {
    margin-left: 4px;
    &:first-child {
      margin-left: 0;
    }
  }
}
.progress-legend {
  margin-top: 1rem;
  .progress-legend-item-icon {
    width: .75rem;
    height: .75rem;
  }
}
</style>
