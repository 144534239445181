export default [
  {
    path: '/production/schedule/plan-ticket/add',
    name: 'production-schedule-plan-ticket-add',
    component: () => import('../pages/schedule/plan-ticket/add')
  },
  {
    path: '/production/schedule/plan-ticket/add-child/:id',
    name: 'production-schedule-plan-ticket-add-child',
    component: () => import('../pages/schedule/plan-ticket/add-child')
  },
  {
    path: '/production/schedule/plan-ticket/list',
    name: 'production-schedule-plan-ticket-list',
    component: () => import('../pages/schedule/plan-ticket/list')
  },
  {
    path: '/production/schedule/plan-ticket/change/:id',
    name: 'production-schedule-plan-ticket-change',
    component: () => import('../pages/schedule/plan-ticket/change')
  },
  {
    path: '/production/schedule/plan-ticket/detail/:id',
    name: 'production-schedule-plan-ticket-detail',
    component: () => import('../pages/schedule/plan-ticket/detail')
  },
  {
    path: '/production/schedule/plan-scheduling/list',
    name: 'production-schedule-plan-scheduling-list',
    component: () => import('../pages/schedule/plan-scheduling/list')
  },
  {
    path: '/production/schedule/plan-scheduling/log/list',
    name: 'production-schedule-plan-scheduling-log-list',
    component: () => import('../pages/schedule/plan-scheduling/log/list')
  },
  {
    path: '/production/schedule/plan-scheduling/log/detail/:id',
    name: 'production-schedule-plan-scheduling-log-detail',
    component: () => import('../pages/schedule/plan-scheduling/log/detail')
  },
  {
    path: '/production/schedule/project/list',
    name: 'production-schedule-project-list',
    component: () => import('../pages/schedule/project/list')
  },
  {
    path: '/production/schedule/project/detail/:id',
    name: 'production-schedule-project-detail',
    component: () => import('../pages/schedule/project/detail')
  },
  {
    path: '/production/schedule/task/unissued-list',
    name: 'production-schedule-task-unissued-list',
    component: () => import('../pages/schedule/task/unissued-list')
  },
  {
    path: '/production/schedule/task/issue-log/list',
    name: 'production-schedule-task-issue-log-list',
    component: () => import('../pages/schedule/task/issue-log/list')
  },
  {
    path: '/production/schedule/task/issue-log/detail/:id',
    name: 'production-schedule-task-issue-log-detail',
    component: () => import('../pages/schedule/task/issue-log/detail')
  },
  {
    path: '/production/schedule/task/issued-list',
    name: 'production-schedule-task-issued-list',
    component: () => import('../pages/schedule/task/issued-list')
  },
  {
    path: '/production/schedule/task/change-production/:id',
    name: 'production-schedule-task-change-production',
    component: () => import('../pages/schedule/task/change-production')
  },
  {
    path: '/production/schedule/task/detail/:id',
    name: 'production-schedule-task-detail',
    component: () => import('../pages/schedule/task/detail')
  },
  {
    path: '/production/schedule/task/recall-log/list',
    name: 'production-schedule-task-recall-log-list',
    component: () => import('../pages/schedule/task/recall-log/list')
  },
  {
    path: '/production/schedule/task/recall-log/detail/:id',
    name: 'production-schedule-task-recall-log-detail',
    component: () => import('../pages/schedule/task/recall-log/detail')
  },
  {
    path: '/production/schedule/workbench/overview',
    name: 'production-schedule-workbench-overview',
    component: () => import('../pages/schedule/workbench/overview')
  },
  {
    path: '/production/schedule/return-task/list',
    name: 'production-schedule-return-task-list',
    component: () => import('../pages/schedule/return-task/list')
  },
  {
    path: '/production/schedule/return-task/detail/:id',
    name: 'production-schedule-return-task-detail',
    component: () => import('../pages/schedule/return-task/detail')
  },
  {
    path: '/production/trace/material/list',
    name: 'production-trace-material-list',
    component: () => import('../pages/trace/material/list')
  },
  {
    path: '/production/trace/material/detail/:id',
    name: 'production-trace-material-detail',
    component: () => import('../pages/trace/material/detail')
  },
  {
    path: '/production/trace/deep/list',
    name: 'production-trace-deep-list',
    component: () => import('../pages/trace/deep/list')
  },
  {
    path: '/production/report/into/list',
    name: 'production-report-into-list',
    component: () => import('../pages/report/into/list')
  },
  {
    path: '/production/kitting/analyze',
    name: 'production-kitting-analyze',
    component: () => import('../pages/kitting/analyze')
  },
  {
    path: '/production/kitting/compute',
    name: 'production-kitting-compute',
    component: () => import('../pages/kitting/compute')
  },
  {
    path: '/production/kitting/log',
    name: 'production-kitting-log',
    component: () => import('../pages/kitting/log')
  },
  {
    path: '/production/kitting/storage',
    name: 'production-kitting-storage',
    component: () => import('../pages/kitting/storage')
  },
  {
    path: '/production/report/workshop/list',
    name: 'production-report-workshop-list',
    component: () => import('../pages/report/workshop/list')
  },
  {
    path: '/production/report/team/day',
    name: 'production-report-team-day',
    component: () => import('../pages/report/team/day')
  },
  {
    path: '/production/report/team/month',
    name: 'production-report-team-month',
    component: () => import('../pages/report/team/month')
  },
  {
    path: '/production/report/team/statistics',
    name: 'production-report-team-statistics',
    component: () => import('../pages/report/team/statistics')
  },
  {
    path: '/production/report/station/day',
    name: 'production-report-station-day',
    component: () => import('../pages/report/station/day')
  },
  {
    path: '/production/report/station/month',
    name: 'production-report-station-month',
    component: () => import('../pages/report/station/month')
  },
  {
    path: '/production/report/station/statistics',
    name: 'production-report-station-statistics',
    component: () => import('../pages/report/station/statistics')
  },
  {
    path: '/production/report/making/list',
    name: 'production-report-making-list',
    component: () => import('../pages/report/making/list')
  },
  {
    path: '/production/report/making/detail',
    name: 'production-report-making-detail',
    component: () => import('../pages/report/making/detail')
  }
]
