/*
 * @Author: xuzhitao
 * @Date: 2023-01-04 09:04:56
 * @LastEditors: xuzhitao
 * @LastEditTime: 2023-01-04 15:51:05
 * @FilePath: /mirrors.in-mes.com-ui/src/stores/menu.ts
 */
import { defineStore } from 'pinia'
import { initCondition } from '@/utils/condition'

export const useMenuStore = defineStore({
  id: 'menu',
  state: () => ({
    store: [],
    raw: [],
    all: [],
    initialized: initCondition('menu'),
    back: ''
  }),
  getters: {
    categories () {
      return this.store
    },
    menus () {
      return this.store
    },
    rawMenus () {
      return this.raw
    },
    allMenus () {
      return this.all
    },
    backMenu () {
      return this.back
    },
    isInitialized () {
      return this.initialized.status
    }
  },
  actions: {
    init (menu) {
      this.raw = [{
        id: 100000010,
        meta: {
          title: '工作台',
          icon: 'mi-menu:workbench',
          show: false
        },
        name: 'overview',
        path: '/overview',
        pid: 0
      }, ...menu]
      this.store = parseNode([...this.raw], 0)
      this.all = JSON.parse(JSON.stringify(this.all)).concat(this.raw)
      this.initialized.set()
    },
    setBackMenu (menu) {
      this.back = menu
    },
    addMenu (menus) {
      this.all = JSON.parse(JSON.stringify(this.all)).concat(menus)
    },
    getMenu (path) {
      for (const n in this.raw) {
        // if ((!this.raw[n].children || this.raw[n].children.length === 0) && this.raw[n].path === path) {
        if (this.raw[n].path === path) {
          return this.raw[n]
        }
      }
      return null
    },
    getMenuByName (name) {
      const route = null
      name = name.replace(/-/g, '_')
      for (const n in this.raw) {
        if (this.raw[n].name === name) {
          return { ...this.raw[n] }
        }
      }
      return route
    },
    getMenuByNameWithParents (name) {
      let route = null
      name = name.replace(/-/g, '_')
      for (const n in this.raw) {
        if (this.raw[n].name === name) {
          route = Object.assign({}, this.raw[n])
          break
        }
      }

      let parents = []

      if (route) {
        parents = getParentById(route.pid)
      }

      return {
        route,
        parents
      }
    }
  }
})

function parseNode (data, parentId) {
  const tree = []
  if (data && data.length && data.length > 0) {
    data.forEach((node, index) => {
      if (Number(node.pid) === Number(parentId)) {
        delete data[index]
        if (node.meta.show) {
          node.children = parseNode(data, Number(node.id))
          if (node.meta && node.meta.icon) {
            const stack = node.meta.icon.split(':')
            node.meta.icon = {
              type: stack[0] || 'icon',
              content: stack[1] || '',
              stacks: stack
            }
          }
          tree.push(node)
        }
      }
    })
  }
  return tree
}

function getParentById (id) {
  const parents = []
  if (!['', 0, '0'].includes(id)) {
    for (const k in this.raw) {
      if (this.raw[k].id === id) {
        const ups = getParentById(this.raw[k].pid)
        if (ups.length !== 0) {
          parents.push(...ups)
        }
        parents.push(this.raw[k])
      }
    }
  }
  return parents
}
