import { Restful, RestfulRequest, IntelliApi } from '@/services/intelli-api'

/**
 * IntelliApi Class
 * @class common
  **/
@Restful({
  name: 'common',
  path: '/common'
})
class CommonService extends IntelliApi {
  @RestfulRequest({
    method: 'GET',
    path: 'column/configuration/one',
    parameters: ['table_name']
  })
  getColumnConfig (tableName) {
    return response => {
      const jsonData = response.more || {}
      try {
        return JSON.parse(jsonData)
      } catch (e) {
        return {}
      }
    }
  }

  @RestfulRequest({
    method: 'POST',
    path: 'column/configuration',
    parameters: ['table_name', 'more']
  })
  _setColumnConfig (tableName, config) {}

  setColumnConfig (tableName, config) {
    return this._setColumnConfig(tableName, JSON.stringify(config))
  }
}

export default new CommonService()
