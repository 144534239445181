<template>
<!--  <div class="app-blank bgi-size-cover bgi-attachment-fixed bgi-position-center bgi-no-repeat">
  </div>-->
  <b-row class="login login-3 d-flex justify-content-center align-content-start">
    <div class="logo"><img class="w-150px" src="/assets/img/logo-brand-blue.svg" /></div>
    <div class="login-splash"></div>
    <div class="login-wrapper">
      <b-col cols="5" offset="7">
        <b-row class="login-container d-flex justify-content-end">
          <div class="login-area">
            <form class="form fv-plugins-bootstrap fv-plugins-framework" novalidate>
              <div class="py-12">
                <span class="fw-normal text-muted fs-3">{{ pkg.tenant?.name || '数字汇聚, 赋能智造' }}</span>
                <h3 class="fw-normal text-dark-50 fs-3 mt-2">欢迎使用数联智造, 请登录</h3>
              </div>
              <div class="card">
                <div class="card-body p-0">
                  <template v-if="tenantSelection">
                    <div class="form-floating mx-6 my-4 w-350px w-xxl-400px">
                      <b-button class="form-control btn-justify-content-between px-4 text-gray-500 text-hover-light text-active-light fw-normal" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" data-kt-menu-offset="0, 0" variant="active-primary">
                        {{ tenantShowName }}
                        <icon name="down" class="rotate-180 ms-3 me-0" size="3" />
                      </b-button>
                      <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary full-width fw-semibold" data-kt-menu="true">
                        <div class="menu-item p-4">
                          <a href="#" :key="`tenant-selection-${index}`" v-for="(tenant, index) in tenantList" @click="selectTenant(index)" class="menu-link px-3">
                            {{ tenant['name'] }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="separator border-gray-100 m-0" />
                  </template>
                  <template v-else-if="!pkg.tenant?.code || pkg.tenant.code === ''">
                    <div class="form-floating mx-4 my-3">
                      <input @keydown.enter="login" v-model="tenant" class="form-control border-0" type="text" name="tenant" autocomplete="off" placeholder="工厂账号" tabindex="1`">
                      <label class="fs-6 text-muted" for="tenant">工厂账号</label>
                    </div>
                    <div class="separator border-gray-100 m-0" />
                  </template>
                  <div class="form-floating mx-4 my-3">
                    <input @keydown.enter="login" v-model="username" class="form-control border-0" type="text" name="username" autocomplete="off" placeholder="用户名/邮箱/账号" tabindex="2">
                    <label class="fs-6 text-muted" for="username">用户名/邮箱/账号</label>
                  </div>
                  <div class="separator border-gray-100 m-0" />
                  <div class="form-floating mx-4 my-3">
                    <input @keydown.enter="login" v-model="password" class="form-control border-0" type="password" name="password" autocomplete="off" placeholder="密码" tabindex="3">
                    <label class="fs-6 text-muted" for="password">密码</label>
                  </div>
                </div>
              </div>
              <b-row class="my-12">
                <b-col cols="5">
                  <b-button variant="link" size="lg"><span class="text-primary">忘记密码?</span></b-button>
                </b-col>
                <b-col cols="7" class="d-grid gap-2">
                  <b-button class="shadow" variant="primary" size="lg" :disabled="isLoggingIn" @keydown.enter="login"  @click="login" tabindex="4"><span class="fs-5 fw-semibold">登录</span></b-button>
                </b-col>
              </b-row>
            </form>
          </div>
        </b-row>
      </b-col>
    </div>
  </b-row>
</template>

<script>
import { Component, Vue, Watch } from '@min/vue-compatible-decorator'
import AccountService from '@/services/account'
import notification from '@/utils/notification'
import Encrypt from '@/utils/encrypt'
import { useVersionStore } from '@/stores/version'
import { clear } from '@/components/layout/page-tabs'

@Component()
class Login extends Vue {
  pkg = useVersionStore().data

  username = ''
  password = ''
  tenant = ''

  tenantSelection = false
  tenantList = []
  tenantShowName = '请选择工厂账号'
  redirect = '/'

  isLoggingIn = false

  @Watch('tenant')
  tenantToUpper (value) {
    this.tenant = value.toUpperCase()
  }

  async login () {
    clear()
    const waiting = notification.info('登录中', '登录中, 请稍后...')
    waiting.then(data => {
      this.isLoggingIn = false
    }).catch(() => {
      this.isLoggingIn = false
    })
    try {
      if (this.username === '' || this.tenant === '' || this.password === '') {
        await notification.warning('登录失败', '工厂、账号、密码不能为空')
        return
      }
      this.isLoggingIn = true
      const encrypt = new Encrypt()
      const password = await encrypt.encode(this.password)
      await AccountService.login(this.username, password, this.tenant, encrypt.getKey())
      waiting.close()
      this.isLoggingIn = false
      this.$router.push(this.redirect)
    } catch (e) {
      console.log(e)
      waiting.close()
      this.isLoggingIn = false
      await notification.warning('登录失败', e.data.message)
    }
  }

  forgetPassword () {
    notification.info('忘记密码', '请联系系统管理员进行密码重置')
  }

  selectTenant (index) {
    this.tenant = this.tenantList[index].code
    this.tenantShowName = this.tenantList[index].name
    this.redirect = this.tenantList[index].redirect || '/'
  }

  created () {
    if (this.pkg.tenant?.code) {
      this.tenant = this.pkg.tenant.code
    } else if (this.pkg.tenant?.length > 0) {
      this.tenantList = this.pkg.tenant
      this.tenantSelection = true
    }
  }

  mounted () {
    // eslint-disable-next-line no-undef
    KTMenu.createInstances()
  }
}

export default Login
</script>
