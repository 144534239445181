export default [
  {
    path: '/hr/salary-record/piece/add',
    name: 'hr-salary-record-piece-add',
    component: () => import('../pages/salary-record/piece/add')
  },
  {
    path: '/hr/salary-record/piece/list',
    name: 'hr-salary-record-piece-list',
    component: () => import('../pages/salary-record/piece/list')
  },
  {
    path: '/hr/salary-record/piece/change/:id',
    name: 'hr-salary-record-piece-change',
    component: () => import('../pages/salary-record/piece/change')
  },
  {
    path: '/hr/salary-record/piece/detail/:id',
    name: 'hr-salary-record-piece-detail',
    component: () => import('../pages/salary-record/piece/detail')
  },
  {
    path: '/hr/salary-record/time/add',
    name: 'hr-salary-record-time-add',
    component: () => import('../pages/salary-record/time/add')
  },
  {
    path: '/hr/salary-record/time/list',
    name: 'hr-salary-record-time-list',
    component: () => import('../pages/salary-record/time/list')
  },
  {
    path: '/hr/salary-record/time/change/:id',
    name: 'hr-salary-record-time-change',
    component: () => import('../pages/salary-record/time/change')
  },
  {
    path: '/hr/salary-record/time/detail/:id',
    name: 'hr-salary-record-time-detail',
    component: () => import('../pages/salary-record/time/detail')
  },
  {
    path: '/hr/salary-record/order/add',
    name: 'hr-salary-record-order-add',
    component: () => import('../pages/salary-record/order/add')
  },
  {
    path: '/hr/salary-record/order/list',
    name: 'hr-salary-record-order-list',
    component: () => import('../pages/salary-record/order/list')
  },
  {
    path: '/hr/salary-record/order/change/:id',
    name: 'hr-salary-record-order-change',
    component: () => import('../pages/salary-record/order/change')
  },
  {
    path: '/hr/salary-record/order/detail/:id',
    name: 'hr-salary-record-order-detail',
    component: () => import('../pages/salary-record/order/detail')
  },
  {
    path: '/hr/salary/type/add',
    name: 'hr-salary-type-add',
    component: () => import('../pages/salary/type/add')
  },
  {
    path: '/hr/salary/type/list',
    name: 'hr-salary-type-list',
    component: () => import('../pages/salary/type/list')
  },
  {
    path: '/hr/salary/type/change/:id',
    name: 'hr-salary-type-change',
    component: () => import('../pages/salary/type/change')
  },
  {
    path: '/hr/salary/type/detail/:id',
    name: 'hr-salary-type-detail',
    component: () => import('../pages/salary/type/detail')
  },
  {
    path: '/hr/salary/adjust/add',
    name: 'hr-salary-adjust-add',
    component: () => import('../pages/salary/adjust/add')
  },
  {
    path: '/hr/salary/adjust/list',
    name: 'hr-salary-adjust-list',
    component: () => import('../pages/salary/adjust/list')
  },
  {
    path: '/hr/salary/adjust/change/:id',
    name: 'hr-salary-adjust-change',
    component: () => import('../pages/salary/adjust/change')
  },
  {
    path: '/hr/salary/adjust/detail/:id',
    name: 'hr-salary-adjust-detail',
    component: () => import('../pages/salary/adjust/detail')
  },
  {
    path: '/hr/salary/param/list',
    name: 'hr-salary-param-list',
    component: () => import('../pages/salary/param/list')
  },
  {
    path: '/hr/salary/param/change/:id',
    name: 'hr-salary-param-change',
    component: () => import('../pages/salary/param/change')
  },
  {
    path: '/hr/salary/param/detail/:id',
    name: 'hr-salary-param-detail',
    component: () => import('../pages/salary/param/detail')
  },
  {
    path: '/hr/salary/create/add',
    name: 'hr-salary-create-add',
    component: () => import('../pages/salary/create/add')
  },
  {
    path: '/hr/salary/create/list',
    name: 'hr-salary-create-list',
    component: () => import('../pages/salary/create/list')
  },
  {
    path: '/hr/salary/draft/list',
    name: 'hr-salary-draft-list',
    component: () => import('../pages/salary/draft/list')
  },
  {
    path: '/hr/salary/draft/change/:id',
    name: 'hr-salary-draft-change',
    component: () => import('../pages/salary/draft/change')
  },
  {
    path: '/hr/salary/detail/list',
    name: 'hr-salary-detail-list',
    component: () => import('../pages/salary/detail/list')
  },
  {
    path: '/hr/salary/own',
    name: 'hr-salary-own',
    component: () => import('../pages/salary/own/list')
  },
  {
    path: '/hr/check-work/set/list',
    name: 'hr-check-work-set-list',
    component: () => import('../pages/check-work/set/list')
  },
  {
    path: '/hr/check-work/set/add',
    name: 'hr-check-work-set-add',
    component: () => import('../pages/check-work/set/add')
  },
  {
    path: '/hr/check-work/set/change/:id',
    name: 'hr-check-work-set-change',
    component: () => import('../pages/check-work/set/change')
  },
  {
    path: '/hr/check-work/set/detail/:id',
    name: 'hr-check-work-set-detail',
    component: () => import('../pages/check-work/set/detail')
  },
  {
    path: '/hr/check-work/statistics/list',
    name: 'hr-check-work-statistics-list',
    component: () => import('../pages/check-work/statistics/list')
  },
  {
    path: '/hr/check-work/records/list',
    name: 'hr-check-work-records-list',
    component: () => import('../pages/check-work/records/list')
  }
]
