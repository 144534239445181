<template>
  <div :class="computedClass">
    <!--begin::Body-->
    <div class="card-body py-3">
      <div class="d-flex align-items-center justify-content-between flex-grow-1">
        <div v-if="title" :class="['d-flex flex-column', (titleAlign === 'right' ? 'me-2 text-right' : '')]">
          <span class="font-weight-bolder font-size-h5 card-widget-title">{{ title }}</span>
          <span v-if="summary" class="font-weight-bold mt-2">{{ summary }}</span>
        </div>
        <slot name="aside-area" />
      </div>
      <slot>
        <p class="display-3 mt-3">{{ value }}</p>
      </slot>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { Component, Prop, Vue } from '@min/vue-compatible-decorator'

export default
@Component()
class ZWidget extends Vue {
  @Prop({ type: String }) variant
  @Prop({ type: String }) hoverVariant
  @Prop({ type: Boolean, default: false }) stretch
  @Prop({ type: String }) bgIcon
  @Prop({ type: String, default: 'right top' }) bgIconPosition
  @Prop({ type: String }) title
  @Prop({ type: String, default: 'left' }) titleAlign
  @Prop({ type: String }) titleVariant
  @Prop({ type: String }) titleHoverVariant
  @Prop({ type: String }) summary
  @Prop({ type: String }) value
  @Prop({ type: Boolean, default: true }) transparent

  get computedTitleClass () {
    const classObject = ['font-weight-bolder font-size-h5']

    if (this.variant) {
      classObject.push('text-white')
    } else if (this.titleVariant) {
      classObject.push(`text-${this.titleVariant}`)
    } else {
      classObject.push('text-dark-75')
    }

    return classObject
  }

  get computedClass () {
    const classObject = ['card card-custom card-widget']

    if (this.stretch) {
      classObject.push('card-stretch')
    }

    if (this.$attrs.class) {
      classObject.push(this.$attrs.class)
    }

    if (this.variant) {
      classObject.push(`bg-${this.variant}`)
    }

    if (this.hoverVariant) {
      classObject.push(`bg-${this.hoverVariant}`)
    }

    if (this.transparent) {
      classObject.push('border-0 shadow-none bg-transparent')
    }

    if (this.bgIcon && this.bgIcon !== '') {
      classObject.push(`background-position: ${this.bgIconPosition}`)
      classObject.push('background-size: 30% auto')
      classObject.push('background-repeat: no-repeat')
      if (this.bgIcon.indexOf('/') !== -1) {
        classObject.push(`background-image: url(${this.bgIcon})`)
      } else {
        classObject.push(`background-image: url(@assets/img/icon/svg/${this.name.replace(/\./g, '/')}.svg)`)
      }
    }

    return classObject
  }
}
</script>
