import { useMenuStore } from '@/stores/menu'
import { useProfileStore } from '@/stores/profile'
import { activeTab } from '@/components/layout/page-tabs'
import { getCached, getIncludes, KEEPALIVE_EVENT } from '@/router/keep-alive'
import Event from '@/utils/event'

export function createPageTabsGuard (router) {
  router.beforeEach((to, from) => {
    const cached = getCached(to.name)
    if (cached && cached.fullPath !== to.fullPath) {
      Event.emit(KEEPALIVE_EVENT.REMOVE_INCLUDE, 'default', to.name)
    }
  })

  router.beforeResolve((to, from) => {
    const keepIncludes = getIncludes()
    if (keepIncludes.indexOf(to.name) > -1) {
      Event.emit(KEEPALIVE_EVENT.ADD_INCLUDE, 'default', to.name, to.fullPath)
    }
  })
  router.afterEach(async (to, from) => {
    useProfileStore().initialized.run(() => {
      if (to.name !== 'overview') {
        const menu = useMenuStore().getMenuByName(to.name)
        if (menu) {
          activeTab({
            ...menu,
            title: menu.meta.title,
            icon: menu.meta.icon,
            params: to.params || {},
            props: to.props || {},
            path: to.path || '',
            fullPath: to.fullPath || '',
            name: to.name || ''
          })
        }
      }
    }, 'PAGE_TAB_ADD_ON_ROUTE')
  })
}
