<template>
  <wrapper>
    <ul class="nav nav-light-success nav-pills justify-content-center" id="myTab3" role="tablist">
<!--      <li class="nav-item">-->
<!--        <a class="nav-link active" data-toggle="tab" href="#tenant">-->
<!--            <span class="nav-icon">-->
<!--              <i class="flaticon-buildings"></i>-->
<!--            </span>-->
<!--          <span class="nav-text">租户信息修改</span>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a class="nav-link" :class="showTab === 'admin' ? 'active' : ''" data-toggle="tab" href="#admin" aria-controls="profile" @click.prevent="showTab = 'admin'">-->
<!--            <span class="nav-icon">-->
<!--              <i class="flaticon-user"></i>-->
<!--            </span>-->
<!--          <span class="nav-text">用户信息修改</span>-->
<!--        </a>-->
<!--      </li>-->
      <li class="nav-item">
        <a class="nav-link" :class="showTab === 'password' ? 'active' : ''" data-toggle="tab" href="#password" aria-controls="contact" @click.prevent="showTab = 'password'">
            <span class="nav-icon">
              <i class="flaticon-lock"></i>
            </span>
          <span class="nav-text">用户密码修改</span>
        </a>
      </li>
    </ul>
    <div class="tab-content mt-5" id="myTabContent3">
<!--      <div class="tab-pane fade active show" id="tenant" role="tabpanel" aria-labelledby="home-tab-3">-->
<!--        <zero-form :change="true" :configs="tenantConfigs" :backFill="tenantInfo" @submit="changeTenant" @cancel="$router.push('/')" />-->
<!--      </div>-->
<!--      <div class="tab-pane fade" :class="showTab === 'admin' ? 'active show' : ''" id="admin" role="tabpanel" aria-labelledby="profile-tab-3">-->
<!--        <zero-form :change="true" :configs="adminConfigs" :backFill="adminInfo" @submit="changeAdmin" @cancel="$router.push('/')" />-->
<!--      </div>-->
      <div class="tab-pane fade" :class="showTab === 'password' ? 'active show' : ''" id="password" role="tabpanel" aria-labelledby="contact-tab-3">
        <zero-form :configs="passwordConfigs" @submit="changePassword" @cancel="$router.push('/')" />
      </div>
    </div>
  </wrapper>
</template>

<script>
// 修饰器依赖
import { Component, Vue } from '@min/vue-compatible-decorator'
import TenantService from '@/services/tenant'
// 提示组件引入
import notification from '@/utils/notification'
import AccountService from '@/services/account'
import Encrypt from '@/utils/encrypt'

export default
@Component()
class TenantChange extends Vue {
  tenantInfo = null

  adminInfo = null

  tenantConfigs = [
    {
      title: '租户基本信息',
      forms: [
        {
          label: '租户名称',
          key: 'name'
        },
        {
          label: '手机号',
          key: 'mobile',
          validation: {
            regExp: /^([1][3,4,5,6,7,8,9])\d{9}$/,
            label: '输入的手机号不存在'
          }
        },
        {
          label: '电子邮箱',
          key: 'email',
          validation: {
            regExp: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
            label: '输入的邮箱不合规范'
          }
        },
        {
          label: '租户简介',
          key: 'description',
          type: 'textarea',
          required: false
        }
      ]
    }
  ]

  adminConfigs = [
    {
      title: '租户管理员信息',
      forms: [
        {
          label: '管理员用户名',
          key: 'username'
        },
        {
          label: '手机号',
          key: 'mobile',
          validation: {
            regExp: /^([1][3,4,5,6,7,8,9])\d{9}$/,
            label: '输入的手机号不存在'
          }
        },
        {
          label: '电子邮箱',
          key: 'email',
          validation: {
            regExp: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
            label: '输入的邮箱不合规范'
          }
        },
        {
          label: '管理员简介',
          key: 'display',
          type: 'textarea',
          required: false
        }
      ]
    }
  ]

  passwordConfigs = [
    {
      title: '租户管理员密码',
      forms: [
        {
          label: '旧密码',
          description: '初始密码',
          config: {
            type: 'password'
          },
          key: 'password'
        },
        {
          label: '密码',
          key: 'new_password',
          description: '密码长度12-20位，必须包含大写字母，小写字母，数字及特殊字符',
          config: {
            type: 'password'
          },
          validation: {
            regExp: /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{12,20}$/,
            label: '密码长度12-20位，必须包含大写字母，小写字母，数字及特殊字符'
          }
        },
        {
          label: '确定密码',
          key: 'repeatPassword',
          config: {
            type: 'password'
          },
          validation: {
            func: (data, formData) => {
              return data === formData.new_password
            },
            label: '密码不一致'
          }
        }
      ]
    }
  ]

  areaList = []

  tenantId = ''

  adminId = ''

  showTab = 'password'

  mounted () {
    // this.get()
  }

  // 获取当前租户信息
  async get () {
    try {
      const data = await TenantService.getCurrentTenant()
      this.tenantInfo = data
      this.adminInfo = data.users
      this.tenantId = data.id
      this.adminId = data.users.id
    } catch (e) {
      console.log(e)
    }
  }

  async changeTenant (data) {
    try {
      await TenantService.changeTenant(this.tenantId, data)
      await notification.success('修改成功', '')
      await this.$router.push('/application/list')
    } catch (e) {
      await notification.error('修改失败', e.data.message)
    }
  }

  async changeAdmin (data) {
    try {
      await TenantService.changeAdmin(this.adminId, data)
      await notification.success('修改成功', '')
      await this.$router.push('/application/list')
    } catch (e) {
      await notification.error('修改失败', e.data.message)
    }
  }

  async changePassword (data) {
    try {
      const encrypt = new Encrypt()
      data.password = await encrypt.encode(data.password)
      data.new_password = await encrypt.encode(data.new_password)
      data.repeatPassword = await encrypt.encode(data.repeatPassword)
      data.public_key = encrypt.getKey()
      await AccountService.changePassword(data)
      await notification.success('修改成功', '')
      await AccountService.logout()
    } catch (e) {
      notification.error('修改失败', e.data.message)
    }
  }
}
</script>
