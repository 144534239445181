<template>
  <b-modal id="modal-about" v-model="modalAboutShow" centered size="lg" hide-header hide-footer body-class="p-0">
    <div class="w-100 h-100 bgi-no-repeat bgi-position-x-end bgi-position-y-center" :style="{ backgroundImage: 'url(/assets/img/bg/symbol.png)' }">
      <div class="w-100 h-80px ribbon ribbon-start">
        <div class="ribbon-label bg-primary rounded-end-1 fs-4 ff-jetbrains-mono">{{ p['app-version'] }} - {{ p.milestone }}</div>
      </div>
      <div class="w-100 p-10 pt-0">
        <h2 class="text-dark pb-5 font-weight-bolder">{{ p.name }}<sup><span class="badge badge-dark ms-2">{{ p.type }}</span></sup><br><small class="text-muted fw-light">INTELLIGENT-MES</small></h2>
        <p class="text-muted pb-5">{{ `${p.environment}(${p.type})` }}
          <br>Version {{ p['app-version'] }}
          <br>Build {{ p['build-version'] }}
          <br>内核版本 {{ p['kernel-version'] }}
        </p>
        <p class="text-muted pt-7 pb-5">授权给:
          <br>{{ p['license-to'] }}</p>
      </div>
    </div>
    <b-button variant="light-primary" class="w-90px me-6 mb-6 position-absolute end-0 bottom-0" @click="modalAboutShow = false">关闭</b-button>
  </b-modal>
</template>

<script>
// 修饰器依赖
import { Component, Vue } from '@min/vue-compatible-decorator'
// import Package from '../../../package.json'
import { useVersionStore } from '@/stores/version'

@Component()
class About extends Vue {
  modalAboutShow = false
  p = {}

  mounted () {
    this.p = useVersionStore().data
  }

  show () {
    this.modalAboutShow = true
  }

  hide () {
    this.modalAboutShow = false
  }
}

export default About
</script>
