import { Restful, IntelliApi } from '@/services/intelli-api'

/**
 * IntelliApi Class
 * @class basic-quality-class
  **/
@Restful({
  name: 'basic-quality-class',
  path: 'base/qa/item-category'
})
class QualityClassService extends IntelliApi {}

export default new QualityClassService()
