<template>
  <DatePicker
    v-if="!props.static"
    v-bind="$props"
    v-model="modelValue"
    confirm
    confirm-text="确认"
    show-time-header
    :time-title-format="props.timeTitleFormat"
    :format="format"
    value-type="format"
    :class="[invalid ? 'is-invalid' : '']"
  />
  <p v-else class="form-control-plaintext text-gray-800">{{ props.data }}</p>
</template>
<script setup>
import { defineModel, defineProps, computed, ref, defineExpose } from 'vue'
import DatePicker from '@zero/datepicker'
import { isEmpty } from '../../utils/common'

const locale = {
  months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
  monthsShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  weekdays: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
  weekdaysShort: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
  weekdaysMin: ['日', '一', '二', '三', '四', '五', '六'],
  firstDayOfWeek: 1,
  firstWeekContainsDate: 4,
  meridiemParse: /上午|下午/,
  meridiem: function meridiem (hour) {
    if (hour < 12) {
      return '上午'
    }
    return '下午'
  },
  isPM: function isPM (input) {
    return input === '下午'
  }
}

const format = computed(() => {
  switch (props.type) {
    case 'datetime':
      return 'YYYY-MM-DD HH:mm:ss'
    case 'date':
      return 'YYYY-MM-DD'
    case 'time':
      return 'HH:mm:ss'
    case 'month':
      return 'YYYY-MM'
    case 'year':
      return 'YYYY'
    default:
      return 'YYYY-MM-DD'
  }
})

const lang = {
  formatLocale: locale,
  yearFormat: 'YYYY年',
  monthFormat: 'MMM',
  monthBeforeYear: false
}
DatePicker.locale('zh-cn', lang)

const props = defineProps({
  static: { type: Boolean, default: false },
  timeTitleFormat: { type: String, default: 'HH:mm:ss' },
  data: {},
  type: { type: String, default: 'date' },
  format: { type: String, default: 'YYYY-MM-DD' },
  required: { type: Boolean, default: false },
  label: { type: String, default: '' },
  for: { type: String, default: '' }
})

const modelValue = defineModel()
const invalid = ref(false)

/**
 *
 * @returns { Object } data
 * @returns { number } data.status 0: 正常, 1: error, 2: warning
 * @returns { string } data.message
 * @returns { any } data.data
 */
function validate () {
  if (props.required && isEmpty(modelValue.value)) {
    invalid.value = true
    return { status: 1, message: `${props.label ?? props.for ?? '此项'}必填` }
  } else {
    invalid.value = false
    return { status: 0, message: '' }
  }
}

defineExpose({ validate })
</script>
