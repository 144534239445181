<template>
  <div>
    <div class="progress-body" :style="[{'width': value + '%' }, {'background': 'linear-gradient(90deg, ' + bgStartColor + ',' + bgEndColor + ')'}]"></div>
  </div>
</template>

<script>
export default {
  name: 'progress',
  props: ['value'],
  data () {
    return {
      bgEndColor: '',
      bgStartColor: ''
    }
  },
  watch: {
    value (newV, oldV) {
      this.setBgColor()
    }
  },
  created () {
    this.setBgColor()
  },
  methods: {
    setBgColor () {
      const data = parseInt(this.value)
      if (data >= 0 && data < 10) {
        this.bgStartColor = 'rgba(237, 113, 91, 0)'
        this.bgEndColor = 'rgba(237, 113, 91, 1)'
      } else if (data >= 10 && data < 60) {
        this.bgStartColor = 'rgba(245, 205, 38, 0)'
        this.bgEndColor = 'rgba(245, 205, 38, 1)'
      } else if (data >= 60 && data < 100) {
        this.bgStartColor = 'rgba(0, 187, 242, 0)'
        this.bgEndColor = 'rgba(0, 187, 242, 1)'
      } else if (data === 100) {
        this.bgStartColor = 'rgba(44, 248, 219, 0)'
        this.bgEndColor = 'rgba(44, 248, 219, 1)'
      }
    }
  }
}
</script>
<style scoped>
.progress-body {
  height: 8px;
  border-radius: 0px 9px 9px 0px;
}
</style>
