<template>
  <template v-if="props.static">
    <p class="form-control-plaintext pb-0" v-if="multiple">
<!--      <template v-for="(value, index) in dataValue" :key="`static-value-${index}`">

        <span class="badge rounded-pill badge-lg badge-secondary me-2 mb-1">{{ value }}</span>
      </template>-->
      <zero-tag-group :items="dataValue" />
    </p>
    <p class="form-control-plaintext text-gray-800" v-else>{{ dataValue[0] }}</p>
  </template>
  <tree-select
    v-else-if="!loading"
    ref="select"
    class="form-control"
    :class="[invalid ? 'is-invalid' : '']"
    :title="title"
    v-b-tooltip.hover.top.body="{ delay: 0 }"
    v-model="$_VALUE"
    :multiple="multiple"
    :name="name"
    :disabled="disabled"
    :placeholder="placeholder"
    :openDirection="openDirection"
    no-options-text="没有可用选项"
    no-children-text="没有下级选项"
    no-results-text="没有可选结果"
    search-prompt-text="输入关键字搜索"
    clear-value-text="清空"
    clear-all-text="清空"
    loading-text="加载中..."
    :async="hasAsyncData"
    :load-options="getAsyncData"
    :options="$_OPTIONS"
    :append-to-body="!alwaysOpen"
    v-on="computedEvents"
    :alwaysOpen="alwaysOpen"
    :z-index="1060"
    @select="(node) => emit('select', node)"
    @deselect="(node) => emit('select', node)"
    @update:modelValue="(value) => emit('change', value)"
  >
    <template #option-label="{ node }">
      <span class="vue-treeselect__label">{{ node.label }}</span>
    </template>
    <template #before-list>
      <slot name="before-list" />
    </template>
    <template #after-list>
      <slot name="after-list" />
    </template>
  </tree-select>
</template>

<script setup>
import { defineProps, defineModel, defineEmits, ref, computed, onMounted, watch, useAttrs, defineExpose, nextTick } from 'vue'
import TreeSelect, { ASYNC_SEARCH } from '@zero/vue3-treeselect'
import { isEmpty, typeOf } from '../../utils/common'

const emit = defineEmits(['select', 'change'])
const props = defineProps({
  static: { type: Boolean, default: false },
  multiple: { type: Boolean, default: false },
  name: { type: String },
  placeholder: { type: String, default: '' },
  openDirection: { type: String, default: 'auto' },
  options: { type: [Array, Object], default: () => { return [] } },
  asyncOptions: { type: [Function, Boolean], default: false },
  extraService: { type: [Function, Boolean], default: false },
  asyncService: { type: [Object, Boolean], default: false },
  asyncKey: { type: String, default: 'id' },
  asyncLabel: { type: String, default: 'name' },
  alwaysOpen: { type: Boolean, default: false },
  data: {},
  showTooltip: { type: Boolean, default: true },
  incrementalMode: Boolean,
  label: String,
  for: String,
  required: Boolean,
  disabled: Boolean,
  validation: {},
  displayCode: { type: Boolean, default: true },
  extraProp: { type: Array }
})

const $_VALUE = defineModel()
const $_OPTIONS = ref(undefined)
const dataValue = ref([])
const select = ref()
const title = ref('')
const invalid = ref(false)
const loading = ref(false)

// const hasAsyncData = computed(() => props.asyncOptions !== false || props.asyncService !== false)
const hasAsyncData = ref(false)
const asyncOptionsKey = ref(new Date().getTime())
/* const computedOptions = computed(() => {
  if (hasAsyncData.value) {
    return undefined
  }
  const data = []
  if (props.options && props.options.length > 0) {
    for (const n in props.options) {
      if (['string', 'number'].includes(typeOf(props.options[n]))) {
        data.push({
          id: props.options[n],
          label: props.options[n] + ''
        })
      } else {
        data.push(Object.assign({
          id: props.options[n].id ?? props.options[n].value,
          label: props.options[n].label ?? props.options[n].text
        }, props.options[n]))
      }
    }
  }
  return data
}) */

const computedEvents = computed(() => {
  const attrs = useAttrs()
  const events = {}
  for (const key in attrs) {
    if (key.startsWith('on')) {
      events[key] = attrs[key]
    }
  }
  return events
})

watch(() => props.options, options => {
  if (hasAsyncData.value) {
    $_OPTIONS.value = undefined
    return
  }
  const data = []
  if (options && options.length > 0) {
    for (const n in options) {
      if (['string', 'number'].includes(typeOf(options[n]))) {
        data.push({
          id: options[n],
          label: options[n] + ''
        })
      } else {
        data.push({
          id: props.options[n].id ?? props.options[n].value,
          label: props.options[n].label ?? props.options[n].text,
          ...props.options[n]
        })
      }
    }
  }
  $_OPTIONS.value = data
}, { immediate: true, deep: true })

watch(hasAsyncData, value => {
  asyncOptionsKey.value = new Date().getTime()
})

watch([$_VALUE, () => props.options], async ([value, options]) => {
  if (!isEmpty(value)) {
    const data = await getDataLabels(props.multiple ? value : [value], options)
    if (select.value) {
      for (const k in data) {
        select.value.forest.nodeMap[k].label = data[k]
      }
      title.value = Object.values(data).join(', ')
    }
    if (props.static) {
      dataValue.value = data
    }
  } else {
    title.value = ''
    loading.value = true
    await nextTick()
    loading.value = false
  }
}, { deep: true, immediate: true })

/* onBeforeMount(() => {
  hasAsyncData.value = props.asyncOptions !== false || props.asyncService !== false
  if (!hasAsyncData.value) {
    loaded.value = true
  }
}) */

onMounted(async () => {
  hasAsyncData.value = props.asyncOptions !== false || props.asyncService !== false
})

async function getAsyncData ({ action, searchQuery, callback }) {
  if (action === ASYNC_SEARCH) {
    if (searchQuery) {
      let data = { records: [] }
      if (props.asyncOptions) {
        data = await props.asyncOptions(searchQuery)
      } else {
        data = await props.asyncService.asyncSearch(searchQuery)
      }
      const list = []
      // console.log(props)
      for (const item of data.records) {
        if (props.extraProp && props.extraProp.length > 0) {
          const infoItem = {
            id: item[props.asyncKey],
            label: (item.code && props.displayCode ? item.code : '') + (props.displayCode && item.code && item[props.asyncLabel] ? '/' : '') + (item[props.asyncLabel] ? item[props.asyncLabel] : ''),
            remark: item.remark,
            unit_name: item.unit_name,
            qr_number: item.qr_number
          }
          props.extraProp.forEach(extraKey => {
            infoItem[extraKey] = item[extraKey]
          })
          list.push(infoItem)
        } else {
          list.push(
            { id: item[props.asyncKey], label: (item.code && props.displayCode ? item.code : '') + (props.displayCode && item.code && item[props.asyncLabel] ? '/' : '') + (item[props.asyncLabel] ? item[props.asyncLabel] : ''), remark: item.remark, unit_name: item.unit_name, qr_number: item.qr_number }
          )
        }
      }
      callback(null, list)
    }
  }
}

/* function updateDataLabels (values, options) {
  const dataLabels = (props.static ? [] : {})
  for (const i in values) {
    const index = options.findIndex(option => (option.id ? option.id.toString() : option.value.toString()) === values[i].toString())
    if (index > -1) {
      if (props.static) {
        dataLabels.push(options[index].label ?? options[index].text)
      } else {
        dataLabels[values[i]] = options[index].label ?? options[index].text
      }
    }
  }
  return dataLabels
} */

async function getDataLabels (values, options) {
  const dataLabels = (props.static ? [] : {})
  // 异步获取options
  if ((props.asyncOptions && props.extraService) || props.asyncService) {
    for (const i in values) {
      let data = { code: '', name: '' }
      if (props.extraService) {
        data = await props.extraService(values[i])
      } else if (props.asyncService) {
        data = await props.asyncService.getDetail(values[i])
      }
      if (props.static) {
        dataLabels.push((data.code && props.displayCode ? data.code : '') + (props.displayCode && data.code && data[props.asyncLabel] ? '/' : '') + (data[props.asyncLabel] ? data[props.asyncLabel] : ''))
      } else {
        dataLabels[values[i]] = (data.code && props.displayCode ? data.code : '') + (props.displayCode && data.code && data[props.asyncLabel] ? '/' : '') + (data[props.asyncLabel] ? data[props.asyncLabel] : '')
      }
    }
  } else if (props.asyncOptions && !props.extraService) {
    return dataLabels
  } else {
    for (const i in values) {
      const index = options.findIndex(option => (option.id ? option.id.toString() : option.value.toString()) === values[i].toString())
      if (index > -1) {
        if (props.static) {
          dataLabels.push(options[index].label ?? options[index].text)
        } else {
          dataLabels[values[i]] = options[index].label ?? options[index].text
        }
      }
    }
  }
  return dataLabels
}

function validate () {
  if (props.required && isEmpty($_VALUE.value)) {
    invalid.value = true
    return { status: 1, message: `${props.label ?? props.name ?? '此项'}必填` }
  } else {
    invalid.value = false
    return { status: 0, message: '' }
  }
}

defineExpose({ validate })
</script>
