<template>
  <div class="d-flex justify-content-between w-100">
    <div class="d-flex align-items-center text-gray-600">
      每页显示
      <b-dropdown variant="link" :text="`${size}`" class="my-2 me-4">
        <b-dropdown-item v-for="option in sizeOptions" :key="`pagination-size-${option.id}`" @click="setPageSize(option.id)">
          {{ option.label }}
        </b-dropdown-item>
      </b-dropdown>
      <span class="w-1px bg-gray-200 h-100" />
      <span class="ms-3">
        {{ (props.current - 1) * props.size + 1 }} - {{ props.current * props.size > props.total ? props.total : props.current * props.size }} 条 , 共 {{ props.total }} 条
      </span>
    </div>
    <div class="kt-pagination kt-pagination--brand d-flex">
      <!-- v-model="pagination.current" -->
      <b-pagination
        :model-value="props.current"
        :total-rows="props.total"
        :per-page="props.size"
        :disabled="disabled"
        class="kt-pagination__links mb-0"
        first-class="first-page"
        prev-class="prev-page"
        next-class="next-page"
        last-class="last-page"
        @update:model-value="$event => pageChange($event)"
      >
<!--        <template #first-text><i class="ki-solid ki-double-left fs-4" /></template>-->
<!--        <template #prev-text><i class="ki-duotone ki-left fs-4" /></template>-->
<!--        <template #next-text><i class="ki-duotone ki-right fs-4" /></template>-->
<!--        <template #last-text><i class="ki-solid ki-double-right fs-4" /></template>-->
      </b-pagination>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue'

const props = defineProps({
  size: { type: Number, default: 20 },
  current: { type: Number, default: 1 },
  total: { type: Number, default: 0 },
  disabled: { type: Boolean, default: false }
})

const emit = defineEmits(['update:size', 'update:current'])

const sizeOptions = [
  { id: 10, label: '10条' },
  { id: 20, label: '20条' },
  { id: 50, label: '50条' },
  { id: 100, label: '100条' }
]

const setPageSize = (size) => {
  emit('update:current', 1)
  emit('update:size', size)
}

const pageChange = (page) => {
  emit('update:current', page)
}
</script>

<style scoped>

</style>
