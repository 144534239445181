<template>
  <teleport :to="`#form-title-${name}`">
    <div class="card-toolbar m-0 ms-2">
      <ul class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
        <li v-for="(tab, i) in computedConfig" :key="`form-tab-${i}`" class="nav-item">
          <a :class="['nav-link text-active-primary', i === 0 ? 'active' : '']" data-bs-toggle="tab" :href="`#${tab.key}`">
              <span v-if="tab.icon" class="nav-icon">
                <i :class="tab.icon" />
              </span>
            <span class="nav-text">{{ tab.title }}</span>
          </a>
        </li>
      </ul>
    </div>
  </teleport>
  <div class="tab-content">
    <div v-for="(tab, i) in computedConfig" :id="tab.key" :key="i" class="tab-pane fade" :class="i === 0 ? 'active show' : ''" role="tabpanel" :aria-labelledby="`tab-${i}`">
      <slot :name="tab.key" :form="tab.config" :data="dataValue">
        <component
          :ref="el => components[tab.key] = el"
          :is="tab.component"
          :data="props.data ? props.data[tab.key] : {}"
          :config="tab.config.type === 'bindComponent' ? tab.config.configs : tab.config"
        />
      </slot>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineModel, defineExpose, onMounted, ref, watch } from 'vue'
import { getStaticComponent, hasComponent } from './internal-types'
import { display } from '../utils/common'

const props = defineProps({
  isStaticMode: { type: Boolean, default: true },
  config: {},
  name: { type: String },
  title: { type: String },
  data: { type: Object, default: () => {} }
})

const components = ref({})
const computedConfig = ref([])
const keyList = ref({})
const dataValue = ref({})
const isValueInitialized = ref(false)
const modelValue = defineModel({ default: {} })

watch(modelValue, value => {
  if (!isValueInitialized.value) {
    const data = {}
    for (const n in value[props.name]) {
      data[n] = value[props.name][n]
    }
    isValueInitialized.value = true
    dataValue.value = data
  }
})

function initTabs () {
  const config = props.config
  const tabsConfig = []
  const value = {}

  for (const i in config.tabs) {
    value[config.tabs[i].key] = {}
    const tabConfigRaw = config.tabs[i]
    tabConfigRaw.display = display(tabConfigRaw.display)
    tabConfigRaw.componentRaw = tabConfigRaw.component
    if (hasComponent(tabConfigRaw.component)) {
      tabConfigRaw.component = getStaticComponent(tabConfigRaw.component)
    }

    // 当zero-form时重新配置配置文件
    if (tabConfigRaw.component === 'zero-form-block-static' && tabConfigRaw.config.type === 'bindComponent') {
      tabConfigRaw.config.configs = tabConfigRaw.config.configs[0]
    }

    /* if (tabConfigRaw.config?.length) {
      tabConfigRaw.config = tabConfigRaw.config[0] ?? {}
    } */
    tabsConfig.push(tabConfigRaw)
  }

  dataValue.value = {
    [props.name]: value
  }
  computedConfig.value = tabsConfig
}

function verify () {
  const validationResult = {
    status: 0,
    message: '',
    data: {}
  }
  const validationList = {}
  for (const k in keyList.value) {
    // 判断是否display数据
    if (keyList.value[k].display(props.data)) {
      // 判断子组件是否有verify方法
      if (components.value[k].verify) {
        validationList[k] = components.value[k].verify()
        if (validationList[k].status !== 0) {
          validationResult.status = 1
        }
      } else {
        if (keyList.value[k].required && [null, undefined, ''].includes(modelValue.value[k])) {
          validationList[k] = {
            status: 1,
            message: '此项必填'
          }
          validationResult.status = 1
        } else {
          validationList[k] = {
            status: 0,
            message: ''
          }
        }
      }
    }
  }
  validationResult.data = validationList
  return validationResult
}

onMounted(() => {
  initTabs()
  /* if (props.mode !== 'change') {
    const data = {}
    for (const i in tabsConfig.value) {
      data[tabsConfig.value[i].key] = null
    }
    modelValue.value = data
  } */
})

defineExpose({ verify })
</script>
