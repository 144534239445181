<template>
  <div class="app-page flex-column flex-column-fluid">
    <page-header :is-super-admin="isSuperAdmin" :profile="profile" :tenant="tenant" :messages="messages" :bread-crumb="breadCrumb" />
    <div id="kt_app_wrapper"
         class="app-wrapper flex-column flex-row-fluid"
         :style="computedStyle"
         :data-kt-app-sidebar-secondary-show="isAppSidebarSecondaryShow ? 'on' : 'off'"
         :data-kt-app-sidebar-primary-collapse="isAppSidebarPrimaryCollapse ? 'on' : 'off'"
         :data-kt-app-sidebar-primary-flat="isAppSidebarPrimaryFlat ? 'on' : 'off'"
    >
      <div id="kt_app_sidebar" class="app-sidebar z-index-1">
        <aside-primary :is-super-admin="isSuperAdmin" :bread-crumb="breadCrumb" :collapse="isAppSidebarPrimaryCollapse" :show-sidebar-secondary="isAppSidebarSecondaryShow" />
      </div>
      <page-tabs v-show="!isOverview" />
      <template v-if="isOverview">
        <router-view />
      </template>
      <template v-else>
        <div id="kt_app_main" class="app-main flex-column flex-row-fluid pb-4 z-index-0 bg-gray-100">
          <breadcrumb class="ps-4 py-2 lh-1r" :bread-crumb="breadCrumb" />
          <RouterView v-slot="{ Component, route }">
            <KeepAlive :include="keepAlive.includes">
              <component :is="keepAlive.setComponentName(Component, route)" />
            </KeepAlive>
          </RouterView>
        </div>
      </template>
    </div>

<!--    <dashboard-modal />-->

    <modal-component />
  </div>
</template>

<script>
import AsideSecondary from './aside-secondary'
import AsidePrimary from './aside-primary'
import PageHeader from './page-header'
import Breadcrumb from './breadcrumb/index.vue'
import { PageTabs } from './page-tabs'
// 修饰器依赖
import { Component, Vue, Watch } from '@min/vue-compatible-decorator'
import { useProfileStore } from '@/stores/profile'
import { useBreadCrumbStore } from '@/stores/bread-crumb'
// #ifdef HAS_SUITE_DASHBOARD
import DashboardModal from '@/modular/dashboard/layout/modal'
// #endif
import { useMenuStore } from '@/stores/menu'
import { useTenantStore } from '@/stores/tenant'
import Event from '@/utils/event'
import ModalComponent from '@/components/layout/modal-component.vue'
import breadcrumb from '@/components/layout/breadcrumb'
import { getIncludes, KEEPALIVE_EVENT, removeCached, setCached, setComponentName } from '@/router/keep-alive'

export default
@Component({
  computed: {
    breadcrumb () {
      return breadcrumb
    }
  },
  components: {
    ModalComponent,
    // #ifdef HAS_SUITE_DASHBOARD
    DashboardModal,
    // #endif
    AsideSecondary,
    AsidePrimary,
    PageHeader,
    PageTabs,
    Breadcrumb
  }
})
class Layout extends Vue {
  keepAlive = {
    setComponentName: setComponentName,
    includes: getIncludes()
  }

  profile = {}
  tenant = {}
  menus = {}
  // ktToggle = null
  notification = null
  messages = []
  websocketTimer = null
  breadCrumb = []
  isAppSidebarSecondaryShow = false
  isAppSidebarPrimaryCollapse = false
  isAppSidebarPrimaryFlat = false

  get isOverview () {
    return this.$route.name === 'overview'
  }

  get computedStyle () {
    const styleObj = {}
    if (this.isOverview) {
      styleObj.marginTop = '48px'
    }
    return styleObj
  }

  get isSuperAdmin () {
    return useProfileStore().isSuperAdmin
  }

  @Watch('$route')
  watchRoute (value) {
    useBreadCrumbStore().initialized.run(() => {
      this.breadCrumb = useBreadCrumbStore().breadCrumb
    }, 'LAYOUT_INDEX_WATCH_ROUTE')
    useBreadCrumbStore().init(value, this.menus)
  }

  async created () {
    Event.on(KEEPALIVE_EVENT.REMOVE_INCLUDE, (condition, name) => {
      const index = this.keepAlive.includes.indexOf(name)
      if (index > -1) {
        this.keepAlive.includes.splice(index, 1)
        removeCached(name)
      }
    })
    Event.on(KEEPALIVE_EVENT.ADD_INCLUDE, (condition, name, fullPath) => {
      const index = this.keepAlive.includes.indexOf(name)
      if (index === -1) {
        this.keepAlive.includes.push(name)
      }
      setCached(name, { fullPath })
    })

    await useProfileStore().init()
  }

  onEventSidebarSecondaryShow () {
    this.isAppSidebarSecondaryShow = true
  }

  onEventSidebarSecondaryHide () {
    this.isAppSidebarSecondaryShow = false
  }

  onEventSidebarPrimaryToggle () {
    this.isAppSidebarPrimaryCollapse = !this.isAppSidebarPrimaryCollapse
  }

  onEventSidebarPrimaryFlatShow () {
    this.isAppSidebarPrimaryFlat = true
  }

  onEventSidebarPrimaryFlatHide () {
    this.isAppSidebarPrimaryFlat = false
  }

  async mounted () {
    Event.on('sidebar.secondary.show', this.onEventSidebarSecondaryShow)
    Event.on('sidebar.secondary.hide', this.onEventSidebarSecondaryHide)
    Event.on('sidebar.primary.collapse-toggle', this.onEventSidebarPrimaryToggle)
    Event.on('sidebar.primary.flat.show', this.onEventSidebarPrimaryFlatShow)
    Event.on('sidebar.primary.flat.hide', this.onEventSidebarPrimaryFlatHide)

    useMenuStore().initialized.run(() => {
      this.menus = this.parseMenus(useMenuStore().rawMenus)
      useBreadCrumbStore().initialized.run(() => {
        this.breadCrumb = useBreadCrumbStore().breadCrumb
      }, 'LAYOUT_INDEX_MOUNT')
      useBreadCrumbStore().init(this.$route, this.menus)
    }, 'LAYOUT_INDEX_MOUNT')
    await this.initProfile()
    // await this.checkNotification()
    // this.notificationWebsocket()
  }

  parseMenus (rawMenus) {
    const menus = {}
    for (const n in rawMenus) {
      menus[rawMenus[n].id] = rawMenus[n]
    }
    return menus
  }

  togglePrimaryCollapse () {
    this.isAppSidebarPrimaryCollapse = !this.isAppSidebarPrimaryCollapse
    this.isAppSidebarSecondaryShow = false
  }

  async initProfile () {
    useProfileStore().initialized.run(() => {
      this.profile = useProfileStore().data
    }, 'LAYOUT_INDEX_INIT_PROFILE')
    useTenantStore().initialized.run(() => {
      this.tenant = useTenantStore().data
    }, 'LAYOUT_INDEX_INIT_PROFILE')
  }

  destroyed () {
    window.clearInterval(this.notification)
    clearInterval(this.websocketTimer)
    Event.remove('sidebar.secondary.show', this.onEventSidebarSecondaryShow)
    Event.remove('sidebar.secondary.hide', this.onEventSidebarSecondaryHide)
    Event.remove('sidebar.primary.collapse-toggle', this.onEventSidebarPrimaryToggle)
    Event.remove('sidebar.primary.flat.show', this.onEventSidebarPrimaryFlatShow)
    Event.remove('sidebar.primary.flat.hide', this.onEventSidebarPrimaryFlatHide)

    Event.remove(KEEPALIVE_EVENT.REMOVE_INCLUDE)
    Event.remove(KEEPALIVE_EVENT.ADD_INCLUDE)
  }
}
</script>
