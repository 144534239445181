<template>
  <zero-form-static
    v-if="staticMode"
    :config="$_CONFIG"
    :data="$_VALUE"
    :show-button="showButton"
    @cancel="cancel"
  >
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </zero-form-static>
  <div v-else class="zero-form card bg-white full-height-content-sticky-footer" data-kt-app-footer-sticky-bottom="on">
    <div class="card-header border-0 bg-white">
      <h3 class="card-title fs-4 text-gray-800">
        <span id="title">{{ computedTitle }}</span></h3>
      <div v-if="!edit && $slots['action-buttons']" class="card-toolbar">
        <slot name="action-buttons" />
      </div>
    </div>
    <div class="card-body p-0">
      <form autocomplete="off" class="form-container">
        <slot :form="$_CONFIG" :configs="$_CONFIG" :data="$_VALUE">
          <template v-for="(blockConfig, index) in $_CONFIG" :key="`form-block-${blockConfig.key}`">
            <div class="mb-5 card-section py-0" v-if="!$_HIDDEN_STATUS[index]">
              <slot :name="`title(${blockConfig.key})`" :form="blockConfig" :config="blockConfig" :data="modelValue">
                <template v-if="blockConfig.title && blockConfig.title !== ''">
                  <div :id="`form-title-${blockConfig.key}`" class="card-header d-flex border-0 ps-0 pt-2 min-h-40px h-40px">
                    <h3 class="card-title text-gray-800 fs-5 m-0 me-2"><span class="bullet bullet-vertical rounded-0 bg-primary h-15px me-5"></span>{{ blockConfig.title }}</h3>
                    <b-badge class="badge-collapse align-self-center cursor-pointer" @click="$_BLOCK_TOGGLE[index] = !$_BLOCK_TOGGLE[index]">
                      <icon type="ri" :name="$_BLOCK_TOGGLE[index] ? 'arrow-down-s' : 'arrow-up-s'" />
                    </b-badge>
                    <div class="separator flex-fill align-self-center" />
                  </div>
                </template>
              </slot>
              <b-collapse :id="`block-collapse-${index}`" v-model="$_BLOCK_TOGGLE[index]">
                <div class="card-body">
                  <zero-form-block
                    v-if="!blockConfig.component && blockConfig.forms"
                    :ref="el => formBlocks[index] = el"
                    :for="index.toString()"
                    :config="blockConfig"
                    :incremental-mode="incrementalMode"
                    :full-data="$_VALUE"
                    v-model="$_VALUE"
                  >
                    <template v-for="(_, name) in $slots" #[name]="slotData">
                      <slot :name="name" v-bind="slotData" />
                    </template>
                  </zero-form-block>
                  <slot v-else :name="blockConfig.key" :config="blockConfig" :form="blockConfig" :data="$_VALUE">
                    <component
                      :ref="el => formBlocks[index] = el"
                      :for="blockConfig.key"
                      :is="blockConfig.component"
                      :config="blockConfig.config"
                      v-model="$_VALUE[blockConfig.key]"
                    />
                  </slot>
                </div>
              </b-collapse>
            </div>
          </template>
        </slot>
      </form>
      <div class="form-affix">
        <!--        <div class="timeline position-fixed">
                  <div v-for="(section) in sectionList" :key="`timeline-${section.title}`" class="timeline-item pb-5">
                    <div :class="['timeline-line', section.fulfilled ? 'border-primary' : '']" />
                    <div class="timeline-icon ps-3">
                      <span :class="['symbol-badge badge badge-circle badge-primary start-100 badge-xs', !section.fulfilled ? 'badge-outline' : '']"></span>
                    </div>
                    <div class="timeline-content h-60px lh-1 m-0">
                      <span :class="['fw-semibold', !section.fulfilled ? 'text-gray-700' : 'text-primary']">{{ section.title }}</span>
                    </div>
                  </div>
                </div>-->
      </div>
    </div>
    <div v-if="showButton" class="form-footer bg-white d-flex align-items-center justify-content-end">
      <slot name="actions" :submit="submit" :cancel="cancel" :validate="validate">
        <b-row>
          <b-col cols="12" class="d-flex justify-content-end px-6">
            <button v-if="showCancel" type="button" class="btn btn-lg btn-dark w-100px me-4" @click="cancel">
              取消
            </button>
            <button class="btn btn-lg btn-primary font-weight-bold" :class="showCancel ? 'w-100px' : 'w-200px'" @click="submit">
              提交
            </button>
          </b-col>
        </b-row>
      </slot>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  onMounted,
  ref,
  defineEmits,
  defineProps,
  defineExpose,
  nextTick,
  watch,
  onBeforeMount
} from 'vue'
// import { useRoute } from 'vue-router'
// import { useMenuStore } from '@/stores/menu'
import { useBreadCrumbStore } from '@/stores/bread-crumb'
import { isEmpty, display, typeOf, isArray, isEqual } from '../utils/common'
import { getComponent } from '@/components/zero-vue/components/internal-types'
import Event from '@/utils/event'
import { cancel as pageTabCancel } from '@/components/layout/page-tabs'

// const router = useRouter()
// const route = useRoute()

const emit = defineEmits(['submit', 'cancel', 'validate', 'update:modelValue'])
const props = defineProps({
  title: { type: String, default: '' },
  // form配置（主要为页面内表单的类型等信息）
  config: { type: Array },
  // 是否静态模式
  isStatic: { type: Boolean, default: false },
  static: { type: Boolean, default: false },
  // 是否显示底部按钮区域
  showButton: { type: Boolean, default: true },
  // 是否显示底部取消按钮
  showCancel: { type: Boolean, default: true },
  // 最后提交的数据是否为增量数据(修改模式下)
  incrementalMode: { default: false },
  // 传入需要显示的数据
  data: { type: Object, default: () => null },
  /**
   * (兼容 1.x)zero传值
   */
  // (兼容 1.x)form配置
  configs: { type: [Array, Object], default: () => {} },
  // (兼容 1.x)表单类型
  type: { type: String },
  // (兼容 1.x)是否可修改
  edit: { type: Boolean, default: true },
  // (兼容 1.x)是否是修改模式
  change: { default: false },
  // (兼容 1.x)用于v-model模式下的数据初始化判定
  bindMode: { type: Boolean, default: false },
  // (兼容 1.x)
  backFill: { type: Object },
  // (兼容 1.x) 提交的数据是否为增量数据
  increment: { default: false },
  onSubmit: {},
  onCancel: {},
  modelValue: { type: Object, default: () => { return {} } }
})

const $_ORIGIN_VALUE = ref({})
const $_CONFIG = ref([])
// 存储处理之后的数据
const $_BLOCK_TOGGLE = ref([])
const $_KEY_LIST = ref([])
const $_HIDDEN_STATUS = ref([])

const $_VALUE = ref(props.modelValue)
const $_EXPOSE_VALUE = ref(props.modelValue)

const staticMode = ref(props.static || props.isStatic || (props.change && !props.edit)) // 适配v1.0中静态判断
const incrementalMode = ref(props.incrementalMode || props.increment) // 适配 v1.x中的增量模式
const formBlocks = ref([])
const breadCrumb = ref([])
const titleBlock = ref({})

// const currentMenu = computed(() => breadCrumb.value[breadCrumb.value.length - 1])
// const backMenu = computed(() => useMenuStore().backMenu)
const computedTitle = computed(() => props.title && props.title !== '' ? props.title : (titleBlock.value?.meta?.title || ''))

function parseDisplayStatus (value) {
  // 处理显隐状态
  const status = []
  for (const n in $_KEY_LIST.value) {
    status[n] = !$_KEY_LIST.value[n].display(value ?? $_VALUE.value)
  }
  $_HIDDEN_STATUS.value = status
}

watch($_EXPOSE_VALUE, value => {
  // 监测向外暴露值，若暴露值变化，向内影响
  if (!isEqual($_VALUE.value, value)) {
    for (const i in $_VALUE.value) {
      $_VALUE.value[i] = value[i]
    }
    $_VALUE.value = { ...$_VALUE.value, ...value }
  }
}, { deep: true })

watch($_VALUE, value => {
  // 实现数据双向绑定
  if (!isEqual($_EXPOSE_VALUE.value, value)) {
    $_EXPOSE_VALUE.value = { ...$_EXPOSE_VALUE.value, ...value }
    emit('update:modelValue', value)
  }
}, { deep: true })

/* function parseDisplayValue (value) {
  const data = {}
  value = value ?? $_RAW.value
  for (const n in value) {
    if (!$_HIDDEN_STATUS.value[n]) {
      data[n] = value[n]
    }
  }
  return data
} */

function init () {
  if (!isEmpty(props.config)) {
    $_CONFIG.value = [...props.config]
  }
  const configs = [...$_CONFIG.value]
  const blockToggle = []
  const keyListRaw = []
  for (const key in configs) {
    if (configs[key].type || configs[key].component) {
      configs[key].componentRaw = configs[key].component ?? configs[key].type
      configs[key].component = getComponent(configs[key].componentRaw)
    }
    // 处理title位于子组件的情况
    if (isEmpty(configs[key].title) && configs[key].config && !isEmpty(configs[key].config.title)) {
      configs[key].title = configs[key].config.title
    }
    blockToggle[key] = true
    keyListRaw[key] = {
      display: display(configs[key].display)
    }
  }

  $_BLOCK_TOGGLE.value = blockToggle
  $_KEY_LIST.value = keyListRaw
}

/**
 * 兼容v1.x中back-fill填充内容
 */
watch(() => props.backFill, value => {
  // 增量模式缓存初始数据
  if (incrementalMode.value) {
    if (!isEmpty(value)) {
      $_ORIGIN_VALUE.value = { ...value }
    }
  }
  if (!isEmpty(value)) {
    $_VALUE.value = { ...value }
  }
})

watch($_VALUE, value => {
  // $_VALUE变化时验证display
  parseDisplayStatus(value)
}, { deep: true, immediate: true })

/* function goBack () {
  if (currentMenu.value?.backRouter && currentMenu.value?.backRouter !== '') {
    router.push(currentMenu.value.backRouter)
  } else if (backMenu.value) {
    router.push(backMenu.value)
  } else {
    const pathArr = route.path.split('/')
    if (pathArr.includes('change') || pathArr.includes('detail')) {
      pathArr.pop()
    }
    // 将pathArr最后一个值替换为list
    pathArr[pathArr.length - 1] = 'list'
    router.push(pathArr.join('/'))
  }
} */

/**
 * 初始化针对 v1.x的兼容性
 */
function updateCompatible () {
  // 适配v1.x中config
  if (isEmpty(props.config) && !isEmpty(props.configs)) {
    if (!isArray(props.configs)) {
      $_CONFIG.value = [{ ...props.configs }]
    } else {
      $_CONFIG.value = [...props.configs]
    }
  }
  /*  // 适配v1.x中表单处理模型 add change
    if (!props.change && props.edit) {
      $_VALUE.value = {}
    }
    if (props.change && !props.edit) {
      staticMode.value = true
    }

    // 适配 v1.x中的增量模式
    if (props.increment) {
      incrementalMode.value = true
    } */
}

// 提交按钮
function submit (callback = null) {
  try {
    // let submitData = parseDisplayValue($_VALUE.value)
    let submitData = { ...$_VALUE.value }
    console.log('提交数据: %O', submitData)
    const verifiedData = validate()

    if (verifiedData.status !== 0) {
      console.error('新增失败, 字段校验失败 %O', verifiedData)
      return
    }
    submitData = handleNoNeedData(submitData)
    // 处理增量模式数据
    if (incrementalMode.value) {
      submitData = getChangedData(submitData)
      if (Object.keys(submitData).length !== 0) {
        if (typeOf(callback) === 'function') {
          callback(submitData)
        }
        emit('submit', submitData)
      }
    } else {
      if (typeOf(callback) === 'function') {
        callback(submitData)
      }
      emit('submit', submitData)
    }
  } catch (e) {
    console.error(e)
  }
}

function getChangedData (data) {
  // 用于修改时的增量处理,只传输被修改的信息
  const changedList = {}
  for (const i in data) {
    if (typeOf(data[i]) === 'object' || ($_ORIGIN_VALUE.value[i] && data[i].toString() !== $_ORIGIN_VALUE.value[i].toString())) {
      changedList[i] = data[i]
    }
  }
  return changedList
}

function handleNoNeedData (info) {
  // 处理提交时的数据，删除update_time与update_user字段，避免后端接收后不对其更新
  if (info.update_user) delete info.update_user
  if (info.update_time) delete info.update_time
  return info
}

function validate () {
  const validationResult = {
    status: 0,
    message: '',
    data: []
  }
  for (const n in formBlocks.value) {
    if (formBlocks.value[n]?.validate) {
      const result = formBlocks.value[n].validate()
      validationResult.data[n] = result
      if (result.status && result.status === 2) {
        validationResult.status = 2
      } else if (result.status === undefined || result.status === 1) {
        validationResult.status = 1
      }
    }
  }
  emit('validate', validationResult)
  return validationResult
}

function cancel () {
  $_VALUE.value = {}
  Event.emit('cancel')
  // Event.emit(PAGE_TABS_EVENTS.CLOSE, 'default', { name: route.name })
  if (props.onCancel) {
    emit('cancel')
  }
  pageTabCancel()
}

onMounted(() => {
  useBreadCrumbStore().initialized.run(() => {
    nextTick(() => {
      breadCrumb.value = useBreadCrumbStore().breadCrumb
      titleBlock.value = [].concat(breadCrumb.value).pop()
    })
    // }, 'ZERO_FORM_INIT_TITLE')
  })
})

onBeforeMount(() => {
  updateCompatible()
  init()
})

const exposeValue = computed({
  get: () => $_EXPOSE_VALUE.value,
  set: value => { $_EXPOSE_VALUE.value = value }
})

defineExpose({ value: exposeValue, info: exposeValue, submit, cancel })
</script>
