import { Restful, RestfulRequest, IntelliApi } from '@/services/intelli-api'

/**
/**
 * IntelliApi Class
 * @class file
  **/
@Restful({
  name: 'file',
  path: '/system/file'
})
class FileService extends IntelliApi {
  @RestfulRequest({
    method: 'POST',
    path: '/upload',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    parameters: ['data']
  })
  uploadFile (info) {}

  @RestfulRequest({
    method: 'GET',
    path: '/download/#{id}',
    parameters: [{ id: '-' }]
  })
  downloadFile (id) {}

  @RestfulRequest({
    method: 'GET',
    path: '/#{id}',
    parameters: [{ id: '-' }]
  })
  // 获取文件详情
  getFileDetail (id) {}

  @RestfulRequest({
    method: 'POST',
    path: '/uploadReturnFullPath',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    parameters: ['data']
  })
  // 上传返回文件全路径
  uploadFilePath (info) {}
}

export default new FileService()
