<template>
  <div class="zero-table d-flex flex-column">
    <div class="card flex-grow-1">
      <div id="kt_app_table_header" v-if="title && title !== ''" class="card-header">
        <h4 class="card-title">{{ title }}</h4>
      </div>
      <ZeroSearch id="kt_app_table_search" v-if="method !== 'pure'" ref="zeroSearch" v-model="filterInfo" v-model:collapse="isSearchCollapse"
                  :class="title && title !== '' ? '' : 'pt-4'"
                  :columns="searchColumns" :filter="config.filter || {}"
                  :handleSearch="handleSearch" :no-reset="noSearchReset" :responsive="true" @search="initData('', 'new')">
        <template v-for="(filter, i) in config.filter" #[`${i}-filter`]="{ data }">
          <slot :name="i + '-filter'" :data="data" :class-list="filter.class" :configs="filter.configs">
          </slot>
        </template>
      </ZeroSearch>
      <div class="separator" />
      <div id="kt_app_table_toolbar" class="card-header">
        <div class="card-title">
          <TableConfig v-model:column="tableColumnConfig" :column-list="config?.table?.fields" :table-name="$route.name" />
        </div>
        <div v-if="hasActionButtons" class="card-toolbar">
          <slot v-if="$slots['left-action-buttons']" name="left-action-buttons" />
          <template v-if="defaultSelect">
            <b-button class="min-w-80px" v-if="!selectMode" variant="light-primary" @click="openSelectMode">
              批量操作
            </b-button>
            <template v-else>
              <slot name="select-actions" :selectedIdList="selectedIdList" :selectedItemList="selectedItemList" :selectMode="selectMode" />
              <b-button class="w-80px" variant="light-primary" @click="closeSelectMode">
                返回
              </b-button>
            </template>
          </template>
          <template v-if="!selectMode">
            <template v-if="defaultImport">
              <b-button class="btn-outline w-80px ms-2" variant="outline-primary" @click="openFileImportModal">
                导入
              </b-button>
            </template>
            <template v-if="defaultExport">
              <b-button class="btn-outline w-80px ms-2" variant="outline-primary" @click="download">
                导出
              </b-button>
            </template>
            <template v-if="defaultAdd">
              <router-link :to="changePathToAdd()" class="btn btn-primary min-w-80px ms-2">
                <icon type="ri" name="add" mode="fill" class="p-0" />{{ defaultAddText }}
              </router-link>
            </template>
          </template>
          <span v-if="((defaultExport || defaultImport || defaultAdd || defaultSelect) && $slots['action-buttons'])" class="bullet h-20px w-1px bg-secondary mx-4"></span>
          <slot name="action-buttons" :selectedIdList="selectedIdList" :selectedItemList="selectedItemList" :selectMode="selectMode" />
        </div>
      </div>
      <div ref="tableWrapper" class="card-body pt-0 pb-4 px-4 z-index-0">
        <slot :config="config">
          <vxe-table ref="tableInstance"
                     :data="$_VALUE"
                     :height="tableHeight"
                     :column-config="vxeColumnConfig"
                     border="inner"
                     @checkbox-change="onSelectChange"
                     class="border-top border-gray-200"
          >
            <vxe-column v-if="selectMode" type="checkbox" width="50" fixed="left" />
            <vxe-column type="seq" title="序号" width="64" fixed="left" align="center" :col-id="$_INDEX_KEY">
              <template v-if="$slots[$_INDEX_KEY] || $slots[`cell(${$_INDEX_KEY})`]" v-slot="slotParams">
                <slot :name="$_INDEX_KEY" :for="$_INDEX_KEY" :data="{ item: slotParams.row, value: slotParams.row[$_INDEX_KEY], index: slotParams.$rowIndex }" :form="{ key: $_INDEX_KEY}" />
              </template>
            </vxe-column>
            <template :key="`table-column-${column.key}`" v-for="column in computedFields">
              <vxe-column v-if="['do', 'action'].includes(column.key)" title="操作" :width="cellOptionWidth" fixed="right" col-id="do">
                <template v-slot="slotParams">
                  <div class="w-100" :ref="el => cellOptionWrapper[slotParams.$rowIndex] = el">
                    <slot name="do" :value="slotParams.row[column.key]" :data="{ item: slotParams.row, value: slotParams.row[column.key] }" :item="slotParams.row" :index="slotParams.$rowIndex" />
                  </div>
                </template>
              </vxe-column>
              <vxe-column v-else-if="column.key === 'status'" width="120" :title="column.label ?? column.key" field="status" :status-data="statusData = {}" col-id="status">
                <template v-if="!column.format && $slots.status" v-slot="slotParams">
                  <slot name="status" :value="slotParams.row[column.key]" :data="{ item: slotParams.row, value: slotParams.row[column.key] }" :item="slotParams.row" :index="slotParams.$rowIndex" />
                </template>
                <template v-else-if="!column.format && $slots['cell(status)']" v-slot="slotParams">
                  <slot name="cell(status)" :value="slotParams.row[column.key]" :data="{ item: slotParams.row, value: slotParams.row[column.key] }" :item="slotParams.row" :index="slotParams.$rowIndex" />
                </template>
                <template v-else v-slot="slotParams">
                  <zero-tag :status-data="statusData[slotParams.$rowIndex] = translateStatus(column, slotParams.row, slotParams.row[column.key], column)" :variant="statusData[slotParams.$rowIndex].variant">{{ statusData[slotParams.$rowIndex].text }}</zero-tag>
                </template>
              </vxe-column>
              <vxe-column v-else-if="column.key !== $_INDEX_KEY"
                          min-width="150"
                          :width="column.width ?? undefined"
                          show-overflow
                          :sortable="!!column.sort"
                          :formatter="parseFormatter(column)"
                          :fixed="column.fixed ?? undefined"
                          :title="column.label ?? column.key"
                          :field="column.key"
                          :col-id="column.key"
              >
                <template v-if="$slots[column.key] || $slots[`cell(${column.key})`]" v-slot="slotParams">
                  <slot :name="column.key" :value="slotParams.row[column.key]" :data="{ item: slotParams.row, value: slotParams.row[column.key] }" :item="slotParams.row" :index="slotParams.$rowIndex" />
                </template>
              </vxe-column>
            </template>
          </vxe-table>
          <!--          <div v-if="items === null || (items && items.length === 0)" class="text-center mt-6">
                      <p class="text-muted fs-6">
                        {{ noItemLabel }}
                      </p>
                    </div>-->
        </slot>
      </div>
      <div class="separator z-index-3" />
      <div id="kt_app_table_footer" class="card-footer d-flex justify-content-between px-6 py-0 bg-white border-0 z-index-3">
        <Pagination
          v-if="!isEmpty($_VALUE) && !hidePagination"
          v-model:current="pagination.current"
          v-model:total="pagination.total"
          v-model:size="pagination.size"
          :disabled="loading"
        ></Pagination>
      </div>
    </div>
  </div>
  <b-modal v-model="fileImportModalShow" ref="modal-import" size="md" id="modal-import" title="文件导入" no-close-on-backdrop centered>
    <div class="form">
      <div class="form-group row">
        <label class="col-3">导入文件上传</label>
        <div class="col-9">
          <button type="button" @click="selectFile" class="btn btn-light-primary font-weight-bold btn-sm">{{ file ? file.name : '请选择需要导入的文件' }}</button>
          <input type="file" ref="file-import-input" style="display: none" accept=".xlsx" @change="getFile"/>
          <div class="form-text text-muted mt-3">请根据导入模板进行信息整理后上传.</div>
          <a v-if="importTemplateFile" :href="importTemplateFile" class="form-text mt-3">模版文件下载</a>
        </div>
      </div>
    </div>
    <template #footer>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button variant="primary" class="w-100px me-2" @click.prevent="upload">确定</b-button>
          <b-button variant="dark" class="w-90px" @click.prevent="closeFileImportModal">取消</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script setup>
import {
  defineEmits,
  onMounted,
  ref,
  watch,
  defineProps,
  defineExpose,
  onBeforeMount,
  onBeforeUnmount,
  nextTick,
  onUnmounted
} from 'vue'
import ZeroSearch from './search.vue'
import { hasAuth } from '../utils/auth'
import CommonService from '@/services/common'
import { usePermissionStore } from '@/stores/permissions'
import Sortable from 'sortablejs'
import Pagination from './pagination'
import TableConfig from './table-config'
import event from '@/utils/event'
import { useRoute } from 'vue-router'
import { isEmpty, typeOf } from '../utils/common'
import DashboardService from '@/services/dashboard'
import { useMenuStore } from '@/stores/menu'

const vxeColumnConfig = {
  resizable: true
}

const route = useRoute()

const props = defineProps({
  // sql id
  id: { type: String, default: '' },
  // 表格配置，包含filter、table两个组件配置
  config: Object,
  // 表格模式，默认standard，pure为纯表格，component为组件
  method: { type: String, default: 'standard' },
  // 是否显示序号
  index: { type: Boolean, default: true },
  // 是否显示搜索重置按钮
  noSearchReset: { type: Boolean, default: false },
  // table是否显示hover效果
  hover: { type: Boolean, default: true },
  // 是否显示操作按钮
  hasActionButtons: { type: Boolean, default: true },
  // 表格标题内容
  title: { type: String },
  // 默认表格每页item数量
  size: { type: [Number, String], default: 20 },
  // search组件中一行显示的搜索项数量
  searchColumns: { type: [Number, String], default: 4 },
  // 当表格内无数据时content展示的内容
  noItemLabel: { type: String, default: '暂无数据' },
  // 是否隐藏分页
  hidePagination: { type: Boolean, default: true },
  // 是否显示table-footer
  tableFooter: { type: Boolean, default: false },
  // 是否开启批量操作
  defaultSelect: { type: Boolean, default: false },
  // 是否开启导入
  defaultImport: { type: Boolean, default: false },
  // 是否开启导出
  defaultExport: { type: Boolean, default: false },
  // 前端导出文件名字
  exportName: { type: String, default: '' },
  // 是否开启新增
  defaultAdd: { type: Boolean, default: false },
  // 新增按钮文字
  defaultAddText: { type: String, default: '新增' },
  // 新增页面跳转路由
  addPath: { type: String, default: '' },
  // 导入模版文件
  importTemplateFile: { type: String, default: '' },
  // 获取表格内容的service，会默认调用其getList方法
  asyncService: Object,
  // 处理get前的param参数
  handleGet: Function,
  // 处理search前的filter参数
  handleSearch: Function,
  // 处理接口获取的的result数据
  handleResult: Function
})

const emits = defineEmits(['upload', 'download'])

const tableWrapper = ref()
const tableInstance = ref()
const tableHeight = ref(400)
// 定义是否在请求数据中
const loading = ref(false)
// 是否搜索折叠
const isSearchCollapse = ref(true)
// 定义table列配置
const fields = ref([])
// 定义table配置
const tableConfig = ref({})
// 定义table列配置
const tableColumnConfig = ref([])
// 定义表单内容项
const $_VALUE = ref([])
// 定义选中的id列表
const selectedIdList = ref([])
// 定义选中的item列表
const selectedItemList = ref([])
// 是否开启批量模式
const selectMode = ref(false)
// 分页配置
const pagination = ref({
  total: 10,
  size: props.size,
  pages: 2,
  current: 1
})

const $_INDEX_KEY = ref('index')

const defaultMenu = ref()
const computedFields = ref([])
const zeroSearch = ref(null)
const filterInfo = ref({})
const cellOptionWrapper = ref([])
const cellOptionWidth = ref(100)

/* const computedTitle = computed(() => {
  let title = props.title
  if (isEmpty(title)) {
    title = defaultMenu.value?.meta?.title || ''
  }
  return title
}) */

watch(fields, fields => {
  event.emit('ZERO_TABLE.FIELD_CHANGED', 'FIELD_CHANGED')
})

watch(tableColumnConfig, tableColumnConfig => {
  event.emit('ZERO_TABLE.FIELD_CHANGED', 'CONFIG_CHANGED')
})

event.on('ZERO_TABLE.FIELD_CHANGED', ['CONFIG_CHANGED', 'FIELD_CHANGED'], () => {
  const resultFields = []
  const fieldsClone = [...fields.value]
  const tableConfig = [...tableColumnConfig.value]

  for (const n in tableConfig) {
    const index = fieldsClone.findIndex(item => item.key === tableConfig[n].key)
    if (index !== -1) {
      const config = fieldsClone[index]
      fieldsClone.splice(index, 1)
      switch (tableConfig[n].width) {
        case 'inherit': break
        case 'auto':
          config.width = 'auto'
          break
        default: {
          const width = parseInt(tableConfig[n].width)
          if (!isNaN(width)) {
            config.width = width.toString() + 'px'
          }
          break
        }
      }
      resultFields.push(config)
    }
  }

  for (const k in fields.value) {
    if (fields.value[k].key === 'index') {
      resultFields.unshift(fields.value[k])
      break
    }
  }
  computedFields.value = resultFields
})

/* watch(computedFields, value => {
  console.log('refresh')
  console.log(value)
  tableInstance.value.loadColumn(value)
}) */

function parseFormatter (field) {
  return ({ cellValue, row, column }) => {
    return translateValue(cellValue, row, column, field)
  }
}

const defaultStatus = {
  0: { variant: 'light-primary', text: '启用' },
  1: { variant: 'light-danger', text: '停用' }
}

function translateStatus (field, data) {
  if (field.format) {
    const value = field.format(data[field.key], data)
    if (typeOf(value) !== 'object') {
      return {
        variant: defaultStatus[data[field.key]]?.variant,
        text: value
      }
    } else {
      return value
    }
  } else {
    return {
      variant: defaultStatus[data[field.key]]?.variant,
      text: defaultStatus[data[field.key]] ? defaultStatus[data[field.key]].text : data[field.key]
    }
  }
}

function translateValue (data, row, column, field) {
  let value = ''
  if (field.format) {
    value = row[field.key] !== undefined ? field.format(row[field.key], row) : undefined
  } else {
    value = row[field.key]
  }
  return value ? value.toString() : '-'
}

let sortable

function initSortable () {
  const options = {
    animation: 200,
    delay: 30,
    draggable: '.vxe-header--column',
    dataIdAttr: 'colid',
    // 被禁止拖动的元素,第二个元素不允许被拖动
    filter: (event, item) => {
      console.log('item', item.className, item)
      if (item.className.indexOf('col--fixed') !== -1) {
        return true
      }
      return false
    },
    // 拖动结束
    onEnd: function (evt) {
      console.log(evt)
      const { fullColumn } = tableInstance.value.getTableColumn()
      console.log('columns', fullColumn)
      // 获取拖动后的排序
      const arr = sortable.toArray()
      console.log(arr)
      const fields = []
      for (const key of arr) {
        for (const n in fullColumn) {
          if (key === fullColumn[n].id) {
            fields.push(fullColumn[n])
            delete fullColumn[n]
            break
          }
        }
      }
      tableInstance.value.loadColumn(fields)
    }
  }

  if (tableInstance.value?.$el) {
    sortable = Sortable.create(tableInstance.value.$el.querySelectorAll('.vxe-table--header .vxe-header--row')[0], options)
  }
}

/* function columnDrop () {
  sortable2 = Sortable.create(tableInstance.value.$el.querySelector('.body--wrapper>.vxe-table--header .vxe-header--row'), {
    handle: '.vxe-header--column',
    onEnd: (sortableEvent) => {
      const targetThElem = sortableEvent.item
      const newIndex = sortableEvent.newIndex
      const oldIndex = sortableEvent.oldIndex
      const { fullColumn, tableColumn } = tableInstance.value.getTableColumn()
      const wrapperElem = targetThElem.parentNode
      const newColumn = fullColumn[newIndex]
      if (newColumn.fixed) {
        // 错误的移动
        const oldThElem = wrapperElem.children[oldIndex]
        if (newIndex > oldIndex) {
          wrapperElem.insertBefore(targetThElem, oldThElem)
        } else {
          wrapperElem.insertBefore(targetThElem, oldThElem ? oldThElem.nextElementSibling : oldThElem)
        }
        // VXETable.modal.message({ content: '固定列不允许拖动！', status: 'error' })
        return
      }
      // 获取列索引 columnIndex > fullColumn
      const oldColumnIndex = tableInstance.value.getColumnIndex(tableColumn[oldIndex])
      const newColumnIndex = tableInstance.value.getColumnIndex(tableColumn[newIndex])
      // 移动到目标列
      const currRow = fullColumn.splice(oldColumnIndex, 1)[0]
      fullColumn.splice(newColumnIndex, 0, currRow)
      tableInstance.value.loadColumn(fullColumn)
    }
  })
} */

onUnmounted(() => {
  if (sortable) {
    sortable.destroy()
  }
})

const initTable = async () => {
  // 过滤手动显隐数据列
  const fieldsConfig = await getColumnItems(props.config?.table?.fields || [])
  // 初始化表格基础项
  let fieldsHandle = []
  for (const item of fieldsConfig) {
    if (item.status) {
      fieldsHandle.push(item)
    }
  }
  // 非超管用户进行权限过滤
  fieldsHandle = fieldsHandle.filter((val) => {
    return hasAuth(val.auth)
  })

  fieldsHandle.forEach(item => {
    if (item.key === 'do') {
      item.thClass = 'text-start'
      item.tdClass = 'text-start'
      item.sticky = 'end'
    }
    if (!item.tdClass) {
      item.tdClass = 'text-gray-700'
    } else {
      item.tdClass += ' text-gray-700'
    }
  })

  fields.value = fieldsHandle
  tableConfig.value = props.config?.table?.tableConfig || {}

  // columnDrop()
}

const initData = async (orderParam, type) => {
  if (type && type === 'new') {
    pagination.value.current = 1
  }
  if (props.id) {
    let filterInfoBack = Object.assign({}, filterInfo.value)
    if (filterInfoBack) {
      for (const i in filterInfoBack) {
        if (filterInfoBack[i] === null || filterInfoBack[i] === undefined || filterInfoBack[i] === '') {
          delete filterInfoBack[i]
        }
      }
      if (props.handleSearch && Object.keys(filterInfoBack).length > 0) filterInfoBack = props.handleSearch(filterInfoBack)
    }
    // let param = { page: pagination.value.current, size: pagination.value.size, ...orderParam, ...filterInfoBack }
    let param = { ...orderParam, ...filterInfoBack }
    param = props.handleGet ? props.handleGet(param) : param
    const data = await DashboardService.getSqlData(props.id, param)

    if (typeOf(data) === 'array') {
      if (props.handleResult) {
        $_VALUE.value = props.handleResult(data)
        return
      }
      $_VALUE.value = data || []
    } else {
      if (data.records && props.handleResult) {
        data.records = props.handleResult(data.records)
      }
      $_VALUE.value = data?.records || []
      pagination.value = data.pagination || pagination.value
    }
  }
}

/* const sortData = async (sort, order) => {
  const orderParam = { sort, order }
  loading.value = true
  await initData(orderParam)
  loading.value = false
} */

const getColumnItems = async (configFields) => {
  const tableName = route.name
  const config = await CommonService.getColumnConfig(tableName)
  const fields = []

  const tmpMap = config.column ?? []
  let lastIndex = 0

  for (const n in configFields) {
    const index = tmpMap.findIndex(item => item.column_key === configFields[n].key)
    if (index !== -1) {
      lastIndex = index
    } else {
      if (!tmpMap[lastIndex]) {
        tmpMap[lastIndex] = []
      } else if (!tmpMap[lastIndex].length) {
        tmpMap[lastIndex] = [tmpMap[lastIndex]]
      }
      tmpMap[lastIndex].push(Object.assign({}, {
        status: config[configFields[n].key] !== 'hide',
        width: ''
      }, configFields[n]))
    }
  }

  for (const k in tmpMap) {
    if (tmpMap[k].length && tmpMap[k].length > 0) {
      fields.push(...tmpMap[k])
    } else {
      fields.push(tmpMap[k])
    }
  }
  return fields
}

// 监听分页变化
watch(() => [pagination.value.current, pagination.value.size], async (newVal, oldValue) => {
  if (newVal[0] !== oldValue[0] || newVal[1] !== oldValue[1]) {
    // 同时监测current和size
    await initData()
    loading.value = false
  }
}, { deep: true })

/**
 * @name reload
 * @description 重新加载数据
 * @return void
 */
const reload = () => {
  fields.value = []
  tableConfig.value = {}
  $_VALUE.value = []

  pagination.value = {
    total: 10,
    size: 20,
    pages: 2,
    current: 1
  }

  initTable()
  initData()
}

let file = ''
// 是否打开文件导入模态框
const fileImportModalShow = ref(false)

/**
 * @name upload
 * @description 上传文件
 * @return void
 */
const upload = async () => {
  // 上传文件
  const formData = new FormData()
  formData.append('file', file)
  // 触发上传事件
  emits('upload', formData)
  // 关闭模态框
  fileImportModalShow.value = false
}

/**
 * @name download
 * @description 下载文件
 * @return void
 */
const download = () => {
  // 包含文件名字段走前端导出,否则走后端导出
  if (props.exportName) {
    tableInstance.value.exportData({
      type: 'xlsx',
      filename: props.exportName
    })
  } else {
    emits('download')
  }
}

// 用于文件选择的type为file的input
const fileImportInput = ref()

/**
 * @name selectFile
 * @description 选择文件
 * @return void
 */
const selectFile = () => {
  // 选择文件
  fileImportInput.value?.click()
}

/**
 * @name getFile
 * @description 获取文件
 * @param e input事件
 * @return void
 */
const getFile = (e) => {
  const aElement = e.target
  file = aElement.files?.[0] || ''
}

/**
 * @name openFileImportModal
 * @description 打开文件导入模态框
 * @return void
 */
const openFileImportModal = () => {
  // 打开文件导入模态框
  fileImportModalShow.value = true
}

const closeFileImportModal = () => {
  // 关闭文件导入模态框
  fileImportModalShow.value = false
}

/**
 * @name changePathToAdd
 * @description 新增地址转换
 * @description 用于新增按钮的跳转
 * @return string 新增地址
 */
const changePathToAdd = () => {
  // 新增地址转换
  return route.path.replace(/\/\w+$/, '/add')
}

/**
 * @name openSelectMode
 * @description 打开批量操作模式
 * @description 增加选择列
 */
const openSelectMode = () => {
  // 打开批量操作模式
  selectMode.value = true
  // 增加选择列
  computedFields.value.unshift({ key: 'selected', stickyColumn: true, isRowHeader: true, label: '选择', thClass: 'w-50px fw-bold fs-6 text-gray-1000' })
  // 清空选择列表
  selectedIdList.value = []
  selectedItemList.value = []
  tableInstance.value.refreshColumn()
}

/**
 * @name closeSelectMode
 * @description 关闭批量操作模式
 * @description 增加选择列
 */
const closeSelectMode = () => {
  // 关闭批量操作模式
  selectMode.value = false
  // 去除选择列
  computedFields.value.shift()
  // 清空选择列表
  selectedIdList.value = []
  selectedItemList.value = []
  tableInstance.value.refreshColumn()
}

function onSelectChange () {
  const records = tableInstance.value.getCheckboxRecords()
  const idList = []
  const itemList = []
  for (const n in records) {
    idList.push(records[n].id)
    itemList.push(records[n])
  }
  selectedIdList.value = idList
  selectedItemList.value = itemList
}

function debounce (func, delay) {
  let timeoutId
  return () => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(func, delay)
  }
}

function parseIndexColumn () {
  for (const n in computedFields.value) {
    if (computedFields.value[n].key === 'index' || computedFields.value[n].type === 'index') {
      $_INDEX_KEY.value = computedFields.value[n].key
      return
    }
  }
}

onBeforeMount(() => {
  parseIndexColumn()
  window.addEventListener('resize', tableHeightListener())
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', tableHeightListener())
})

function tableHeightListener () {
  return debounce(() => {
    if (tableWrapper.value) {
      tableHeight.value = getTableHeight()
    }
  }, 10)
}

watch(cellOptionWrapper, value => {
  const minWidth = 100
  let width = 0
  for (const i in value) {
    const options = value[i].querySelectorAll('.cell-option')
    let currentWidth = 0
    for (const n in options) {
      if (options[n].clientWidth) {
        currentWidth += options[n].clientWidth + 12
      }
    }
    currentWidth += 32
    width = (currentWidth > width ? currentWidth : width)
  }
  cellOptionWidth.value = (minWidth > width ? minWidth : width)
  // tableInstance.value.refreshColumn()
}, { deep: true, once: true })

function getTableHeight () {
  const bodyHeight = document.body.clientHeight
  const heights = {
    pageHeader: document.querySelector('#kt_app_header')?.clientHeight ?? 0,
    breadcrumb: 32,
    tableHeader: document.querySelector('#kt_app_table_header')?.clientHeight ?? 0,
    search: document.querySelector('#kt_app_table_search')?.clientHeight ?? 0,
    searchPadding: document.querySelector('#kt_app_table_search') ? 16 : 0,
    tableToolbar: document.querySelector('#kt_app_table_toolbar')?.clientHeight ?? 0,
    tableFooter: 48,
    // bottom: 16,
    separator: 4
  }
  let widgetHeight = 0
  for (const n in heights) {
    widgetHeight += heights[n]
  }
  return bodyHeight - widgetHeight
}

watch(isSearchCollapse, async value => {
  await nextTick()
  tableHeight.value = getTableHeight()
})

onMounted(() => {
  tableHeight.value = getTableHeight()
  if (props.type === 'component') {
    initTable()
    initData()
  } else {
    // 初始化
    useMenuStore().initialized.run(async () => {
      defaultMenu.value = useMenuStore().getMenuByName(route.name)
      await nextTick()
      tableHeight.value = getTableHeight()
    }, 'ZERO_SQL_TABLE_TITLE_INITIALIZED')
    usePermissionStore().initialized.run(() => {
      initTable()
      initData()
      initSortable()
      changePathToAdd()
      // eslint-disable-next-line no-undef
      // KTMenu.createInstances()
    }, 'ZERO_SQL_TABLE_PERMISSIONS_INITIALIZED')
  }
})

defineExpose({ reload, initData, fields: computedFields })
</script>
