import { Restful, RestfulRequest, IntelliApi } from '@/services/intelli-api'

/**
 * IntelliApi Class
 * @class tenant
  **/
@Restful({
  name: 'tenant',
  path: 'system/tenant'
})
class TenantService extends IntelliApi {
  @RestfulRequest({
    method: 'GET',
    path: '?scope=current'
  })
  getCurrentTenant () {}

  @RestfulRequest({
    method: 'GET',
    basepath: 'system/tenant-menu/#{id}',
    parameters: [{ id: '-' }]
  })
  getMenuListByTenant (id) {}

  @RestfulRequest({
    method: 'PUT',
    basepath: 'system/tenant-menu/#{id}',
    parameters: [{ id: '-' }, 'data']
  })
  addMenuListByTenant (id, data) {}

  async getTenantList () {
    try {
      const list = []
      const data = (await this.getList({ page: 1, size: 1000 })).records
      for (const i in data) {
        if (data[i].status !== undefined && data[i].status === 0) {
          list.push(
            { id: data[i].id, label: data[i].name }
          )
        }
      }
      return list
    } catch (e) {
      console.log(e)
    }
  }
}

export default new TenantService()
