/*
 * @Author: xuzhitao
 * @Date: 2023-01-04 10:26:57
 * @LastEditors: xuzhitao
 * @LastEditTime: 2023-01-04 10:27:14
 * @FilePath: /mirrors.in-mes.com-ui/src/stores/index.ts
 */
import { createPinia } from 'pinia'

const store = createPinia()

export function setupStore (app) {
  app.use(store)
}

export { store }
