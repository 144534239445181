import { Options, Vue, Emit, Inject, Model, Prop, Provide, Ref, Watch, mixins } from 'vue-property-decorator'
import { nextTick } from 'vue'
// import { createDecorator } from 'vue-class-component'

function Component (options) {
  return Options(options || {})
}

/* function Event (options) {
  return (target, name, descriptor) => {
    createDecorator((componentsOptions, key) => {
    })
  }
} */

const Mixins = mixins

export {
  Component,
  Options,
  Vue,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Ref,
  Watch,
  mixins,
  Mixins,
  nextTick
}
