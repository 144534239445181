<template>
  <span :class="computedClass">
    <slot />
  </span>
</template>
<script setup>
import { defineProps, computed } from 'vue'
import { isEmpty } from '../utils/common'

const props = defineProps({
  variant: { type: String }
})

const computedClass = computed(() => {
  const classObject = ['badge', 'badge-pill']
  if (!isEmpty(props.variant)) {
    classObject.push(`badge-${props.variant}`)
  } else {
    classObject.push('badge-secondary')
  }
  return classObject
})
</script>
