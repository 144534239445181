export default [
  {
    path: '/telemetry/type/list',
    name: 'telemetry-type-list',
    component: () => import('../pages/type/list')
  },
  {
    path: '/telemetry/type/add',
    name: 'telemetry-type-add',
    component: () => import('../pages/type/add')
  },
  {
    path: '/telemetry/type/change/:id',
    name: 'telemetry-type-change',
    component: () => import('../pages/type/change')
  },
  {
    path: '/telemetry/type/detail/:id',
    name: 'telemetry-type-detail',
    component: () => import('../pages/type/detail')
  },
  {
    path: '/telemetry/entity/list',
    name: 'telemetry-entity-list',
    component: () => import('../pages/entity/list')
  },
  {
    path: '/telemetry/entity/add',
    name: 'telemetry-entity-add',
    component: () => import('../pages/entity/add')
  },
  {
    path: '/telemetry/entity/change/:id',
    name: 'telemetry-entity-change',
    component: () => import('../pages/entity/change')
  },
  {
    path: '/telemetry/entity/detail/:id',
    name: 'telemetry-entity-detail',
    component: () => import('../pages/entity/detail')
  },
  {
    path: '/telemetry/entity/relation/:id',
    name: 'telemetry-entity-relation',
    component: () => import('../pages/entity/relation')
  },
  {
    path: '/telemetry/rule-engine/list',
    name: 'telemetry-rule-engine-list',
    component: () => import('../pages/rule-engine/list')
  },
  {
    path: '/telemetry/rule-engine/config/:id',
    name: 'telemetry-rule-engine-config',
    component: () => import('../pages/rule-engine/config')
  },
  {
    path: '/telemetry/live',
    name: 'telemetry-live',
    component: () => import('../pages/live/index')
  },
  {
    path: '/telemetry/gateway/list',
    name: 'telemetry-gateway-list',
    component: () => import('../pages/gateway/list')
  },
  {
    path: '/telemetry/gateway/add',
    name: 'telemetry-gateway-add',
    component: () => import('../pages/gateway/add')
  },
  {
    path: '/telemetry/gateway/change/:id',
    name: 'telemetry-gateway-change',
    component: () => import('../pages/gateway/change')
  },
  {
    path: '/telemetry/gateway/detail/:id',
    name: 'telemetry-gateway-detail',
    component: () => import('../pages/gateway/detail')
  },
  {
    path: '/telemetry/gateway/protocol/:id',
    name: 'telemetry-gateway-protocol',
    component: () => import('../pages/gateway/protocol')
  },
  {
    path: '/telemetry/connector/add',
    name: 'telemetry-connector-add',
    component: () => import('../pages/connector/add')
  },
  {
    path: '/telemetry/connector/change/:id',
    name: 'telemetry-connector-change',
    component: () => import('../pages/connector/change')
  },
  {
    path: '/telemetry/connector/list/:gatewayId?',
    name: 'telemetry-connector-list',
    component: () => import('../pages/connector/list')
  },
  {
    path: '/telemetry/connector/detail/:id',
    name: 'telemetry-connector-detail',
    component: () => import('../pages/connector/detail')
  },
  {
    path: '/telemetry/relation/list',
    name: 'telemetry-relation-list',
    component: () => import('../pages/relation/list')
  },
  {
    path: '/telemetry/cpk-params/list',
    name: 'telemetry-cpk-params-list',
    component: () => import('../pages/cpk/params/list')
  },
  {
    path: '/telemetry/cpk-params/add',
    name: 'telemetry-cpk-params-add',
    component: () => import('../pages/cpk/params/add')
  },
  {
    path: '/telemetry/cpk-params/change/:id',
    name: 'telemetry-cpk-params-change',
    component: () => import('../pages/cpk/params/change')
  },
  {
    path: '/telemetry/cpk-params/detail/:id',
    name: 'telemetry-cpk-params-detail',
    component: () => import('../pages/cpk/params/detail')
  },
  {
    path: '/telemetry/cpk-compute',
    name: 'telemetry-cpk-compute',
    component: () => import('../pages/cpk/compute')
  },
  {
    path: '/telemetry/cpk-records/list',
    name: 'telemetry-cpk-records-list',
    component: () => import('../pages/cpk/records/list')
  },
  {
    path: '/telemetry/cpk-records/detail/:id',
    name: 'telemetry-cpk-records-detail',
    component: () => import('../pages/cpk/records/detail')
  },
  {
    path: '/telemetry/zone/list',
    name: 'telemetry-zone-list',
    component: () => import('../pages/zone/list')
  },
  {
    path: '/telemetry/zone/add',
    name: 'telemetry-zone-add',
    component: () => import('../pages/zone/add')
  },
  {
    path: '/telemetry/zone/change/:id',
    name: 'telemetry-zone-change',
    component: () => import('../pages/zone/change')
  },
  {
    path: '/telemetry/zone/detail/:id',
    name: 'telemetry-zone-detail',
    component: () => import('../pages/zone/detail')
  }
]
