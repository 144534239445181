import { Restful, RestfulRequest, IntelliApi } from '@/services/intelli-api'

/**
 * IntelliApi Class
 * @class app-landing
  **/
@Restful({
  name: 'app-landing',
  path: '/system/sys-app/app-download'
})
class AppLandingService extends IntelliApi {
  @RestfulRequest({
    method: 'GET'
  })
  getList () {
    return data => {
      const result = {}
      const records = data.records || []

      for (const n in records) {
        if (records[n].type) {
          result[records[n].type] = records[n]
        }
      }

      return result
    }
  }
}

export default new AppLandingService()
