<template>
  <a v-if="!disabled" href="#" class="cell-option d-inline-block text-nowrap" :class="computedClass" @click.prevent="to"><slot>{{ text }}</slot></a>
  <span v-else class="text-black-50 me-3"><slot>{{ text }}</slot></span>
</template>

<script>
import { Component, Prop, Vue } from '@min/vue-compatible-decorator'

@Component()
class CellOption extends Vue {
  @Prop({ type: [String, Object] }) route
  @Prop({ type: Function }) function
  @Prop({ type: String }) text
  @Prop({ type: String, default: 'primary' }) variant
  @Prop({ type: Boolean, default: false }) disabled

  get computedClass () {
    const classObject = this.$attrs.class || []
    classObject.push('me-3')
    classObject.push('font-size-sm')
    if (this.variant) {
      classObject.push(`text-${this.variant}`)
    }

    return classObject
  }

  to () {
    if (!this.route) {
      this.$emit('function')
    } else {
      this.$router.push(this.route)
    }
  }
}

export default CellOption
</script>
