<template>
  <b-row class="m-0">
    <template v-if="list.length !== 0">
      <b-col v-for="(l, i) in list" :key="i" class="mb-4 p-2" cols="4">
        <div class="u-block-hover">
          <div class="option">
            <span class="option-label">
              <span class="option-head">
                <span class="option-title">{{ l.name }}</span>
              <!--              <span class="option-head">示例</span>-->
              </span>
              <span class="option-body">{{ l.identifier }}</span>
            </span>
          </div>
          <div class="u-block-hover__additional--fade g-bg-primary-opacity-0_9" style="border-radius: .42rem">
            <div class="u-block-hover__additional--fade u-block-hover__additional--fade-down g-flex-middle">
              <ul class="list-inline text-center g-flex-middle-item">
                <li class="list-inline-item align-middle mx-3">
                  <a href="javascript:;" class="btn btn-sm btn-secondary btn-icon btn-circle" title="修改" @click.prevent="changeOne(i)">
                    <span class="svg-icon svg-icon-info">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24" />
                          <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) " />
                          <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1" />
                        </g>
                      </svg>
                    </span>
                  </a>
                </li>
                <li class="list-inline-item align-middle mx-3">
                  <a href="javascript:;" class="btn btn-sm btn-secondary btn-icon btn-circle" title="删除" @click.prevent="deleteOne(i)">
                    <span class="svg-icon svg-icon-danger">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24" />
                          <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero" />
                          <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3" />
                        </g>
                      </svg>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </b-col>
    </template>
    <b-col cols="4" class="mb-4 p-2">
      <div class="u-block-hover">
        <div class="option">
          <span class="option-label">
            <span class="option-head">
              <span class="option-title">{{ name }}</span>
              <span class="option-head">示例</span>
            </span>
            <span class="option-body">标识符</span>
          </span>
        </div>
        <div class="u-block-hover__additional--fade-down g-flex-middle g-bg-primary-opacity-0_9" style="transform: translateZ(0) !important; border-radius: .42rem">
          <ul class="list-inline text-center g-flex-middle-item">
            <li class="list-inline-item align-middle mx-3">
              <a href="javascript:;" class="btn btn-sm btn-secondary btn-icon btn-circle" title="新增" @click.prevent="addOne">
                <span class="svg-icon svg-icon-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1" />
                      <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1" />
                    </g>
                  </svg>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </b-col>
    <form-modal :modal-id="modalId" :configs="configs" :change="change" :back-fill="backFill" @submit="submit" />
  </b-row>
</template>

<script>
import { Component, Vue, Watch, Prop } from '@min/vue-compatible-decorator'
import FormModal from './form-modal.vue'

@Component({
  components: { FormModal }
})
class FormInfoBlock extends Vue {
  @Prop() name

  @Prop() modalId

  @Prop() configs

  @Prop() modelValue

  list = []

  change = false

  backFill = {}

  index = 0

  submit (data) {
    if (this.change) {
      this.list[this.index] = data
    } else {
      this.list.push(data)
    }
    if (this.$root) {
      this.$root.$emit('bv::hide::modal', this.modalId, `#${this.modalId}`)
    }
  }

  addOne () {
    this.backFill = {}
    this.change = false
    if (this.$root) {
      this.$root.$emit('bv::show::modal', this.modalId, `#${this.modalId}`)
    }
  }

  changeOne (index) {
    this.change = true
    this.backFill = this.list[index]
    this.index = index
    if (this.$root) {
      this.$root.$emit('bv::show::modal', this.modalId, `#${this.modalId}`)
    }
  }

  deleteOne (index) {
    this.list.splice(index, 1)
  }

  @Watch('list', { deep: true })
  onChangeList () {
    this.$emit('update:modelValue', this.list)
  }

  @Watch('modelValue', { immediate: true })
  onChangeValue () {
    this.list = this.modelValue || []
  }
}

export default FormInfoBlock
</script>
