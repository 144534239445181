<template>
  <Transition name="aside-secondary">
    <div v-show="show" :class="['app-sidebar-secondary', 'bg-transparent']">
      <div class="background-placeholder h-100 w-100 bg-dark"></div>
      <div class="d-flex h-100 flex-column px-0 pt-2 pb-4">
        <div v-for="(category, index) in categories" :id="`aside-secondary-${category.id}`" :key="category.id" :class="['tab-pane fade', currentTabId === category.id ? 'active show' : 'd-none']">
          <div class="d-flex flex-column position-relative" :id="`kt_app_sidebar_secondary_header_${category.id}`">
            <a href="#" @click.prevent="hideAppSidebarSecondary" class="btn btn-icon btn-trim-start btn-icon-primary text-primary w-auto h-40px">
              <icon type="ri" name="arrow-left-s" mode="line" size="4" class="ms-3" />
              <span class="ms-2 fs-5">{{ category.meta.title }}</span>
            </a>
            <div class="separator border-gray-800 my-2 w-100" />
          </div>
          <div id="kt_app_sidebar_secondary_menu" class="flex-column-fluid hover-scroll-y ps-2 pe-0"
               data-kt-scroll="true"
               data-kt-scroll-activate="true"
               data-kt-scroll-height="auto"
               data-kt-scroll-wrappers="#kt_app_sidebar_secondary_menu_wrapper"
               :data-kt-scroll-dependencies="`#kt_app_header_container, #kt_aside_secondary_header_${category.id}`"
               data-kt-scroll-offset="48px"
          >
            <div :id="`menu-${index}`" class="menu menu-column menu-active-bg-light-primary menu-state-white menu-arrow-gray-100 fs-6 pb-6 pt-4" data-kt-menu="true">
              <div class="menu-fit">
                <template v-for="(menu, index) in category.children">
                  <div v-if="menu.children.length && menu.children.length !== 0 && childrenDisplay(menu.children)" :key="menu.id" data-kt-menu-trigger="click" :id="`menu-item-${index}`" :class="['menu-item', 'menu-accordion', isMenuActive(menu, 1) ? 'hover show' : '']">
                    <span class="menu-link">
                      <span :class="['menu-title', 'text-gray-100']">{{ menu.meta.title }}</span>
                      <i class="menu-arrow" />
                    </span>
                    <div :class="['menu-sub', 'menu-sub-item']">
                      <div v-for="submenu in menu.children" :key="submenu.id" class="menu-item">
                        <a :class="['menu-link', isLeafActive(submenu, 2) ? 'active fw-semibold' : '']" href="#"  @click.prevent="to(submenu)">
                          <span :class="['menu-title', isLeafActive(submenu, 2) ? '' : 'text-gray-500']">{{ submenu.meta.title }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <template v-else>
                    <div v-if="menu.meta.show" class="menu-item" :key="menu.id">
                      <a :class="['menu-link', isLeafActive(menu, 1) ? 'active' : '']" href="#" @click.prevent="to(menu)">
                        <span :class="['menu-title', 'text-gray-100']">{{ menu.meta.title }}</span>
                      </a>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { Component, Prop, Watch, Vue } from '@min/vue-compatible-decorator'
import { useMenuStore } from '@/stores/menu'
import Event from '@/utils/event'
import { nextTick } from 'vue'
// import { onResize } from '@/components/layout/media-query'

export default @Component()
class AsideSecondary extends Vue {
  @Prop({ type: Boolean, default: false }) isSuperAdmin
  @Prop({ type: Array, default: () => [], required: true }) breadCrumb
  @Prop({ type: Boolean, default: false }) show

  on = false
  menuIds = []
  categories = []
  currentTabId = ''

  get menus () {
    const menus = useMenuStore().menus
    const ids = []
    for (const i in menus) {
      ids.push(menus[i].id)
    }
    this.menuIds = ids
    return menus
  }

  hideAppSidebarSecondary () {
    Event.emit('sidebar.secondary.hide')
  }

  childrenDisplay (children) {
    let flag = false
    for (const i in children) {
      flag = flag || children[i].meta.show
    }
    return flag
  }

  @Watch('show', { immediate: true })
  watchShow (value) {
    this.$nextTick(() => {
      this.on = value
    })
  }

  @Watch('categories')
  async watchCategories (value) {
    const ids = []
    for (const i in value) {
      ids.push(value[i].id)
    }
    this.menuIds = ids
    await nextTick()
    // eslint-disable-next-line no-undef
    KTMenu.createInstances()
    // eslint-disable-next-line no-undef
    KTScroll.createInstances()
  }

  @Watch('breadCrumb')
  watchBreadCrumb (value) {
    this.currentTabId = value[0]?.id || ''
  }

  isMenuActive (menu, level) {
    return this.breadCrumb[level] && (menu.id === this.breadCrumb[level].id)
  }

  isLeafActive (menu, level) {
    return this.breadCrumb[level] && (menu.id === this.breadCrumb[level].id)
  }

  to (menu) {
    /* Event.emit('tab.insert', 'default', {
      name: menu.name.replace(/_/g, '-'),
      title: menu.meta.title,
      icon: menu.meta.icon,
      route: menu.path,
      params: {
        timestamp: moment().unix()
      },
      hidden: false,
      active: true
    }) */
    let path = menu.path
    if (menu.meta?.open_type === 4) {
      path = `${menu.path}#${encodeURIComponent(menu.meta.link ?? '')}`
    }
    this.$router.push(path)
  }

  mounted () {
    useMenuStore().initialized.run(() => {
      this.categories = useMenuStore().categories
    }, 'ASIDE_SECONDARY_MOUNT')

    Event.on('aside-primary.click', (tabName) => {
      this.currentTabId = tabName
    })
  }
}
</script>

<style scoped>
.aside-secondary-enter-active,
.aside-secondary-leave-active {
  transition: opacity .15s ease;
}

.aside-secondary-enter-from,
.aside-secondary-leave-to {
  opacity: 0;
}
</style>
