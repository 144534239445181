import { Restful, RestfulRequest, IntelliApi } from '@/services/intelli-api'

/**
 * IntelliApi Class
 * @class notification
  **/
@Restful({
  name: 'notification',
  path: '/common/notice'
})
class NotificationService extends IntelliApi {
  @RestfulRequest({
    method: 'GET',
    path: 'page-current-user',
    parameters: ['data']
  })
  getList (data) {}

  @RestfulRequest({
    method: 'GET',
    path: '#{id}',
    parameters: [{ id: '-' }]
  })
  getDetail (id) {}

  @RestfulRequest({
    method: 'GET',
    path: '/self-recently-notice?status=0&second=86400'
  })
  fetchNewNotify () {}

  @RestfulRequest({
    method: 'GET',
    path: '/unread-notice-count'
  })
  getAllNoRead () {}

  @RestfulRequest({
    method: 'POST',
    path: '/batch',
    parameters: ['flag', 'status']
  })
  allRead (flag, status) {}

  @RestfulRequest({
    method: 'PUT',
    path: '/batch/#{ids}',
    parameters: [{ ids: '-' }, 'status']
  })
  read (ids, status) {}
}

export default new NotificationService()
