<template>
  <action-item
    id="kt_header_notice_toggle"
    :active="noticeActive"
    hover-width="80"
    active-width="250"
    expand-width="450"
    expand-height="726px"
    action-thumb-class="d-flex"
    variant="color-state"
    @action-show="getListData()"
  >
    <template #action-thumb>
      <a href="#" class="btn btn-icon btn-icon-light justify-content-around w-40px h-40px">
        <icon type="ri" name="notification-badge" mode="fill" size="6" />
      </a>
      <div class="hover-show align-self-center">
        <span :class="['align-self-center', 'badge badge-sm badge-pill', noticeCount ? 'badge-primary' : 'badge-primary text-light', 'd-hover-inline-flex', 'justify-content-center']">{{ noticeCount || 0 }}</span>
      </div>
      <div class="active-show w-200px overflow-hidden">
        <div class="text-gray-500 me-3 text-end">数联智造通知</div>
        <div class="me-3 text-gray-500 text-truncate">{{ noticeMessage }}</div>
      </div>
    </template>
    <template #action-expand>
      <div class="d-flex flex-column bg-primary background-with-logo">
        <h4 class="text-white fw-semibold px-4 mt-4 mb-2">
          系统通知
        </h4>
        <span class="fs-8 text-white ps-4 mb-4">{{ noticeCount ? `${noticeCount}条新通知` : '暂无新通知' }}</span>
        <ul class="nav nav-line-tabs nav-line-tabs-2x nav-stretch px-6" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link text-white active" data-bs-toggle="tab" href="#topbar_notify" aria-selected="true" role="tab">通知</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link text-white" data-bs-toggle="tab" href="#topbar_event" aria-selected="false" role="tab" tabindex="-1">事件</a>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <div class="tab-pane fade active show" id="topbar_notify" role="tabpanel">
          <div class="scroll-y mh-550px my-0 px-4">
            <div v-for="(notice, n) in noticeList" :key="`notice-${n}`" class="d-flex flex-stack py-4 border-bottom border-gray-200">
              <div class="w-100 d-flex flex-column">
                <div class="w-100 d-flex flex-row justify-content-between">
                  <a href="#" class="fs-5 text-gray-900 d-block mw-250px text-truncate">
                    <span class="fw-bold pe-4">{{ notice.module }}</span>
                    <span>{{ notice.type ? `${notice.type}` : '' }}</span>
                  </a>
                  <span class="fs-8 text-gray-600 fw-normal d-block">{{ notice.create_time }}</span>
                </div>
                <div class="text-gray-700 fs-6 mw-400px mt-2 text-truncate text-left">
                  <b-badge class="badge-pill bg-light-primary text-primary mr-2">{{ notice.title }}</b-badge>
                  {{ notice.content }}
                </div>
              </div>

            </div>
          </div>
          <div class="py-3 text-center">
            <a href="#" class="btn btn-color-primary" @click="gotoNotice">
              查看所有通知<icon name="arrow-right" type="ri" mode="line" class="ms-1" /></a>
          </div>
        </div>
        <div class="tab-pane fade" id="topbar_event" role="tabpanel">
          <div class="scroll-y mh-475px my-5 px-8">
            <div v-for="(event, n) in eventList" :key="`notice-${n}`"  class="d-flex flex-stack py-4">
              <div class="d-flex align-items-center me-2">
                <zero-tag v-if="event.importance === 1" variant="light-success">一般</zero-tag>
                <zero-tag v-if="event.importance === 2" variant="light-primary">中等</zero-tag>
                <zero-tag v-if="event.importance === 3" variant="light-warning">重要</zero-tag>
                <zero-tag v-if="event.importance === 4" variant="light-danger">严重</zero-tag>
<!--                <span class="w-40px text-center badge badge-light-success me-4">{{ translateImportance(event.importance) }}</span>-->
                <a href="#" class="text-gray-800 text-hover-primary fw-semibold">{{ event.abnormal_type }}: {{ event.abnormal_subject }}</a>
              </div>
              <span class="badge badge-light fs-8 fw-normal text-end" v-html="event.create_time"></span>
            </div>
          </div>
          <div class="py-3 text-center border-top">
            <a href="#" class="btn btn-color-primary" @click="gotoEvent">
              查看所有事件<icon name="arrow-right" type="ri" mode="line" class="ms-1" /></a>
          </div>
        </div>
      </div>
    </template>
  </action-item>
</template>

<script>
import { Component, Prop, Vue } from '@min/vue-compatible-decorator'
import NotificationService from '@/services/notification'
import notification from '@/utils/notification'
import ActionItem from '@/components/layout/header-components/action-item.vue'
import EventAbnormalService from '@/modular/base/system/services/event-abnormal'
import { all } from '@/services/intelli-api'

@Component({
  components: { ActionItem }
})
class Notification extends Vue {
  @Prop({ type: [Array, Object], default: () => [] }) messages

  noticeActive = false
  noticeMessage = ''
  noticeCount = 0

  noticeList = []
  eventList = []

  gotoNotice () {
    this.$router.push('/notification/list')
  }

  gotoEvent () {
    this.$router.push('/system/event/abnormal/list')
  }

  async checkNotification () {
    try {
      const messages = await NotificationService.fetchNewNotify()

      if (messages.length && messages.length > 0) {
        this.noticeActive = true
        this.noticeCount = messages.length
        this.noticeMessage = (messages.length > 1 ? `您有${messages.length}条提醒, 请注意查收` : messages[0].content)

        setTimeout(() => {
          this.noticeActive = false
        }, 5000)
      }
    } catch (e) {
      console.log('获取通知失败')
    }
  }

  async getListData () {
    [this.noticeList, this.eventList] = await all(
      (await NotificationService.getList({ page: 1, size: 10, status: 0 }))?.records,
      (await EventAbnormalService.getList({ page: 1, size: 10 }))?.records
    )
  }

  ns2br (str) {
    return str.replace(' ', '<br />')
  }

  getPrefix (str) {
    if (str !== '') {
      return str.substring(0, 1)
    }
    return ''
  }

  translateImportance (data) {
    switch (data) {
      case 1:
        return '一般'
      case 2:
        return '中等'
      case 3:
        return '重要'
      case 4:
        return '严重'
      default:
        return '无'
    }
  }

  notificationWebsocket () {
    const wsAddress = process.env.WS_URL || ((window.location.protocol === 'http:' ? 'ws' : 'wss') + '://' + window.location.host + '/api')
    // console.log(wsAddress)
    // const websocket = new WebSocket(`${window.location.protocol === 'http:' ? 'ws' : 'wss'}://${window.location.host}/api/test/self-recently-notice?token=${localStorage.getItem('access_token')}`)
    try {
      const websocket = new WebSocket(`${wsAddress}/test/self-recently-notice?token=${localStorage.getItem('access_token')}`)
      websocket.onmessage = (message) => {
        if (message.data.substr(0, 1) === '{') {
          const data = JSON.parse(message.data)
          notification.toast.warning(
            `数联智造通知:${data.title}`,
            data.content,
            10 * 1000,
            {
              position: 'top-end',
              onclick: () => {
                this.$router.push('/notification/list')
              }
            }
          )
        }
      }
      websocket.onopen = () => { console.log('通知通道建立') }
      websocket.onerror = (e) => { console.log('通知通道出现错误' + JSON.stringify(e)) }
      websocket.onclose = (e) => {
        clearInterval(this.websocketTimer)
        console.log('断开连接', e)
      }
      this.websocketTimer = setInterval(() => {
        websocket.send('ping')
      }, 30000)
    } catch (e) {
      console.log('通知通道连接失败')
    }
  }

  async mounted () {
    await this.checkNotification()
    // this.notificationWebsocket()
  }

  destroyed () {
    clearInterval(this.notification)
    clearInterval(this.websocketTimer)
  }
}

export default Notification
</script>
