<template>
  <b-button :id="`drawer_open_button_${$route.name}`" size="sm" variant="link" class="btn-text-muted text-hover-primary btn-icon-muted fw-normal py-1">
    <slot name="openButton"></slot>
  </b-button>
  <teleport to="body">
    <div
      id="kt_drawer_example_basic"
      class="bg-white"
      data-kt-drawer="true"
      data-kt-drawer-activate="true"
      :data-kt-drawer-toggle="`#drawer_open_button_${$route.name}`"
      data-kt-drawer-close=".drawer_close_button"
      :data-kt-drawer-width="`${drawerWidth}px`"
    >
      <div class="position-relative w-100 h-100">
        <div class="p-4 d-flex justify-content-between w-100" style="height: 3.75rem">
          <div class="fs-4 fw-normal text-gray-1000">{{ title }}</div>
          <div class="btn btn-sm btn-icon w-auto h-100 drawer_close_button">
          <span class="svg-icon svg-icon-2">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="#525252" xmlns="http://www.w3.org/2000/svg" >
              <rect x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="#525252" style="color: #525252" />
              <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="#525252" style="color: #525252" />
            </svg>
          </span>
          </div>
        </div>
        <slot></slot>
      </div>
    </div>
  </teleport>
</template>

<script setup>
import { defineProps, onMounted } from 'vue'

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  title: { type: String, default: '' },
  btnClass: { type: String, default: 'btn-text-muted btn-icon-muted fw-normal bg-none bg-hover-secondary py-1' },
  drawerWidth: { type: Number, default: 500 }
})

onMounted(() => {
  // eslint-disable-next-line no-undef
  KTDrawer.init()
})
</script>
