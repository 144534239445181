function toCamelCase (str, upperCaseFirstLetter) {
  const camelCaseStr = str ? str.replace(/[-_](\w)/g, (all, letter) => { letter.toUpperCase() }) : ''
  if (upperCaseFirstLetter) {
    return camelCaseStr
  } else {
    return camelCaseStr.charAt(0).toLowerCase() + camelCaseStr.slice(1)
  }
}

function toSnakeCase (str) {
  return str ? str.replace(/([A-Z])/g, '_$1').toLowerCase() : ''
}

function toLineCase (str) {
  const temp = str ? str.replace(/([A-Z])/g, '-$1').toLowerCase() : ''
  return temp.startsWith('-') ? temp.slice(1) : temp
}

function strLen (str) {
  const l = str.length
  let blen = 0
  for (let i = 0; i < l; i++) {
    if ((str.charCodeAt(i) & 0xff00) !== 0) {
      blen++
    }
    blen++
  }
  return blen
}

export {
  toCamelCase,
  toSnakeCase,
  toLineCase,
  strLen
}
