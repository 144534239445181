export default [
  {
    path: '/outsource/task/list',
    name: 'outsource-task-list',
    component: () => import('../pages/task/list.vue')
  },
  {
    path: '/outsource/delivery/relation/list',
    name: 'outsource-delivery-relation-list',
    component: () => import('../pages/delivery/relation/list.vue')
  },
  {
    path: '/outsource/delivery/relation/add',
    name: 'outsource-delivery-relation-add',
    component: () => import('../pages/delivery/relation/add.vue')
  },
  {
    path: '/outsource/delivery/relation/change/:id',
    name: 'outsource-delivery-relation-change',
    component: () => import('../pages/delivery/relation/change.vue')
  },
  {
    path: '/outsource/delivery/relation/detail/:id',
    name: 'outsource-delivery-relation-detail',
    component: () => import('../pages/delivery/relation/detail.vue')
  },
  {
    path: '/outsource/delivery/plan/list',
    name: 'outsource-delivery-plan-list',
    component: () => import('../pages/delivery/plan/list.vue')
  },
  {
    path: '/outsource/delivery/plan/change/:id',
    name: 'outsource-delivery-plan-change',
    component: () => import('../pages/delivery/plan/change.vue')
  },
  {
    path: '/outsource/delivery/plan/detail/:id',
    name: 'outsource-delivery-plan-detail',
    component: () => import('../pages/delivery/plan/detail.vue')
  },
  {
    path: '/outsource/delivery/plan-log/list',
    name: 'outsource-delivery-plan-log-list',
    component: () => import('../pages/delivery/plan/log.vue')
  },
  {
    path: '/outsource/delivery/receive-log/list',
    name: 'outsource-delivery-receive-log-list',
    component: () => import('../pages/delivery/log/receive-list.vue')
  },
  {
    path: '/outsource/delivery/delivery-log/list',
    name: 'outsource-delivery-delivery-log-list',
    component: () => import('../pages/delivery/log/delivery-list.vue')
  },
  {
    path: '/outsource/price/list',
    name: 'outsource-price-list',
    component: () => import('../pages/price/list.vue')
  },
  {
    path: '/outsource/price/add',
    name: 'outsource-price-add',
    component: () => import('../pages/price/add.vue')
  },
  {
    path: '/outsource/price/change/:id',
    name: 'outsource-price-change',
    component: () => import('../pages/price/change.vue')
  },
  {
    path: '/outsource/price/detail/:id',
    name: 'outsource-price-detail',
    component: () => import('../pages/price/detail.vue')
  }
]
